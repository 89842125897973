@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.skills-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 5%;
  font-family: 'Press Start 2P', cursive;
  max-width: 600px;
}

.skills-section {
  margin-bottom: 40px;
}

.category-title {
  font-size: 1.5rem;
  color: #f39c12;
  text-decoration: underline;
  margin-bottom: 15px;
}

.skill-bar {
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skill-name {
  color: #f1c40f;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.bar-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.bar {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #555;
  border: 3px solid #000;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

.bar-filled {
  background-color: #8b0000; /* Blood red color */
  height: 100%;
  transition: width 0.5s ease;
  border-radius: 15px 0 0 15px;
  position: relative;
  animation: pulse 2s infinite;
}

.bar-filled.full::after {
  content: '';
  position: absolute;
  top: 50%;
  left: calc(100% - 5px); /* Positioning the drop at the very end of the bar */
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: #8b0000;
  border-radius: 50%;
  animation: drop 1.5s ease-in infinite;
  opacity: 1;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  50% {
    transform: scaleX(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes drop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(25px); /* Drop moving downwards */
    opacity: 0;
  }
}

/* Staggered drop animations */
.bar-filled.full:nth-of-type(1)::after {
  animation-delay: 0s;
}

.bar-filled.full:nth-of-type(2)::after {
  animation-delay: 0.3s;
}

.bar-filled.full:nth-of-type(3)::after {
  animation-delay: 0.6s;
}

.bar-filled.full:nth-of-type(4)::after {
  animation-delay: 0.9s;
}

.bar-filled.full:nth-of-type(5)::after {
  animation-delay: 1.2s;
}

.bar-filled.full:nth-of-type(6)::after {
  animation-delay: 1.5s;
}

.bar-filled.full:nth-of-type(7)::after {
  animation-delay: 1.8s;
}

.bar-filled.full:nth-of-type(8)::after {
  animation-delay: 2.1s;
}
