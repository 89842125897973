.background-container {
    background-color: #121212;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1; /* Ensure the background is behind other content */

    &::after,
    &::before {
        display: block;
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        left: -600px;
        right: 0;
        top: calc(70%);
        transform: translateY(-50%);
        margin: auto;
    }

    &::after {
        animation: animate 0.5s infinite steps(1, end);
        /* z-index: 0; */
    }

    &::before {
        box-shadow: 0px 168px rgb(60, 61, 70), 0px 172px rgb(60, 61, 70),
            0px 176px rgb(60, 61, 70), 4px 168px rgb(60, 61, 70),
            4px 172px rgb(60, 61, 70), 4px 176px rgb(60, 61, 70),
            8px 168px rgb(60, 61, 70), 8px 172px rgb(60, 61, 70),
            8px 176px rgb(60, 61, 70), 12px 168px rgb(60, 61, 70),
            12px 172px rgb(60, 61, 70), 12px 176px rgb(60, 61, 70),
            16px 168px rgb(60, 61, 70), 16px 172px rgb(60, 61, 70),
            16px 176px rgb(60, 61, 70), 20px 168px rgb(60, 61, 70),
            20px 172px rgb(60, 61, 70), 20px 176px rgb(60, 61, 70),
            24px 168px rgb(60, 61, 70), 24px 172px rgb(60, 61, 70),
            24px 176px rgb(60, 61, 70), 28px 168px rgb(60, 61, 70),
            28px 172px rgb(60, 61, 70), 28px 176px rgb(60, 61, 70),
            28px 180px rgb(60, 61, 70), 32px 168px rgb(60, 61, 70),
            32px 172px rgb(60, 61, 70), 32px 176px rgb(60, 61, 70),
            32px 180px rgb(60, 61, 70), 36px 168px rgb(60, 61, 70),
            36px 172px rgb(60, 61, 70), 36px 176px rgb(60, 61, 70),
            36px 180px rgb(60, 61, 70), 36px 184px rgb(60, 61, 70),
            40px 168px rgb(60, 61, 70), 40px 172px rgb(60, 61, 70),
            40px 176px rgb(60, 61, 70), 40px 180px rgb(60, 61, 70),
            40px 184px rgb(60, 61, 70), 44px 168px rgb(60, 61, 70),
            44px 172px rgb(60, 61, 70), 44px 176px rgb(60, 61, 70),
            44px 180px rgb(60, 61, 70), 44px 184px rgb(60, 61, 70),
            48px 164px rgb(60, 61, 70), 48px 168px rgb(60, 61, 70),
            48px 172px rgb(60, 61, 70), 48px 176px rgb(60, 61, 70),
            48px 180px rgb(60, 61, 70), 48px 184px rgb(60, 61, 70),
            52px 164px rgb(60, 61, 70), 52px 168px rgb(60, 61, 70),
            52px 172px rgb(60, 61, 70), 52px 176px rgb(60, 61, 70),
            52px 180px rgb(60, 61, 70), 52px 184px rgb(60, 61, 70),
            56px 164px rgb(60, 61, 70), 56px 168px rgb(60, 61, 70),
            56px 172px rgb(60, 61, 70), 56px 176px rgb(60, 61, 70),
            56px 180px rgb(60, 61, 70), 56px 184px rgb(60, 61, 70),
            60px 164px rgb(60, 61, 70), 60px 168px rgb(60, 61, 70),
            60px 172px rgb(60, 61, 70), 60px 176px rgb(60, 61, 70),
            60px 180px rgb(60, 61, 70), 60px 184px rgb(60, 61, 70),
            60px 196px rgb(60, 61, 70), 64px 160px rgb(60, 61, 70),
            64px 164px rgb(60, 61, 70), 64px 168px rgb(60, 61, 70),
            64px 172px rgb(60, 61, 70), 64px 176px rgb(60, 61, 70),
            64px 180px rgb(60, 61, 70), 64px 184px rgb(60, 61, 70),
            64px 196px rgb(60, 61, 70), 68px 160px rgb(60, 61, 70),
            68px 164px rgb(60, 61, 70), 68px 168px rgb(60, 61, 70),
            68px 172px rgb(60, 61, 70), 68px 176px rgb(60, 61, 70),
            68px 180px rgb(60, 61, 70), 68px 184px rgb(60, 61, 70),
            68px 192px rgb(60, 61, 70), 68px 196px rgb(60, 61, 70),
            72px 160px rgb(60, 61, 70), 72px 164px rgb(60, 61, 70),
            72px 168px rgb(60, 61, 70), 72px 172px rgb(60, 61, 70),
            72px 176px rgb(60, 61, 70), 72px 180px rgb(60, 61, 70),
            72px 184px rgb(60, 61, 70), 72px 188px rgb(60, 61, 70),
            72px 192px rgb(60, 61, 70), 72px 196px rgb(60, 61, 70),
            72px 200px rgb(60, 61, 70), 76px 160px rgb(60, 61, 70),
            76px 164px rgb(60, 61, 70), 76px 168px rgb(60, 61, 70),
            76px 172px rgb(60, 61, 70), 76px 176px rgb(60, 61, 70),
            76px 180px rgb(60, 61, 70), 76px 184px rgb(60, 61, 70),
            76px 188px rgb(60, 61, 70), 76px 192px rgb(60, 61, 70),
            76px 196px rgb(60, 61, 70), 76px 200px rgb(60, 61, 70),
            76px 244px rgb(60, 61, 70), 80px 160px rgb(60, 61, 70),
            80px 164px rgb(60, 61, 70), 80px 168px rgb(60, 61, 70),
            80px 172px rgb(60, 61, 70), 80px 176px rgb(60, 61, 70),
            80px 180px rgb(60, 61, 70), 80px 184px rgb(60, 61, 70),
            80px 188px rgb(60, 61, 70), 80px 192px rgb(60, 61, 70),
            80px 196px rgb(60, 61, 70), 80px 200px rgb(60, 61, 70),
            80px 240px rgb(60, 61, 70), 80px 244px rgb(60, 61, 70),
            80px 248px rgb(60, 61, 70), 84px 160px rgb(60, 61, 70),
            84px 164px rgb(60, 61, 70), 84px 168px rgb(60, 61, 70),
            84px 172px rgb(60, 61, 70), 84px 176px rgb(60, 61, 70),
            84px 180px rgb(60, 61, 70), 84px 184px rgb(60, 61, 70),
            84px 188px rgb(60, 61, 70), 84px 192px rgb(60, 61, 70),
            84px 196px rgb(60, 61, 70), 84px 200px rgb(60, 61, 70),
            84px 240px rgb(60, 61, 70), 84px 244px rgb(60, 61, 70),
            84px 248px rgb(60, 61, 70), 88px 160px rgb(60, 61, 70),
            88px 164px rgb(60, 61, 70), 88px 168px rgb(60, 61, 70),
            88px 172px rgb(60, 61, 70), 88px 176px rgb(60, 61, 70),
            88px 180px rgb(60, 61, 70), 88px 184px rgb(60, 61, 70),
            88px 188px rgb(60, 61, 70), 88px 192px rgb(60, 61, 70),
            88px 196px rgb(60, 61, 70), 88px 200px rgb(60, 61, 70),
            88px 240px rgb(60, 61, 70), 88px 244px rgb(60, 61, 70),
            88px 248px rgb(60, 61, 70), 88px 252px rgb(60, 61, 70),
            92px 160px rgb(60, 61, 70), 92px 164px rgb(60, 61, 70),
            92px 168px rgb(60, 61, 70), 92px 172px rgb(60, 61, 70),
            92px 176px rgb(60, 61, 70), 92px 180px rgb(60, 61, 70),
            92px 184px rgb(60, 61, 70), 92px 188px rgb(60, 61, 70),
            92px 192px rgb(60, 61, 70), 92px 196px rgb(60, 61, 70),
            92px 200px rgb(60, 61, 70), 92px 236px rgb(60, 61, 70),
            92px 240px rgb(60, 61, 70), 92px 244px rgb(60, 61, 70),
            92px 248px rgb(60, 61, 70), 92px 252px rgb(60, 61, 70),
            96px 160px rgb(60, 61, 70), 96px 164px rgb(60, 61, 70),
            96px 168px rgb(60, 61, 70), 96px 172px rgb(60, 61, 70),
            96px 176px rgb(60, 61, 70), 96px 180px rgb(38, 29, 35),
            96px 184px rgb(38, 29, 35), 96px 188px rgb(38, 29, 35),
            96px 192px rgb(38, 29, 35), 96px 196px rgb(60, 61, 70),
            96px 200px rgb(60, 61, 70), 96px 236px rgb(60, 61, 70),
            96px 240px rgb(60, 61, 70), 96px 244px rgb(60, 61, 70),
            96px 248px rgb(60, 61, 70), 96px 252px rgb(60, 61, 70),
            100px 160px rgb(60, 61, 70), 100px 164px rgb(60, 61, 70),
            100px 168px rgb(60, 61, 70), 100px 172px rgb(60, 61, 70),
            100px 176px rgb(38, 29, 35), 100px 180px rgb(38, 29, 35),
            100px 184px rgb(38, 29, 35), 100px 188px rgb(52, 34, 45),
            100px 192px rgb(38, 29, 35), 100px 196px rgb(38, 29, 35),
            100px 200px rgb(60, 61, 70), 100px 236px rgb(60, 61, 70),
            100px 240px rgb(60, 61, 70), 100px 244px rgb(60, 61, 70),
            100px 248px rgb(60, 61, 70), 104px 160px rgb(60, 61, 70),
            104px 164px rgb(60, 61, 70), 104px 168px rgb(60, 61, 70),
            104px 172px rgb(38, 29, 35), 104px 176px rgb(38, 29, 35),
            104px 180px rgb(38, 29, 35), 104px 184px rgb(52, 34, 45),
            104px 188px rgb(52, 34, 45), 104px 192px rgb(52, 34, 45),
            104px 196px rgb(38, 29, 35), 104px 200px rgb(38, 29, 35),
            104px 232px rgb(60, 61, 70), 104px 236px rgb(60, 61, 70),
            104px 240px rgb(60, 61, 70), 104px 244px rgb(60, 61, 70),
            104px 248px rgb(60, 61, 70), 108px 160px rgb(60, 61, 70),
            108px 164px rgb(60, 61, 70), 108px 168px rgb(60, 61, 70),
            108px 172px rgb(38, 29, 35), 108px 176px rgb(38, 29, 35),
            108px 180px rgb(38, 29, 35), 108px 184px rgb(52, 34, 45),
            108px 188px rgb(52, 34, 45), 108px 192px rgb(52, 34, 45),
            108px 196px rgb(38, 29, 35), 108px 200px rgb(38, 29, 35),
            108px 232px rgb(60, 61, 70), 108px 236px rgb(60, 61, 70),
            108px 240px rgb(60, 61, 70), 108px 244px rgb(60, 61, 70),
            108px 248px rgb(60, 61, 70), 112px 160px rgb(60, 61, 70),
            112px 164px rgb(60, 61, 70), 112px 168px rgb(52, 34, 45),
            112px 172px rgb(52, 34, 45), 112px 176px rgb(38, 29, 35),
            112px 180px rgb(38, 29, 35), 112px 184px rgb(38, 29, 35),
            112px 188px rgb(52, 34, 45), 112px 192px rgb(38, 29, 35),
            112px 196px rgb(38, 29, 35), 112px 200px rgb(38, 29, 35),
            112px 232px rgb(60, 61, 70), 112px 236px rgb(60, 61, 70),
            112px 240px rgb(60, 61, 70), 112px 244px rgb(60, 61, 70),
            116px 160px rgb(60, 61, 70), 116px 164px rgb(60, 61, 70),
            116px 168px rgb(52, 34, 45), 116px 172px rgb(52, 34, 45),
            116px 176px rgb(38, 29, 35), 116px 180px rgb(38, 29, 35),
            116px 184px rgb(38, 29, 35), 116px 188px rgb(38, 29, 35),
            116px 192px rgb(38, 29, 35), 116px 196px rgb(38, 29, 35),
            116px 200px rgb(118, 80, 95), 116px 204px rgb(76, 53, 68),
            116px 208px rgb(76, 53, 68), 116px 212px rgb(76, 53, 68),
            116px 216px rgb(76, 53, 68), 116px 220px rgb(76, 53, 68),
            116px 224px rgb(76, 53, 68), 116px 228px rgb(76, 53, 68),
            116px 232px rgb(52, 34, 45), 116px 236px rgb(60, 61, 70),
            116px 240px rgb(60, 61, 70), 116px 244px rgb(60, 61, 70),
            120px 156px rgb(38, 29, 35), 120px 160px rgb(38, 29, 35),
            120px 164px rgb(38, 29, 35), 120px 168px rgb(52, 34, 45),
            120px 172px rgb(52, 34, 45), 120px 176px rgb(52, 34, 45),
            120px 180px rgb(52, 34, 45), 120px 184px rgb(38, 29, 35),
            120px 188px rgb(38, 29, 35), 120px 192px rgb(38, 29, 35),
            120px 196px rgb(118, 80, 95), 120px 200px rgb(118, 80, 95),
            120px 204px rgb(118, 80, 95), 120px 208px rgb(76, 53, 68),
            120px 212px rgb(76, 53, 68), 120px 216px rgb(76, 53, 68),
            120px 220px rgb(76, 53, 68), 120px 224px rgb(76, 53, 68),
            120px 228px rgb(76, 53, 68), 120px 232px rgb(76, 53, 68),
            120px 236px rgb(52, 34, 45), 120px 240px rgb(60, 61, 70),
            120px 244px rgb(60, 61, 70), 124px 140px rgb(38, 29, 35),
            124px 144px rgb(38, 29, 35), 124px 148px rgb(38, 29, 35),
            124px 152px rgb(38, 29, 35), 124px 156px rgb(52, 34, 45),
            124px 160px rgb(52, 34, 45), 124px 164px rgb(52, 34, 45),
            124px 168px rgb(38, 29, 35), 124px 172px rgb(52, 34, 45),
            124px 176px rgb(52, 34, 45), 124px 180px rgb(52, 34, 45),
            124px 184px rgb(52, 34, 45), 124px 188px rgb(52, 34, 45),
            124px 192px rgb(52, 34, 45), 124px 196px rgb(118, 80, 95),
            124px 200px rgb(118, 80, 95), 124px 204px rgb(118, 80, 95),
            124px 208px rgb(76, 53, 68), 124px 212px rgb(76, 53, 68),
            124px 216px rgb(76, 53, 68), 124px 220px rgb(76, 53, 68),
            124px 224px rgb(76, 53, 68), 124px 228px rgb(76, 53, 68),
            124px 232px rgb(76, 53, 68), 124px 236px rgb(52, 34, 45),
            124px 240px rgb(60, 61, 70), 128px 128px rgb(52, 34, 45),
            128px 132px rgb(52, 34, 45), 128px 136px rgb(52, 34, 45),
            128px 140px rgb(52, 34, 45), 128px 144px rgb(52, 34, 45),
            128px 148px rgb(52, 34, 45), 128px 152px rgb(52, 34, 45),
            128px 156px rgb(52, 34, 45), 128px 160px rgb(52, 34, 45),
            128px 164px rgb(52, 34, 45), 128px 168px rgb(52, 34, 45),
            128px 172px rgb(38, 29, 35), 128px 176px rgb(78, 51, 68),
            128px 180px rgb(52, 34, 45), 128px 184px rgb(78, 51, 68),
            128px 188px rgb(52, 34, 45), 128px 192px rgb(52, 34, 45),
            128px 196px rgb(118, 80, 95), 128px 200px rgb(118, 80, 95),
            128px 208px rgb(76, 53, 68), 128px 212px rgb(76, 53, 68),
            128px 216px rgb(52, 34, 45), 128px 220px rgb(76, 53, 68),
            128px 224px rgb(76, 53, 68), 128px 228px rgb(76, 53, 68),
            128px 232px rgb(76, 53, 68), 128px 236px rgb(52, 34, 45),
            128px 240px rgb(60, 61, 70), 132px 124px rgb(52, 34, 45),
            132px 128px rgb(76, 53, 68), 132px 132px rgb(76, 53, 68),
            132px 136px rgb(76, 53, 68), 132px 140px rgb(76, 53, 68),
            132px 144px rgb(52, 34, 45), 132px 148px rgb(52, 34, 45),
            132px 152px rgb(52, 34, 45), 132px 156px rgb(76, 53, 68),
            132px 160px rgb(76, 53, 68), 132px 164px rgb(52, 34, 45),
            132px 168px rgb(52, 34, 45), 132px 172px rgb(52, 34, 45),
            132px 176px rgb(38, 29, 35), 132px 180px rgb(52, 34, 45),
            132px 184px rgb(78, 51, 68), 132px 188px rgb(52, 34, 45),
            132px 192px rgb(52, 34, 45), 132px 200px rgb(118, 80, 95),
            132px 208px rgb(118, 80, 95), 132px 212px rgb(118, 80, 95),
            132px 216px rgb(52, 34, 45), 132px 220px rgb(118, 80, 95),
            132px 224px rgb(118, 80, 95), 132px 228px rgb(118, 80, 95),
            132px 232px rgb(118, 80, 95), 132px 236px rgb(52, 34, 45),
            132px 240px rgb(60, 61, 70), 136px 120px rgb(52, 34, 45),
            136px 124px rgb(76, 53, 68), 136px 128px rgb(76, 53, 68),
            136px 132px rgb(118, 80, 95), 136px 136px rgb(118, 80, 95),
            136px 140px rgb(118, 80, 95), 136px 144px rgb(76, 53, 68),
            136px 148px rgb(52, 34, 45), 136px 152px rgb(52, 34, 45),
            136px 156px rgb(52, 34, 45), 136px 160px rgb(52, 34, 45),
            136px 164px rgb(76, 53, 68), 136px 168px rgb(52, 34, 45),
            136px 172px rgb(52, 34, 45), 136px 176px rgb(38, 29, 35),
            136px 180px rgb(78, 51, 68), 136px 184px rgb(124, 72, 72),
            136px 188px rgb(52, 34, 45), 136px 192px rgb(60, 61, 70),
            136px 200px rgb(118, 80, 95), 136px 208px rgb(118, 80, 95),
            136px 212px rgb(118, 80, 95), 136px 216px rgb(52, 34, 45),
            136px 220px rgb(52, 34, 45), 136px 224px rgb(52, 34, 45),
            136px 228px rgb(52, 34, 45), 136px 232px rgb(52, 34, 45),
            136px 236px rgb(60, 61, 70), 140px 120px rgb(52, 34, 45),
            140px 124px rgb(76, 53, 68), 140px 128px rgb(118, 80, 95),
            140px 132px rgb(118, 80, 95), 140px 136px rgb(118, 80, 95),
            140px 140px rgb(118, 80, 95), 140px 144px rgb(76, 53, 68),
            140px 148px rgb(52, 34, 45), 140px 152px rgb(76, 53, 68),
            140px 156px rgb(76, 53, 68), 140px 160px rgb(76, 53, 68),
            140px 164px rgb(52, 34, 45), 140px 168px rgb(52, 34, 45),
            140px 172px rgb(52, 34, 45), 140px 176px rgb(38, 29, 35),
            140px 180px rgb(124, 72, 72), 140px 184px rgb(52, 34, 45),
            140px 188px rgb(52, 34, 45), 140px 192px rgb(60, 61, 70),
            140px 208px rgb(165, 120, 138), 140px 212px rgb(118, 80, 95),
            140px 216px rgb(52, 34, 45), 140px 236px rgb(52, 34, 45),
            144px 108px rgb(52, 34, 45), 144px 112px rgb(52, 34, 45),
            144px 116px rgb(52, 34, 45), 144px 120px rgb(52, 34, 45),
            144px 124px rgb(76, 53, 68), 144px 128px rgb(118, 80, 95),
            144px 132px rgb(118, 80, 95), 144px 144px rgb(118, 80, 95),
            144px 148px rgb(52, 34, 45), 144px 152px rgb(76, 53, 68),
            144px 156px rgb(76, 53, 68), 144px 160px rgb(76, 53, 68),
            144px 164px rgb(76, 53, 68), 144px 168px rgb(52, 34, 45),
            144px 172px rgb(52, 34, 45), 144px 176px rgb(38, 29, 35),
            144px 180px rgb(52, 34, 45), 144px 184px rgb(52, 34, 45),
            144px 188px rgb(52, 34, 45), 144px 192px rgb(60, 61, 70),
            144px 196px rgb(60, 61, 70), 144px 208px rgb(165, 120, 138),
            144px 212px rgb(76, 53, 68), 144px 232px rgb(52, 34, 45),
            144px 236px rgb(60, 61, 70), 148px 104px rgb(52, 34, 45),
            148px 108px rgb(52, 34, 45), 148px 112px rgb(76, 53, 68),
            148px 116px rgb(76, 53, 68), 148px 120px rgb(52, 34, 45),
            148px 124px rgb(76, 53, 68), 148px 128px rgb(118, 80, 95),
            148px 148px rgb(52, 34, 45), 148px 152px rgb(76, 53, 68),
            148px 156px rgb(118, 80, 95), 148px 160px rgb(76, 53, 68),
            148px 164px rgb(76, 53, 68), 148px 168px rgb(52, 34, 45),
            148px 172px rgb(76, 53, 68), 148px 176px rgb(76, 53, 68),
            148px 180px rgb(76, 53, 68), 148px 184px rgb(76, 53, 68),
            148px 188px rgb(76, 53, 68), 148px 192px rgb(52, 34, 45),
            148px 196px rgb(52, 34, 45), 148px 200px rgb(60, 61, 70),
            148px 204px rgb(82, 84, 101), 148px 208px rgb(82, 84, 101),
            148px 212px rgb(82, 84, 101), 148px 216px rgb(82, 84, 101),
            152px 100px rgb(52, 34, 45), 152px 104px rgb(76, 53, 68),
            152px 108px rgb(118, 80, 95), 152px 112px rgb(76, 53, 68),
            152px 116px rgb(76, 53, 68), 152px 120px rgb(76, 53, 68),
            152px 124px rgb(52, 34, 45), 152px 128px rgb(118, 80, 95),
            152px 148px rgb(52, 34, 45), 152px 152px rgb(76, 53, 68),
            152px 156px rgb(118, 80, 95), 152px 160px rgb(118, 80, 95),
            152px 164px rgb(76, 53, 68), 152px 168px rgb(76, 53, 68),
            152px 172px rgb(52, 34, 45), 152px 180px rgb(76, 53, 68),
            152px 184px rgb(76, 53, 68), 152px 188px rgb(76, 53, 68),
            152px 192px rgb(52, 34, 45), 152px 196px rgb(52, 34, 45),
            152px 200px rgb(60, 61, 70), 152px 204px rgb(82, 84, 101),
            152px 208px rgb(82, 84, 101), 152px 212px rgb(82, 84, 101),
            156px 100px rgb(52, 34, 45), 156px 104px rgb(118, 80, 95),
            156px 108px rgb(52, 34, 45), 156px 112px rgb(76, 53, 68),
            156px 116px rgb(52, 34, 45), 156px 120px rgb(52, 34, 45),
            156px 124px rgb(52, 34, 45), 156px 128px rgb(52, 34, 45),
            156px 132px rgb(118, 80, 95), 156px 144px rgb(118, 80, 95),
            156px 148px rgb(52, 34, 45), 156px 152px rgb(52, 34, 45),
            156px 156px rgb(76, 53, 68), 156px 160px rgb(118, 80, 95),
            156px 164px rgb(52, 34, 45), 156px 168px rgb(76, 53, 68),
            156px 172px rgb(52, 34, 45), 156px 184px rgb(76, 53, 68),
            156px 188px rgb(76, 53, 68), 156px 192px rgb(52, 34, 45),
            156px 204px rgb(60, 61, 70), 156px 208px rgb(82, 84, 101),
            156px 212px rgb(82, 84, 102), 156px 216px rgb(82, 84, 102),
            156px 220px rgb(82, 84, 101), 160px 100px rgb(52, 34, 45),
            160px 104px rgb(52, 34, 45), 160px 108px rgb(76, 53, 68),
            160px 112px rgb(52, 34, 45), 160px 116px rgb(76, 53, 68),
            160px 120px rgb(76, 53, 68), 160px 124px rgb(52, 34, 45),
            160px 128px rgb(52, 34, 45), 160px 132px rgb(52, 34, 45),
            160px 136px rgb(52, 34, 45), 160px 140px rgb(52, 34, 45),
            160px 144px rgb(52, 34, 45), 160px 148px rgb(52, 34, 45),
            160px 152px rgb(52, 34, 45), 160px 156px rgb(52, 34, 45),
            160px 160px rgb(52, 34, 45), 160px 164px rgb(76, 53, 68),
            160px 168px rgb(76, 53, 68), 160px 172px rgb(52, 34, 45),
            160px 184px rgb(60, 61, 70), 160px 192px rgb(101, 104, 126),
            160px 204px rgb(60, 61, 70), 160px 208px rgb(82, 84, 101),
            160px 212px rgb(82, 84, 102), 160px 220px rgb(82, 84, 102),
            160px 224px rgb(82, 84, 101), 164px 100px rgb(52, 34, 45),
            164px 104px rgb(76, 53, 68), 164px 108px rgb(76, 53, 68),
            164px 112px rgb(76, 53, 68), 164px 116px rgb(76, 53, 68),
            164px 120px rgb(52, 34, 45), 164px 124px rgb(118, 80, 95),
            164px 128px rgb(52, 34, 45), 164px 132px rgb(76, 53, 68),
            164px 136px rgb(76, 53, 68), 164px 140px rgb(52, 34, 45),
            164px 144px rgb(52, 34, 45), 164px 148px rgb(52, 34, 45),
            164px 152px rgb(76, 53, 68), 164px 156px rgb(76, 53, 68),
            164px 160px rgb(52, 34, 45), 164px 164px rgb(76, 53, 68),
            164px 168px rgb(76, 53, 68), 164px 172px rgb(52, 34, 45),
            164px 176px rgb(52, 34, 45), 164px 180px rgb(60, 61, 70),
            164px 184px rgb(60, 61, 70), 164px 188px rgb(82, 84, 102),
            164px 192px rgb(82, 84, 101), 164px 196px rgb(82, 84, 102),
            164px 204px rgb(60, 61, 70), 164px 208px rgb(82, 84, 102),
            164px 212px rgb(82, 84, 101), 164px 216px rgb(82, 84, 102),
            168px 96px rgb(52, 34, 45), 168px 100px rgb(76, 53, 68),
            168px 104px rgb(76, 53, 68), 168px 108px rgb(118, 80, 95),
            168px 112px rgb(76, 53, 68), 168px 116px rgb(52, 34, 45),
            168px 120px rgb(76, 53, 68), 168px 124px rgb(76, 53, 68),
            168px 128px rgb(76, 53, 68), 168px 132px rgb(118, 80, 95),
            168px 136px rgb(118, 80, 95), 168px 140px rgb(76, 53, 68),
            168px 144px rgb(76, 53, 68), 168px 148px rgb(52, 34, 45),
            168px 152px rgb(52, 34, 45), 168px 156px rgb(76, 53, 68),
            168px 160px rgb(52, 34, 45), 168px 164px rgb(76, 53, 68),
            168px 168px rgb(118, 80, 95), 168px 172px rgb(52, 34, 45),
            168px 176px rgb(52, 34, 45), 168px 180px rgb(52, 34, 45),
            168px 184px rgb(52, 34, 45), 168px 188px rgb(60, 61, 70),
            168px 192px rgb(82, 84, 102), 168px 196px rgb(82, 84, 102),
            168px 200px rgb(82, 84, 102), 168px 204px rgb(82, 84, 102),
            168px 208px rgb(82, 84, 102), 168px 212px rgb(82, 84, 101),
            168px 216px rgb(82, 84, 102), 168px 220px rgb(82, 84, 102),
            172px 96px rgb(52, 34, 45), 172px 100px rgb(76, 53, 68),
            172px 104px rgb(76, 53, 68), 172px 108px rgb(76, 53, 68),
            172px 112px rgb(76, 53, 68), 172px 116px rgb(52, 34, 45),
            172px 120px rgb(76, 53, 68), 172px 124px rgb(118, 80, 95),
            172px 128px rgb(76, 53, 68), 172px 132px rgb(118, 80, 95),
            172px 136px rgb(118, 80, 95), 172px 140px rgb(118, 80, 95),
            172px 144px rgb(118, 80, 95), 172px 148px rgb(52, 34, 45),
            172px 152px rgb(52, 34, 45), 172px 156px rgb(52, 34, 45),
            172px 160px rgb(52, 34, 45), 172px 164px rgb(118, 80, 95),
            172px 168px rgb(118, 80, 95), 172px 172px rgb(52, 34, 45),
            172px 176px rgb(52, 34, 45), 172px 180px rgb(52, 34, 45),
            172px 184px rgb(52, 34, 45), 172px 188px rgb(60, 61, 70),
            172px 192px rgb(82, 84, 102), 172px 196px rgb(82, 84, 102),
            172px 200px rgb(82, 84, 102), 172px 204px rgb(82, 84, 102),
            172px 208px rgb(82, 84, 102), 172px 212px rgb(82, 84, 102),
            172px 216px rgb(82, 84, 102), 172px 220px rgb(82, 84, 102),
            172px 224px rgb(82, 84, 102), 172px 228px rgb(82, 84, 101),
            176px 96px rgb(76, 53, 68), 176px 100px rgb(118, 80, 95),
            176px 104px rgb(76, 53, 68), 176px 108px rgb(76, 53, 68),
            176px 112px rgb(118, 80, 95), 176px 116px rgb(76, 53, 68),
            176px 120px rgb(118, 80, 95), 176px 124px rgb(76, 53, 68),
            176px 132px rgb(76, 53, 68), 176px 148px rgb(52, 34, 45),
            176px 152px rgb(52, 34, 45), 176px 156px rgb(52, 34, 45),
            176px 160px rgb(82, 84, 101), 176px 164px rgb(118, 80, 95),
            176px 168px rgb(118, 80, 95), 176px 172px rgb(52, 34, 45),
            176px 176px rgb(82, 84, 101), 176px 180px rgb(52, 34, 45),
            176px 184px rgb(52, 34, 45), 176px 188px rgb(60, 61, 70),
            176px 192px rgb(82, 84, 102), 176px 196px rgb(82, 84, 102),
            176px 200px rgb(82, 84, 102), 176px 204px rgb(82, 84, 102),
            176px 208px rgb(82, 84, 102), 176px 212px rgb(82, 84, 102),
            176px 216px rgb(82, 84, 102), 176px 220px rgb(82, 84, 102),
            176px 224px rgb(82, 84, 102), 176px 232px rgb(82, 84, 102),
            180px 96px rgb(76, 53, 68), 180px 100px rgb(118, 80, 95),
            180px 104px rgb(118, 80, 95), 180px 108px rgb(118, 80, 95),
            180px 112px rgb(118, 80, 95), 180px 116px rgb(76, 53, 68),
            180px 120px rgb(118, 80, 95), 180px 128px rgb(76, 53, 68),
            180px 140px rgb(76, 53, 68), 180px 148px rgb(52, 34, 45),
            180px 152px rgb(52, 34, 45), 180px 156px rgb(52, 34, 45),
            180px 160px rgb(82, 84, 101), 180px 164px rgb(118, 80, 95),
            180px 168px rgb(118, 80, 95), 180px 172px rgb(52, 34, 45),
            180px 176px rgb(101, 104, 126), 180px 180px rgb(52, 34, 45),
            180px 184px rgb(52, 34, 45), 180px 188px rgb(82, 84, 102),
            180px 192px rgb(101, 104, 126), 180px 196px rgb(101, 104, 126),
            180px 200px rgb(82, 84, 102), 180px 204px rgb(82, 84, 102),
            180px 208px rgb(82, 84, 102), 180px 212px rgb(82, 84, 102),
            180px 216px rgb(82, 84, 102), 180px 220px rgb(82, 84, 102),
            180px 224px rgb(82, 84, 102), 180px 228px rgb(82, 84, 101),
            184px 96px rgb(76, 53, 68), 184px 100px rgb(76, 53, 68),
            184px 104px rgb(118, 80, 95), 184px 108px rgb(118, 80, 95),
            184px 112px rgb(76, 53, 68), 184px 116px rgb(118, 80, 95),
            184px 140px rgb(76, 53, 68), 184px 148px rgb(52, 34, 45),
            184px 152px rgb(52, 34, 45), 184px 156px rgb(52, 34, 45),
            184px 160px rgb(82, 84, 101), 184px 164px rgb(82, 84, 101),
            184px 168px rgb(76, 53, 68), 184px 176px rgb(101, 104, 126),
            184px 184px rgb(52, 34, 45), 184px 188px rgb(82, 84, 102),
            184px 192px rgb(101, 104, 126), 184px 196px rgb(82, 84, 102),
            184px 200px rgb(82, 84, 102), 184px 204px rgb(101, 104, 126),
            184px 208px rgb(101, 104, 126), 184px 212px rgb(82, 84, 102),
            184px 216px rgb(82, 84, 102), 184px 220px rgb(82, 84, 102),
            184px 224px rgb(82, 84, 102), 184px 228px rgb(82, 84, 102),
            184px 232px rgb(82, 84, 102), 184px 236px rgb(82, 84, 102),
            188px 96px rgb(76, 53, 68), 188px 100px rgb(76, 53, 68),
            188px 104px rgb(76, 53, 68), 188px 108px rgb(76, 53, 68),
            188px 112px rgb(76, 53, 68), 188px 116px rgb(76, 53, 68),
            188px 148px rgb(52, 34, 45), 188px 152px rgb(118, 80, 95),
            188px 156px rgb(76, 53, 68), 188px 160px rgb(82, 84, 101),
            188px 164px rgb(82, 84, 101), 188px 184px rgb(101, 104, 126),
            188px 188px rgb(101, 104, 126), 188px 192px rgb(101, 104, 126),
            188px 196px rgb(101, 104, 126), 188px 200px rgb(101, 104, 126),
            188px 204px rgb(82, 84, 102), 188px 208px rgb(82, 84, 102),
            188px 212px rgb(82, 84, 102), 188px 216px rgb(82, 84, 102),
            188px 220px rgb(82, 84, 102), 188px 224px rgb(82, 84, 102),
            188px 228px rgb(82, 84, 102), 188px 232px rgb(82, 84, 102),
            192px 100px rgb(76, 53, 68), 192px 104px rgb(118, 80, 95),
            192px 108px rgb(118, 80, 95), 192px 112px rgb(118, 80, 95),
            192px 116px rgb(76, 53, 68), 192px 120px rgb(76, 53, 68),
            192px 124px rgb(76, 53, 68), 192px 132px rgb(76, 53, 68),
            192px 148px rgb(76, 53, 68), 192px 152px rgb(118, 80, 95),
            192px 156px rgb(76, 53, 68), 192px 160px rgb(82, 84, 101),
            192px 164px rgb(82, 84, 101), 192px 168px rgb(82, 84, 102),
            192px 184px rgb(101, 104, 126), 192px 188px rgb(101, 104, 126),
            192px 192px rgb(101, 104, 126), 192px 196px rgb(82, 84, 102),
            192px 200px rgb(82, 84, 102), 192px 204px rgb(80, 82, 98),
            192px 208px rgb(101, 104, 126), 192px 212px rgb(82, 84, 102),
            192px 216px rgb(82, 84, 102), 192px 220px rgb(82, 84, 102),
            192px 224px rgb(82, 84, 102), 192px 228px rgb(82, 84, 102),
            196px 104px rgb(76, 53, 68), 196px 108px rgb(76, 53, 68),
            196px 112px rgb(76, 53, 68), 196px 116px rgb(76, 53, 68),
            196px 120px rgb(118, 80, 95), 196px 124px rgb(118, 80, 95),
            196px 128px rgb(76, 53, 68), 196px 144px rgb(82, 84, 101),
            196px 148px rgb(76, 53, 68), 196px 152px rgb(118, 80, 95),
            196px 156px rgb(76, 53, 68), 196px 160px rgb(82, 84, 101),
            196px 164px rgb(101, 104, 126), 196px 168px rgb(101, 104, 126),
            196px 172px rgb(101, 104, 126), 196px 176px rgb(101, 104, 126),
            196px 180px rgb(101, 104, 126), 196px 184px rgb(101, 104, 126),
            196px 188px rgb(101, 104, 126), 196px 192px rgb(101, 104, 126),
            196px 196px rgb(82, 84, 102), 196px 200px rgb(101, 104, 126),
            196px 204px rgb(82, 84, 102), 196px 208px rgb(82, 84, 102),
            196px 212px rgb(82, 84, 102), 196px 216px rgb(82, 84, 102),
            196px 220px rgb(82, 84, 102), 196px 224px rgb(82, 84, 102),
            196px 232px rgb(82, 84, 102), 196px 236px rgb(82, 84, 102),
            200px 112px rgb(76, 53, 68), 200px 116px rgb(76, 53, 68),
            200px 120px rgb(76, 53, 68), 200px 124px rgb(76, 53, 68),
            200px 140px rgb(82, 84, 101), 200px 148px rgb(76, 53, 68),
            200px 152px rgb(52, 34, 45), 200px 156px rgb(52, 34, 45),
            200px 160px rgb(82, 84, 101), 200px 164px rgb(82, 84, 102),
            200px 168px rgb(101, 104, 126), 200px 172px rgb(101, 104, 126),
            200px 176px rgb(101, 104, 126), 200px 180px rgb(101, 104, 126),
            200px 184px rgb(101, 104, 126), 200px 188px rgb(101, 104, 126),
            200px 192px rgb(101, 104, 126), 200px 196px rgb(101, 104, 126),
            200px 200px rgb(101, 104, 126), 200px 204px rgb(101, 104, 126),
            200px 208px rgb(101, 104, 126), 200px 212px rgb(101, 104, 126),
            200px 216px rgb(101, 104, 126), 200px 220px rgb(82, 84, 102),
            200px 224px rgb(82, 84, 102), 200px 228px rgb(82, 84, 102),
            200px 232px rgb(82, 84, 102), 200px 236px rgb(82, 84, 102),
            200px 240px rgb(82, 84, 102), 204px 144px rgb(82, 84, 101),
            204px 148px rgb(52, 34, 45), 204px 156px rgb(82, 84, 101),
            204px 160px rgb(101, 104, 126), 204px 164px rgb(101, 104, 126),
            204px 168px rgb(101, 104, 126), 204px 172px rgb(101, 104, 126),
            204px 176px rgb(101, 104, 126), 204px 180px rgb(101, 104, 126),
            204px 184px rgb(101, 104, 126), 204px 188px rgb(101, 104, 126),
            204px 192px rgb(101, 104, 126), 204px 196px rgb(101, 104, 126),
            204px 200px rgb(101, 104, 126), 204px 204px rgb(101, 104, 126),
            204px 208px rgb(101, 104, 126), 204px 212px rgb(82, 84, 102),
            204px 216px rgb(82, 84, 102), 204px 220px rgb(82, 84, 102),
            204px 224px rgb(82, 84, 102), 204px 228px rgb(82, 84, 102),
            204px 232px rgb(82, 84, 102), 208px 156px rgb(82, 84, 101),
            208px 160px rgb(82, 84, 101), 208px 164px rgb(101, 104, 126),
            208px 168px rgb(101, 104, 126), 208px 172px rgb(101, 104, 126),
            208px 176px rgb(101, 104, 126), 208px 180px rgb(101, 104, 126),
            208px 184px rgb(101, 104, 126), 208px 188px rgb(101, 104, 126),
            208px 192px rgb(101, 104, 126), 208px 196px rgb(101, 104, 126),
            208px 200px rgb(101, 104, 126), 208px 204px rgb(101, 104, 126),
            208px 208px rgb(82, 84, 102), 208px 212px rgb(82, 84, 102),
            208px 216px rgb(82, 84, 102), 208px 220px rgb(82, 84, 102),
            208px 224px rgb(82, 84, 102), 208px 228px rgb(82, 84, 102),
            208px 232px rgb(82, 84, 102), 208px 236px rgb(82, 84, 102),
            212px 136px rgb(82, 84, 101), 212px 140px rgb(82, 84, 101),
            212px 152px rgb(82, 84, 101), 212px 156px rgb(82, 84, 101),
            212px 160px rgb(101, 104, 126), 212px 164px rgb(101, 104, 126),
            212px 168px rgb(101, 104, 126), 212px 172px rgb(101, 104, 126),
            212px 176px rgb(101, 104, 126), 212px 180px rgb(101, 104, 126),
            212px 184px rgb(101, 104, 126), 212px 188px rgb(101, 104, 126),
            212px 192px rgb(101, 104, 126), 212px 196px rgb(101, 104, 126),
            212px 200px rgb(101, 104, 126), 212px 204px rgb(82, 84, 102),
            212px 208px rgb(101, 104, 126), 212px 212px rgb(101, 104, 126),
            212px 216px rgb(101, 104, 126), 212px 220px rgb(82, 84, 102),
            212px 224px rgb(82, 84, 102), 212px 228px rgb(82, 84, 102),
            212px 232px rgb(82, 84, 102), 212px 240px rgb(82, 84, 102),
            212px 244px rgb(60, 61, 70), 212px 248px rgb(60, 61, 70),
            212px 252px rgb(60, 61, 70), 216px 148px rgb(82, 84, 101),
            216px 152px rgb(82, 84, 102), 216px 156px rgb(101, 104, 126),
            216px 160px rgb(101, 104, 126), 216px 164px rgb(101, 104, 126),
            216px 168px rgb(101, 104, 126), 216px 172px rgb(101, 104, 126),
            216px 176px rgb(101, 104, 126), 216px 180px rgb(101, 104, 126),
            216px 184px rgb(101, 104, 126), 216px 188px rgb(101, 104, 126),
            216px 192px rgb(101, 104, 126), 216px 196px rgb(101, 104, 126),
            216px 200px rgb(101, 104, 126), 216px 204px rgb(101, 104, 126),
            216px 208px rgb(101, 104, 126), 216px 212px rgb(82, 84, 102),
            216px 216px rgb(82, 84, 102), 216px 220px rgb(82, 84, 102),
            216px 224px rgb(82, 84, 102), 216px 228px rgb(82, 84, 102),
            216px 232px rgb(82, 84, 102), 216px 236px rgb(60, 61, 70),
            216px 240px rgb(60, 61, 70), 216px 244px rgb(60, 61, 70),
            216px 248px rgb(60, 61, 70), 216px 252px rgb(60, 61, 70),
            220px 140px rgb(82, 84, 101), 220px 148px rgb(82, 84, 101),
            220px 152px rgb(101, 104, 126), 220px 156px rgb(82, 84, 101),
            220px 160px rgb(82, 84, 101), 220px 164px rgb(101, 104, 126),
            220px 168px rgb(101, 104, 126), 220px 172px rgb(101, 104, 126),
            220px 176px rgb(101, 104, 126), 220px 180px rgb(101, 104, 126),
            220px 184px rgb(165, 120, 138), 220px 188px rgb(101, 104, 126),
            220px 192px rgb(101, 104, 126), 220px 196px rgb(101, 104, 126),
            220px 200px rgb(101, 104, 126), 220px 204px rgb(101, 104, 126),
            220px 208px rgb(101, 104, 126), 220px 212px rgb(101, 104, 126),
            220px 216px rgb(101, 104, 126), 220px 220px rgb(101, 104, 126),
            220px 224px rgb(82, 84, 102), 220px 228px rgb(82, 84, 102),
            220px 232px rgb(60, 61, 70), 220px 236px rgb(60, 61, 70),
            220px 240px rgb(60, 61, 70), 220px 244px rgb(60, 61, 70),
            220px 248px rgb(60, 61, 70), 220px 252px rgb(60, 61, 70),
            224px 132px rgb(82, 84, 101), 224px 144px rgb(82, 84, 101),
            224px 148px rgb(82, 84, 101), 224px 152px rgb(82, 84, 102),
            224px 156px rgb(101, 104, 126), 224px 160px rgb(101, 104, 126),
            224px 164px rgb(101, 104, 126), 224px 168px rgb(101, 104, 126),
            224px 172px rgb(101, 104, 126), 224px 176px rgb(165, 120, 138),
            224px 180px rgb(165, 120, 138), 224px 184px rgb(165, 120, 138),
            224px 188px rgb(165, 120, 138), 224px 192px rgb(101, 104, 126),
            224px 196px rgb(101, 104, 126), 224px 200px rgb(101, 104, 126),
            224px 204px rgb(101, 104, 126), 224px 208px rgb(101, 104, 126),
            224px 212px rgb(101, 104, 126), 224px 216px rgb(82, 84, 102),
            224px 220px rgb(82, 84, 102), 224px 224px rgb(76, 53, 68),
            224px 228px rgb(76, 53, 68), 224px 232px rgb(60, 61, 70),
            224px 236px rgb(60, 61, 70), 224px 240px rgb(60, 61, 70),
            224px 244px rgb(60, 61, 70), 224px 248px rgb(60, 61, 70),
            224px 252px rgb(60, 61, 70), 228px 140px rgb(82, 84, 102),
            228px 144px rgb(82, 84, 101), 228px 148px rgb(101, 104, 126),
            228px 152px rgb(101, 104, 126), 228px 156px rgb(101, 104, 126),
            228px 160px rgb(101, 104, 126), 228px 164px rgb(101, 104, 126),
            228px 168px rgb(101, 104, 126), 228px 172px rgb(101, 104, 126),
            228px 176px rgb(101, 104, 126), 228px 180px rgb(165, 120, 138),
            228px 184px rgb(165, 120, 138), 228px 188px rgb(165, 120, 138),
            228px 192px rgb(165, 120, 138), 228px 196px rgb(101, 104, 126),
            228px 200px rgb(101, 104, 126), 228px 204px rgb(101, 104, 126),
            228px 208px rgb(101, 104, 126), 228px 212px rgb(82, 84, 102),
            228px 216px rgb(101, 104, 126), 228px 220px rgb(76, 53, 68),
            228px 224px rgb(76, 53, 68), 228px 228px rgb(38, 29, 35),
            228px 232px rgb(60, 61, 70), 228px 236px rgb(60, 61, 70),
            228px 240px rgb(60, 61, 70), 228px 244px rgb(60, 61, 70),
            228px 248px rgb(60, 61, 70), 232px 132px rgb(82, 84, 101),
            232px 136px rgb(82, 84, 101), 232px 140px rgb(82, 84, 101),
            232px 144px rgb(82, 84, 101), 232px 148px rgb(82, 84, 101),
            232px 152px rgb(82, 84, 102), 232px 156px rgb(101, 104, 126),
            232px 160px rgb(101, 104, 126), 232px 164px rgb(101, 104, 126),
            232px 168px rgb(101, 104, 126), 232px 172px rgb(165, 120, 138),
            232px 176px rgb(165, 120, 138), 232px 180px rgb(165, 120, 138),
            232px 184px rgb(165, 120, 138), 232px 188px rgb(165, 120, 138),
            232px 192px rgb(101, 104, 126), 232px 196px rgb(101, 104, 126),
            232px 200px rgb(101, 104, 126), 232px 204px rgb(101, 104, 126),
            232px 208px rgb(101, 104, 126), 232px 212px rgb(101, 104, 126),
            232px 216px rgb(76, 53, 68), 232px 220px rgb(76, 53, 68),
            232px 224px rgb(38, 29, 35), 232px 228px rgb(38, 29, 35),
            232px 232px rgb(60, 61, 70), 232px 236px rgb(60, 61, 70),
            232px 240px rgb(60, 61, 70), 232px 244px rgb(60, 61, 70),
            236px 140px rgb(82, 84, 102), 236px 144px rgb(82, 84, 101),
            236px 148px rgb(82, 84, 102), 236px 152px rgb(101, 104, 126),
            236px 156px rgb(101, 104, 126), 236px 160px rgb(101, 104, 126),
            236px 164px rgb(101, 104, 126), 236px 168px rgb(101, 104, 126),
            236px 172px rgb(165, 120, 138), 236px 176px rgb(165, 120, 138),
            236px 180px rgb(165, 120, 138), 236px 184px rgb(165, 120, 138),
            236px 188px rgb(165, 120, 138), 236px 192px rgb(165, 120, 138),
            236px 196px rgb(101, 104, 126), 236px 200px rgb(101, 104, 126),
            236px 204px rgb(101, 104, 126), 236px 208px rgb(101, 104, 126),
            236px 212px rgb(101, 104, 126), 236px 216px rgb(118, 80, 95),
            236px 220px rgb(76, 53, 68), 236px 224px rgb(38, 29, 35),
            236px 228px rgb(38, 29, 35), 236px 232px rgb(60, 61, 70),
            236px 236px rgb(60, 61, 70), 236px 240px rgb(60, 61, 70),
            240px 136px rgb(82, 84, 101), 240px 140px rgb(82, 84, 101),
            240px 144px rgb(82, 84, 101), 240px 148px rgb(101, 104, 126),
            240px 152px rgb(101, 104, 126), 240px 156px rgb(82, 84, 102),
            240px 160px rgb(101, 104, 126), 240px 164px rgb(101, 104, 126),
            240px 168px rgb(101, 104, 126), 240px 172px rgb(165, 120, 138),
            240px 176px rgb(165, 120, 138), 240px 180px rgb(165, 120, 138),
            240px 184px rgb(165, 120, 138), 240px 188px rgb(165, 120, 138),
            240px 192px rgb(165, 120, 138), 240px 196px rgb(165, 120, 138),
            240px 200px rgb(165, 120, 138), 240px 204px rgb(101, 104, 126),
            240px 208px rgb(101, 104, 126), 240px 212px rgb(101, 104, 126),
            240px 216px rgb(118, 80, 95), 240px 220px rgb(76, 53, 68),
            240px 224px rgb(38, 29, 35), 240px 228px rgb(38, 29, 35),
            240px 232px rgb(60, 61, 70), 240px 236px rgb(60, 61, 70),
            240px 240px rgb(82, 84, 102), 244px 132px rgb(82, 84, 101),
            244px 136px rgb(82, 84, 101), 244px 140px rgb(82, 84, 102),
            244px 144px rgb(82, 84, 102), 244px 148px rgb(82, 84, 102),
            244px 152px rgb(82, 84, 102), 244px 156px rgb(101, 104, 126),
            244px 160px rgb(101, 104, 126), 244px 164px rgb(101, 104, 126),
            244px 168px rgb(165, 120, 138), 244px 172px rgb(165, 120, 138),
            244px 176px rgb(165, 120, 138), 244px 180px rgb(165, 120, 138),
            244px 184px rgb(165, 120, 138), 244px 188px rgb(165, 120, 138),
            244px 192px rgb(165, 120, 138), 244px 196px rgb(165, 120, 138),
            244px 200px rgb(101, 104, 126), 244px 204px rgb(101, 104, 126),
            244px 208px rgb(101, 104, 126), 244px 212px rgb(101, 104, 126),
            244px 216px rgb(82, 84, 102), 244px 220px rgb(52, 34, 45),
            244px 224px rgb(52, 34, 45), 244px 228px rgb(38, 29, 35),
            244px 232px rgb(82, 84, 102), 244px 236px rgb(82, 84, 102),
            244px 240px rgb(82, 84, 102), 248px 128px rgb(82, 84, 102),
            248px 136px rgb(82, 84, 101), 248px 140px rgb(82, 84, 102),
            248px 148px rgb(82, 84, 102), 248px 152px rgb(82, 84, 102),
            248px 156px rgb(101, 104, 126), 248px 160px rgb(101, 104, 126),
            248px 164px rgb(101, 104, 126), 248px 168px rgb(165, 120, 138),
            248px 172px rgb(165, 120, 138), 248px 176px rgb(165, 120, 138),
            248px 180px rgb(165, 120, 138), 248px 184px rgb(165, 120, 138),
            248px 188px rgb(165, 120, 138), 248px 192px rgb(165, 120, 138),
            248px 196px rgb(165, 120, 138), 248px 200px rgb(165, 120, 138),
            248px 204px rgb(101, 104, 126), 248px 208px rgb(101, 104, 126),
            248px 212px rgb(101, 104, 126), 248px 216px rgb(101, 104, 126),
            248px 220px rgb(101, 104, 126), 248px 224px rgb(101, 104, 126),
            248px 228px rgb(101, 104, 126), 248px 232px rgb(82, 84, 102),
            248px 236px rgb(82, 84, 102), 252px 132px rgb(82, 84, 102),
            252px 136px rgb(82, 84, 102), 252px 140px rgb(82, 84, 102),
            252px 144px rgb(82, 84, 102), 252px 148px rgb(82, 84, 102),
            252px 152px rgb(101, 104, 126), 252px 156px rgb(101, 104, 126),
            252px 160px rgb(101, 104, 126), 252px 164px rgb(101, 104, 126),
            252px 168px rgb(165, 120, 138), 252px 172px rgb(165, 120, 138),
            252px 176px rgb(165, 120, 138), 252px 180px rgb(165, 120, 138),
            252px 184px rgb(165, 120, 138), 252px 188px rgb(165, 120, 138),
            252px 192px rgb(165, 120, 138), 252px 196px rgb(165, 120, 138),
            252px 200px rgb(165, 120, 138), 252px 204px rgb(101, 104, 126),
            252px 208px rgb(101, 104, 126), 252px 212px rgb(101, 104, 126),
            252px 216px rgb(101, 104, 126), 252px 220px rgb(82, 84, 102),
            252px 224px rgb(101, 104, 126), 252px 228px rgb(101, 104, 126),
            252px 232px rgb(82, 84, 102), 252px 236px rgb(82, 84, 102),
            252px 240px rgb(82, 84, 102), 252px 244px rgb(82, 84, 102),
            256px 124px rgb(82, 84, 102), 256px 132px rgb(82, 84, 102),
            256px 136px rgb(82, 84, 102), 256px 140px rgb(82, 84, 102),
            256px 144px rgb(82, 84, 102), 256px 148px rgb(82, 84, 102),
            256px 152px rgb(101, 104, 126), 256px 156px rgb(101, 104, 126),
            256px 160px rgb(101, 104, 126), 256px 164px rgb(101, 104, 126),
            256px 168px rgb(165, 120, 138), 256px 172px rgb(165, 120, 138),
            256px 176px rgb(165, 120, 138), 256px 180px rgb(165, 120, 138),
            256px 184px rgb(165, 120, 138), 256px 188px rgb(78, 51, 68),
            256px 192px rgb(52, 34, 45), 256px 196px rgb(165, 120, 138),
            256px 200px rgb(165, 120, 138), 256px 204px rgb(165, 120, 138),
            256px 208px rgb(101, 104, 126), 256px 212px rgb(101, 104, 126),
            256px 216px rgb(101, 104, 126), 256px 220px rgb(101, 104, 126),
            256px 224px rgb(82, 84, 102), 256px 228px rgb(82, 84, 102),
            256px 232px rgb(82, 84, 102), 256px 236px rgb(82, 84, 102),
            256px 240px rgb(82, 84, 102), 256px 248px rgb(82, 84, 102),
            260px 128px rgb(82, 84, 102), 260px 132px rgb(82, 84, 102),
            260px 136px rgb(82, 84, 102), 260px 140px rgb(82, 84, 102),
            260px 144px rgb(82, 84, 102), 260px 148px rgb(101, 104, 126),
            260px 152px rgb(101, 104, 126), 260px 156px rgb(101, 104, 126),
            260px 160px rgb(101, 104, 126), 260px 164px rgb(165, 120, 138),
            260px 168px rgb(165, 120, 138), 260px 172px rgb(78, 51, 68),
            260px 176px rgb(250, 141, 124), 260px 180px rgb(52, 34, 45),
            260px 184px rgb(52, 34, 45), 260px 188px rgb(98, 59, 63),
            260px 192px rgb(78, 51, 68), 260px 196px rgb(52, 34, 45),
            260px 200px rgb(165, 120, 138), 260px 204px rgb(165, 120, 138),
            260px 208px rgb(101, 104, 126), 260px 212px rgb(101, 104, 126),
            260px 216px rgb(101, 104, 126), 260px 220px rgb(101, 104, 126),
            260px 224px rgb(101, 104, 126), 260px 228px rgb(101, 104, 126),
            260px 232px rgb(101, 104, 126), 260px 236px rgb(82, 84, 102),
            260px 240px rgb(82, 84, 102), 260px 244px rgb(82, 84, 102),
            260px 248px rgb(82, 84, 102), 264px 128px rgb(82, 84, 102),
            264px 132px rgb(82, 84, 102), 264px 136px rgb(82, 84, 102),
            264px 140px rgb(82, 84, 102), 264px 144px rgb(82, 84, 102),
            264px 148px rgb(101, 104, 126), 264px 152px rgb(101, 104, 126),
            264px 156px rgb(101, 104, 126), 264px 160px rgb(101, 104, 126),
            264px 164px rgb(165, 120, 138), 264px 168px rgb(98, 59, 63),
            264px 172px rgb(52, 34, 45), 264px 176px rgb(52, 34, 45),
            264px 180px rgb(98, 59, 63), 264px 184px rgb(98, 59, 63),
            264px 188px rgb(98, 59, 63), 264px 192px rgb(52, 34, 45),
            264px 196px rgb(52, 34, 45), 264px 200px rgb(165, 120, 138),
            264px 204px rgb(101, 104, 126), 264px 208px rgb(101, 104, 126),
            264px 212px rgb(101, 104, 126), 264px 216px rgb(101, 104, 126),
            264px 220px rgb(101, 104, 126), 264px 224px rgb(101, 104, 126),
            264px 228px rgb(82, 84, 102), 264px 232px rgb(82, 84, 102),
            264px 236px rgb(82, 84, 102), 264px 240px rgb(60, 61, 70),
            264px 244px rgb(60, 61, 70), 264px 248px rgb(60, 61, 70),
            264px 252px rgb(60, 61, 70), 268px 124px rgb(82, 84, 102),
            268px 128px rgb(82, 84, 102), 268px 132px rgb(82, 84, 102),
            268px 136px rgb(82, 84, 102), 268px 140px rgb(82, 84, 102),
            268px 144px rgb(82, 84, 102), 268px 148px rgb(101, 104, 126),
            268px 152px rgb(101, 104, 126), 268px 156px rgb(101, 104, 126),
            268px 160px rgb(101, 104, 126), 268px 164px rgb(165, 120, 138),
            268px 168px rgb(52, 34, 45), 268px 172px rgb(52, 34, 45),
            268px 176px rgb(98, 59, 63), 268px 180px rgb(52, 34, 45),
            268px 184px rgb(124, 72, 72), 268px 188px rgb(52, 34, 45),
            268px 192px rgb(52, 34, 45), 268px 196px rgb(165, 120, 138),
            268px 200px rgb(165, 120, 138), 268px 204px rgb(101, 104, 126),
            268px 208px rgb(165, 120, 138), 268px 212px rgb(101, 104, 126),
            268px 216px rgb(101, 104, 126), 268px 220px rgb(101, 104, 126),
            268px 224px rgb(76, 53, 68), 268px 228px rgb(52, 34, 45),
            268px 232px rgb(60, 61, 70), 268px 236px rgb(60, 61, 70),
            268px 240px rgb(60, 61, 70), 268px 244px rgb(60, 61, 70),
            268px 248px rgb(60, 61, 70), 268px 252px rgb(60, 61, 70),
            272px 124px rgb(82, 84, 102), 272px 128px rgb(82, 84, 102),
            272px 132px rgb(82, 84, 102), 272px 136px rgb(82, 84, 102),
            272px 140px rgb(82, 84, 102), 272px 144px rgb(82, 84, 102),
            272px 148px rgb(82, 84, 102), 272px 152px rgb(101, 104, 126),
            272px 156px rgb(101, 104, 126), 272px 160px rgb(101, 104, 126),
            272px 164px rgb(165, 120, 138), 272px 168px rgb(165, 120, 138),
            272px 172px rgb(78, 51, 68), 272px 176px rgb(52, 34, 45),
            272px 180px rgb(250, 141, 124), 272px 184px rgb(98, 59, 63),
            272px 188px rgb(52, 34, 45), 272px 192px rgb(52, 34, 45),
            272px 196px rgb(165, 120, 138), 272px 200px rgb(165, 120, 138),
            272px 204px rgb(165, 120, 138), 272px 208px rgb(101, 104, 126),
            272px 212px rgb(101, 104, 126), 272px 216px rgb(101, 104, 126),
            272px 220px rgb(118, 80, 95), 272px 224px rgb(38, 29, 35),
            272px 228px rgb(38, 29, 35), 272px 232px rgb(60, 61, 70),
            272px 236px rgb(60, 61, 70), 272px 240px rgb(60, 61, 70),
            272px 244px rgb(60, 61, 70), 272px 248px rgb(82, 84, 102),
            276px 132px rgb(82, 84, 102), 276px 136px rgb(82, 84, 102),
            276px 140px rgb(82, 84, 102), 276px 144px rgb(101, 104, 126),
            276px 148px rgb(82, 84, 102), 276px 152px rgb(101, 104, 126),
            276px 156px rgb(101, 104, 126), 276px 160px rgb(101, 104, 126),
            276px 164px rgb(165, 120, 138), 276px 168px rgb(165, 120, 138),
            276px 172px rgb(52, 34, 45), 276px 176px rgb(250, 141, 124),
            276px 180px rgb(98, 59, 63), 276px 184px rgb(52, 34, 45),
            276px 188px rgb(52, 34, 45), 276px 192px rgb(165, 120, 138),
            276px 196px rgb(165, 120, 138), 276px 200px rgb(165, 120, 138),
            276px 204px rgb(165, 120, 138), 276px 208px rgb(165, 120, 138),
            276px 212px rgb(101, 104, 126), 276px 216px rgb(101, 104, 126),
            276px 220px rgb(118, 80, 95), 276px 224px rgb(38, 29, 35),
            276px 228px rgb(38, 29, 35), 276px 232px rgb(101, 104, 126),
            276px 236px rgb(82, 84, 102), 276px 240px rgb(82, 84, 102),
            276px 248px rgb(82, 84, 102), 280px 124px rgb(82, 84, 102),
            280px 132px rgb(82, 84, 102), 280px 136px rgb(82, 84, 102),
            280px 140px rgb(82, 84, 102), 280px 144px rgb(101, 104, 126),
            280px 148px rgb(101, 104, 126), 280px 152px rgb(101, 104, 126),
            280px 156px rgb(101, 104, 126), 280px 160px rgb(98, 59, 63),
            280px 164px rgb(98, 59, 63), 280px 168px rgb(165, 120, 138),
            280px 172px rgb(52, 34, 45), 280px 176px rgb(78, 51, 68),
            280px 180px rgb(78, 51, 68), 280px 184px rgb(52, 34, 45),
            280px 188px rgb(52, 34, 45), 280px 192px rgb(165, 120, 138),
            280px 196px rgb(165, 120, 138), 280px 200px rgb(165, 120, 138),
            280px 204px rgb(165, 120, 138), 280px 208px rgb(165, 120, 138),
            280px 212px rgb(101, 104, 126), 280px 216px rgb(101, 104, 126),
            280px 220px rgb(101, 104, 126), 280px 224px rgb(101, 104, 126),
            280px 228px rgb(101, 104, 126), 280px 232px rgb(52, 34, 45),
            280px 236px rgb(52, 34, 45), 280px 244px rgb(82, 84, 102),
            280px 248px rgb(82, 84, 102), 284px 124px rgb(82, 84, 102),
            284px 128px rgb(82, 84, 102), 284px 132px rgb(82, 84, 102),
            284px 136px rgb(82, 84, 102), 284px 140px rgb(82, 84, 102),
            284px 144px rgb(101, 104, 126), 284px 148px rgb(101, 104, 126),
            284px 152px rgb(101, 104, 126), 284px 156px rgb(52, 34, 45),
            284px 160px rgb(98, 59, 63), 284px 164px rgb(98, 59, 63),
            284px 168px rgb(98, 59, 63), 284px 172px rgb(98, 59, 63),
            284px 176px rgb(98, 59, 63), 284px 180px rgb(52, 34, 45),
            284px 184px rgb(52, 34, 45), 284px 188px rgb(165, 120, 138),
            284px 192px rgb(165, 120, 138), 284px 196px rgb(165, 120, 138),
            284px 200px rgb(165, 120, 138), 284px 204px rgb(165, 120, 138),
            284px 208px rgb(165, 120, 138), 284px 212px rgb(101, 104, 126),
            284px 216px rgb(101, 104, 126), 284px 220px rgb(101, 104, 126),
            284px 224px rgb(82, 84, 102), 284px 228px rgb(118, 80, 95),
            284px 232px rgb(52, 34, 45), 284px 236px rgb(38, 29, 35),
            284px 240px rgb(60, 61, 70), 284px 244px rgb(60, 61, 70),
            284px 248px rgb(60, 61, 70), 284px 252px rgb(60, 61, 70),
            288px 128px rgb(82, 84, 102), 288px 132px rgb(82, 84, 102),
            288px 136px rgb(82, 84, 102), 288px 140px rgb(82, 84, 102),
            288px 144px rgb(101, 104, 126), 288px 148px rgb(101, 104, 126),
            288px 152px rgb(101, 104, 126), 288px 156px rgb(98, 59, 63),
            288px 160px rgb(52, 34, 45), 288px 164px rgb(52, 34, 45),
            288px 168px rgb(52, 34, 45), 288px 172px rgb(98, 59, 63),
            288px 176px rgb(250, 141, 124), 288px 180px rgb(98, 59, 63),
            288px 184px rgb(52, 34, 45), 288px 188px rgb(165, 120, 138),
            288px 192px rgb(165, 120, 138), 288px 196px rgb(165, 120, 138),
            288px 200px rgb(165, 120, 138), 288px 204px rgb(165, 120, 138),
            288px 208px rgb(165, 120, 138), 288px 212px rgb(101, 104, 126),
            288px 216px rgb(101, 104, 126), 288px 220px rgb(101, 104, 126),
            288px 224px rgb(101, 104, 126), 288px 228px rgb(118, 80, 95),
            288px 232px rgb(38, 29, 35), 288px 236px rgb(38, 29, 35),
            288px 240px rgb(60, 61, 70), 288px 244px rgb(60, 61, 70),
            288px 248px rgb(60, 61, 70), 288px 252px rgb(60, 61, 70),
            292px 132px rgb(82, 84, 102), 292px 136px rgb(82, 84, 102),
            292px 140px rgb(82, 84, 102), 292px 144px rgb(101, 104, 126),
            292px 148px rgb(101, 104, 126), 292px 152px rgb(101, 104, 126),
            292px 156px rgb(101, 104, 126), 292px 160px rgb(52, 34, 45),
            292px 164px rgb(52, 34, 45), 292px 168px rgb(52, 34, 45),
            292px 172px rgb(52, 34, 45), 292px 176px rgb(52, 34, 45),
            292px 180px rgb(250, 141, 124), 292px 184px rgb(250, 141, 124),
            292px 188px rgb(98, 59, 63), 292px 192px rgb(52, 34, 45),
            292px 196px rgb(165, 120, 138), 292px 200px rgb(165, 120, 138),
            292px 204px rgb(165, 120, 138), 292px 208px rgb(101, 104, 126),
            292px 212px rgb(101, 104, 126), 292px 216px rgb(101, 104, 126),
            292px 220px rgb(101, 104, 126), 292px 224px rgb(101, 104, 126),
            292px 228px rgb(118, 80, 95), 292px 232px rgb(38, 29, 35),
            292px 236px rgb(38, 29, 35), 292px 240px rgb(60, 61, 70),
            292px 244px rgb(60, 61, 70), 292px 248px rgb(60, 61, 70),
            292px 252px rgb(60, 61, 70), 296px 124px rgb(82, 84, 102),
            296px 132px rgb(82, 84, 102), 296px 136px rgb(82, 84, 102),
            296px 140px rgb(82, 84, 102), 296px 144px rgb(101, 104, 126),
            296px 148px rgb(101, 104, 126), 296px 152px rgb(101, 104, 126),
            296px 156px rgb(101, 104, 126), 296px 160px rgb(101, 104, 126),
            296px 164px rgb(165, 120, 138), 296px 168px rgb(52, 34, 45),
            296px 172px rgb(78, 51, 68), 296px 176px rgb(52, 34, 45),
            296px 180px rgb(52, 34, 45), 296px 184px rgb(52, 34, 45),
            296px 188px rgb(98, 59, 63), 296px 192px rgb(78, 51, 68),
            296px 196px rgb(52, 34, 45), 296px 200px rgb(165, 120, 138),
            296px 204px rgb(165, 120, 138), 296px 208px rgb(165, 120, 138),
            296px 212px rgb(101, 104, 126), 296px 216px rgb(101, 104, 126),
            296px 220px rgb(101, 104, 126), 296px 224px rgb(101, 104, 126),
            296px 228px rgb(76, 53, 68), 296px 232px rgb(38, 29, 35),
            296px 236px rgb(38, 29, 35), 296px 240px rgb(60, 61, 70),
            296px 244px rgb(60, 61, 70), 296px 248px rgb(60, 61, 70),
            296px 252px rgb(60, 61, 70), 300px 132px rgb(82, 84, 102),
            300px 136px rgb(82, 84, 102), 300px 140px rgb(82, 84, 102),
            300px 144px rgb(82, 84, 102), 300px 148px rgb(101, 104, 126),
            300px 152px rgb(101, 104, 126), 300px 156px rgb(101, 104, 126),
            300px 160px rgb(101, 104, 126), 300px 164px rgb(165, 120, 138),
            300px 168px rgb(78, 51, 68), 300px 172px rgb(52, 34, 45),
            300px 176px rgb(52, 34, 45), 300px 180px rgb(165, 120, 138),
            300px 184px rgb(52, 34, 45), 300px 188px rgb(52, 34, 45),
            300px 192px rgb(78, 51, 68), 300px 196px rgb(52, 34, 45),
            300px 200px rgb(165, 120, 138), 300px 204px rgb(165, 120, 138),
            300px 208px rgb(165, 120, 138), 300px 212px rgb(101, 104, 126),
            300px 216px rgb(101, 104, 126), 300px 220px rgb(101, 104, 126),
            300px 224px rgb(101, 104, 126), 300px 228px rgb(101, 104, 126),
            300px 232px rgb(76, 53, 68), 300px 236px rgb(38, 29, 35),
            300px 240px rgb(60, 61, 70), 300px 244px rgb(60, 61, 70),
            300px 248px rgb(60, 61, 70), 300px 252px rgb(60, 61, 70),
            304px 136px rgb(82, 84, 102), 304px 140px rgb(82, 84, 102),
            304px 144px rgb(101, 104, 126), 304px 148px rgb(101, 104, 126),
            304px 152px rgb(101, 104, 126), 304px 156px rgb(101, 104, 126),
            304px 160px rgb(101, 104, 126), 304px 164px rgb(165, 120, 138),
            304px 168px rgb(165, 120, 138), 304px 172px rgb(78, 51, 68),
            304px 176px rgb(52, 34, 45), 304px 180px rgb(52, 34, 45),
            304px 184px rgb(165, 120, 138), 304px 188px rgb(52, 34, 45),
            304px 192px rgb(52, 34, 45), 304px 196px rgb(52, 34, 45),
            304px 200px rgb(165, 120, 138), 304px 204px rgb(101, 104, 126),
            304px 208px rgb(101, 104, 126), 304px 212px rgb(101, 104, 126),
            304px 216px rgb(101, 104, 126), 304px 220px rgb(82, 84, 102),
            304px 224px rgb(101, 104, 126), 304px 228px rgb(82, 84, 102),
            304px 232px rgb(82, 84, 102), 304px 236px rgb(82, 84, 102),
            304px 240px rgb(82, 84, 102), 304px 244px rgb(82, 84, 102),
            304px 248px rgb(82, 84, 102), 308px 124px rgb(82, 84, 102),
            308px 128px rgb(82, 84, 102), 308px 136px rgb(82, 84, 102),
            308px 140px rgb(82, 84, 102), 308px 144px rgb(82, 84, 102),
            308px 148px rgb(101, 104, 126), 308px 152px rgb(101, 104, 126),
            308px 156px rgb(101, 104, 126), 308px 160px rgb(101, 104, 126),
            308px 164px rgb(165, 120, 138), 308px 168px rgb(165, 120, 138),
            308px 172px rgb(250, 141, 124), 308px 176px rgb(78, 51, 68),
            308px 180px rgb(52, 34, 45), 308px 184px rgb(52, 34, 45),
            308px 188px rgb(165, 120, 138), 308px 192px rgb(52, 34, 45),
            308px 196px rgb(165, 120, 138), 308px 200px rgb(165, 120, 138),
            308px 204px rgb(165, 120, 138), 308px 208px rgb(101, 104, 126),
            308px 212px rgb(101, 104, 126), 308px 216px rgb(101, 104, 126),
            308px 220px rgb(101, 104, 126), 308px 224px rgb(101, 104, 126),
            308px 228px rgb(101, 104, 126), 308px 232px rgb(101, 104, 126),
            308px 236px rgb(101, 104, 126), 308px 240px rgb(82, 84, 102),
            308px 244px rgb(82, 84, 102), 312px 132px rgb(82, 84, 102),
            312px 136px rgb(82, 84, 102), 312px 140px rgb(82, 84, 102),
            312px 144px rgb(82, 84, 102), 312px 148px rgb(101, 104, 126),
            312px 152px rgb(101, 104, 126), 312px 156px rgb(101, 104, 126),
            312px 160px rgb(101, 104, 126), 312px 164px rgb(101, 104, 126),
            312px 168px rgb(165, 120, 138), 312px 172px rgb(165, 120, 138),
            312px 176px rgb(98, 59, 63), 312px 180px rgb(78, 51, 68),
            312px 184px rgb(52, 34, 45), 312px 188px rgb(52, 34, 45),
            312px 192px rgb(165, 120, 138), 312px 196px rgb(165, 120, 138),
            312px 200px rgb(165, 120, 138), 312px 204px rgb(165, 120, 138),
            312px 208px rgb(101, 104, 126), 312px 212px rgb(101, 104, 126),
            312px 216px rgb(101, 104, 126), 312px 220px rgb(101, 104, 126),
            312px 224px rgb(101, 104, 126), 312px 228px rgb(101, 104, 126),
            312px 232px rgb(82, 84, 102), 312px 236px rgb(82, 84, 102),
            312px 240px rgb(82, 84, 102), 316px 132px rgb(82, 84, 102),
            316px 136px rgb(82, 84, 102), 316px 140px rgb(82, 84, 102),
            316px 144px rgb(82, 84, 102), 316px 148px rgb(101, 104, 126),
            316px 152px rgb(101, 104, 126), 316px 156px rgb(101, 104, 126),
            316px 160px rgb(101, 104, 126), 316px 164px rgb(101, 104, 126),
            316px 168px rgb(165, 120, 138), 316px 172px rgb(165, 120, 138),
            316px 176px rgb(165, 120, 138), 316px 180px rgb(52, 34, 45),
            316px 184px rgb(52, 34, 45), 316px 188px rgb(165, 120, 138),
            316px 192px rgb(165, 120, 138), 316px 196px rgb(165, 120, 138),
            316px 200px rgb(165, 120, 138), 316px 204px rgb(165, 120, 138),
            316px 208px rgb(101, 104, 126), 316px 212px rgb(101, 104, 126),
            316px 216px rgb(101, 104, 126), 316px 220px rgb(101, 104, 126),
            316px 224px rgb(82, 84, 102), 316px 228px rgb(82, 84, 102),
            316px 232px rgb(82, 84, 102), 316px 236px rgb(82, 84, 102),
            316px 240px rgb(82, 84, 102), 316px 244px rgb(82, 84, 102),
            320px 128px rgb(82, 84, 102), 320px 132px rgb(82, 84, 102),
            320px 144px rgb(82, 84, 102), 320px 152px rgb(101, 104, 126),
            320px 156px rgb(101, 104, 126), 320px 160px rgb(101, 104, 126),
            320px 164px rgb(101, 104, 126), 320px 168px rgb(165, 120, 138),
            320px 172px rgb(165, 120, 138), 320px 176px rgb(165, 120, 138),
            320px 180px rgb(165, 120, 138), 320px 184px rgb(52, 34, 45),
            320px 188px rgb(165, 120, 138), 320px 192px rgb(165, 120, 138),
            320px 196px rgb(165, 120, 138), 320px 200px rgb(165, 120, 138),
            320px 204px rgb(101, 104, 126), 320px 208px rgb(101, 104, 126),
            320px 212px rgb(101, 104, 126), 320px 216px rgb(101, 104, 126),
            320px 220px rgb(101, 104, 126), 320px 224px rgb(118, 80, 95),
            320px 228px rgb(52, 34, 45), 320px 232px rgb(52, 34, 45),
            320px 236px rgb(82, 84, 102), 320px 244px rgb(82, 84, 102),
            324px 128px rgb(82, 84, 102), 324px 136px rgb(82, 84, 102),
            324px 140px rgb(82, 84, 102), 324px 144px rgb(82, 84, 102),
            324px 148px rgb(101, 104, 126), 324px 152px rgb(82, 84, 102),
            324px 156px rgb(101, 104, 126), 324px 160px rgb(101, 104, 126),
            324px 164px rgb(101, 104, 126), 324px 168px rgb(165, 120, 138),
            324px 172px rgb(165, 120, 138), 324px 176px rgb(165, 120, 138),
            324px 180px rgb(165, 120, 138), 324px 184px rgb(165, 120, 138),
            324px 188px rgb(165, 120, 138), 324px 192px rgb(165, 120, 138),
            324px 196px rgb(165, 120, 138), 324px 200px rgb(165, 120, 138),
            324px 204px rgb(101, 104, 126), 324px 208px rgb(101, 104, 126),
            324px 212px rgb(101, 104, 126), 324px 216px rgb(101, 104, 126),
            324px 220px rgb(118, 80, 95), 324px 224px rgb(76, 53, 68),
            324px 228px rgb(52, 34, 45), 324px 232px rgb(52, 34, 45),
            324px 236px rgb(60, 61, 70), 324px 240px rgb(82, 84, 102),
            324px 244px rgb(82, 84, 102), 328px 116px rgb(82, 84, 102),
            328px 136px rgb(82, 84, 102), 328px 140px rgb(82, 84, 101),
            328px 144px rgb(82, 84, 102), 328px 148px rgb(101, 104, 126),
            328px 152px rgb(101, 104, 126), 328px 156px rgb(101, 104, 126),
            328px 160px rgb(101, 104, 126), 328px 164px rgb(101, 104, 126),
            328px 168px rgb(101, 104, 126), 328px 172px rgb(101, 104, 126),
            328px 176px rgb(165, 120, 138), 328px 180px rgb(165, 120, 138),
            328px 184px rgb(165, 120, 138), 328px 188px rgb(165, 120, 138),
            328px 192px rgb(165, 120, 138), 328px 196px rgb(165, 120, 138),
            328px 200px rgb(165, 120, 138), 328px 204px rgb(101, 104, 126),
            328px 208px rgb(101, 104, 126), 328px 212px rgb(101, 104, 126),
            328px 216px rgb(101, 104, 126), 328px 220px rgb(118, 80, 95),
            328px 224px rgb(76, 53, 68), 328px 228px rgb(38, 29, 35),
            328px 232px rgb(38, 29, 35), 328px 236px rgb(60, 61, 70),
            328px 240px rgb(60, 61, 70), 328px 244px rgb(82, 84, 102),
            332px 132px rgb(82, 84, 102), 332px 136px rgb(82, 84, 101),
            332px 140px rgb(82, 84, 101), 332px 144px rgb(101, 104, 126),
            332px 148px rgb(82, 84, 102), 332px 152px rgb(82, 84, 102),
            332px 156px rgb(101, 104, 126), 332px 160px rgb(101, 104, 126),
            332px 164px rgb(101, 104, 126), 332px 168px rgb(101, 104, 126),
            332px 172px rgb(165, 120, 138), 332px 176px rgb(165, 120, 138),
            332px 180px rgb(165, 120, 138), 332px 184px rgb(165, 120, 138),
            332px 188px rgb(165, 120, 138), 332px 192px rgb(165, 120, 138),
            332px 196px rgb(101, 104, 126), 332px 200px rgb(101, 104, 126),
            332px 204px rgb(101, 104, 126), 332px 208px rgb(101, 104, 126),
            332px 212px rgb(101, 104, 126), 332px 216px rgb(101, 104, 126),
            332px 220px rgb(76, 53, 68), 332px 224px rgb(76, 53, 68),
            332px 228px rgb(38, 29, 35), 332px 232px rgb(38, 29, 35),
            332px 236px rgb(60, 61, 70), 332px 240px rgb(60, 61, 70),
            332px 244px rgb(60, 61, 70), 332px 248px rgb(60, 61, 70),
            336px 132px rgb(82, 84, 102), 336px 140px rgb(82, 84, 101),
            336px 144px rgb(82, 84, 102), 336px 148px rgb(82, 84, 102),
            336px 152px rgb(101, 104, 126), 336px 156px rgb(101, 104, 126),
            336px 160px rgb(101, 104, 126), 336px 164px rgb(101, 104, 126),
            336px 168px rgb(101, 104, 126), 336px 172px rgb(101, 104, 126),
            336px 176px rgb(165, 120, 138), 336px 180px rgb(165, 120, 138),
            336px 184px rgb(165, 120, 138), 336px 188px rgb(165, 120, 138),
            336px 192px rgb(165, 120, 138), 336px 196px rgb(165, 120, 138),
            336px 200px rgb(101, 104, 126), 336px 204px rgb(101, 104, 126),
            336px 208px rgb(101, 104, 126), 336px 212px rgb(101, 104, 126),
            336px 216px rgb(101, 104, 126), 336px 220px rgb(76, 53, 68),
            336px 224px rgb(76, 53, 68), 336px 228px rgb(38, 29, 35),
            336px 232px rgb(38, 29, 35), 336px 236px rgb(60, 61, 70),
            336px 240px rgb(60, 61, 70), 336px 244px rgb(60, 61, 70),
            336px 248px rgb(60, 61, 70), 336px 252px rgb(60, 61, 70),
            340px 120px rgb(82, 84, 102), 340px 136px rgb(82, 84, 101),
            340px 140px rgb(82, 84, 101), 340px 144px rgb(82, 84, 102),
            340px 148px rgb(101, 104, 126), 340px 152px rgb(82, 84, 102),
            340px 156px rgb(101, 104, 126), 340px 160px rgb(101, 104, 126),
            340px 164px rgb(101, 104, 126), 340px 168px rgb(101, 104, 126),
            340px 172px rgb(101, 104, 126), 340px 176px rgb(165, 120, 138),
            340px 180px rgb(165, 120, 138), 340px 184px rgb(101, 104, 126),
            340px 188px rgb(101, 104, 126), 340px 192px rgb(101, 104, 126),
            340px 196px rgb(101, 104, 126), 340px 200px rgb(101, 104, 126),
            340px 204px rgb(101, 104, 126), 340px 208px rgb(101, 104, 126),
            340px 212px rgb(101, 104, 126), 340px 216px rgb(101, 104, 126),
            340px 220px rgb(101, 104, 126), 340px 224px rgb(76, 53, 68),
            340px 228px rgb(52, 34, 45), 340px 232px rgb(52, 34, 45),
            340px 236px rgb(60, 61, 70), 340px 240px rgb(60, 61, 70),
            340px 244px rgb(60, 61, 70), 340px 248px rgb(60, 61, 70),
            340px 252px rgb(60, 61, 70), 344px 116px rgb(82, 84, 101),
            344px 132px rgb(82, 84, 102), 344px 144px rgb(82, 84, 102),
            344px 148px rgb(101, 104, 126), 344px 152px rgb(82, 84, 102),
            344px 156px rgb(101, 104, 126), 344px 160px rgb(101, 104, 126),
            344px 164px rgb(101, 104, 126), 344px 168px rgb(101, 104, 126),
            344px 172px rgb(101, 104, 126), 344px 176px rgb(101, 104, 126),
            344px 180px rgb(165, 120, 138), 344px 184px rgb(101, 104, 126),
            344px 188px rgb(165, 120, 138), 344px 192px rgb(101, 104, 126),
            344px 196px rgb(101, 104, 126), 344px 200px rgb(101, 104, 126),
            344px 204px rgb(101, 104, 126), 344px 208px rgb(101, 104, 126),
            344px 212px rgb(101, 104, 126), 344px 216px rgb(101, 104, 126),
            344px 220px rgb(101, 104, 126), 344px 224px rgb(101, 104, 126),
            344px 228px rgb(101, 104, 126), 344px 232px rgb(82, 84, 102),
            344px 236px rgb(82, 84, 102), 344px 240px rgb(60, 61, 70),
            344px 244px rgb(60, 61, 70), 344px 248px rgb(60, 61, 70),
            344px 252px rgb(60, 61, 70), 348px 140px rgb(82, 84, 102),
            348px 144px rgb(82, 84, 102), 348px 148px rgb(82, 84, 102),
            348px 152px rgb(101, 104, 126), 348px 156px rgb(101, 104, 126),
            348px 160px rgb(82, 84, 102), 348px 164px rgb(101, 104, 126),
            348px 168px rgb(101, 104, 126), 348px 172px rgb(101, 104, 126),
            348px 176px rgb(101, 104, 126), 348px 180px rgb(101, 104, 126),
            348px 184px rgb(101, 104, 126), 348px 188px rgb(101, 104, 126),
            348px 192px rgb(101, 104, 126), 348px 196px rgb(101, 104, 126),
            348px 200px rgb(101, 104, 126), 348px 204px rgb(101, 104, 126),
            348px 208px rgb(101, 104, 126), 348px 212px rgb(101, 104, 126),
            348px 216px rgb(101, 104, 126), 348px 220px rgb(101, 104, 126),
            348px 224px rgb(101, 104, 126), 348px 228px rgb(82, 84, 102),
            348px 232px rgb(101, 104, 126), 348px 244px rgb(60, 61, 70),
            348px 248px rgb(60, 61, 70), 348px 252px rgb(60, 61, 70),
            352px 136px rgb(82, 84, 102), 352px 140px rgb(82, 84, 102),
            352px 144px rgb(82, 84, 102), 352px 148px rgb(82, 84, 102),
            352px 152px rgb(82, 84, 102), 352px 156px rgb(82, 84, 102),
            352px 160px rgb(101, 104, 126), 352px 164px rgb(101, 104, 126),
            352px 168px rgb(101, 104, 126), 352px 172px rgb(101, 104, 126),
            352px 176px rgb(101, 104, 126), 352px 180px rgb(101, 104, 126),
            352px 184px rgb(101, 104, 126), 352px 188px rgb(101, 104, 126),
            352px 192px rgb(101, 104, 126), 352px 196px rgb(101, 104, 126),
            352px 200px rgb(101, 104, 126), 352px 204px rgb(101, 104, 126),
            352px 208px rgb(101, 104, 126), 352px 212px rgb(101, 104, 126),
            352px 216px rgb(101, 104, 126), 352px 220px rgb(82, 84, 102),
            352px 224px rgb(82, 84, 102), 352px 228px rgb(82, 84, 102),
            352px 232px rgb(82, 84, 102), 352px 236px rgb(82, 84, 102),
            352px 240px rgb(82, 84, 102), 352px 248px rgb(60, 61, 70),
            352px 252px rgb(60, 61, 70), 356px 136px rgb(82, 84, 102),
            356px 148px rgb(82, 84, 102), 356px 152px rgb(82, 84, 102),
            356px 156px rgb(101, 104, 126), 356px 160px rgb(101, 104, 126),
            356px 164px rgb(82, 84, 102), 356px 168px rgb(101, 104, 126),
            356px 172px rgb(101, 104, 126), 356px 176px rgb(101, 104, 126),
            356px 180px rgb(101, 104, 126), 356px 184px rgb(101, 104, 126),
            356px 188px rgb(101, 104, 126), 356px 192px rgb(101, 104, 126),
            356px 196px rgb(101, 104, 126), 356px 200px rgb(101, 104, 126),
            356px 204px rgb(101, 104, 126), 356px 208px rgb(101, 104, 126),
            356px 212px rgb(101, 104, 126), 356px 216px rgb(101, 104, 126),
            356px 220px rgb(101, 104, 126), 356px 224px rgb(101, 104, 126),
            356px 228px rgb(101, 104, 126), 356px 232px rgb(82, 84, 102),
            360px 140px rgb(82, 84, 101), 360px 148px rgb(82, 84, 102),
            360px 152px rgb(82, 84, 102), 360px 156px rgb(82, 84, 102),
            360px 160px rgb(82, 84, 102), 360px 164px rgb(101, 104, 126),
            360px 168px rgb(101, 104, 126), 360px 172px rgb(101, 104, 126),
            360px 176px rgb(101, 104, 126), 360px 180px rgb(101, 104, 126),
            360px 184px rgb(101, 104, 126), 360px 188px rgb(101, 104, 126),
            360px 192px rgb(101, 104, 126), 360px 204px rgb(101, 104, 126),
            360px 208px rgb(101, 104, 126), 360px 212px rgb(101, 104, 126),
            360px 216px rgb(101, 104, 126), 360px 220px rgb(82, 84, 102),
            360px 224px rgb(101, 104, 126), 360px 228px rgb(101, 104, 126),
            360px 240px rgb(82, 84, 102), 364px 144px rgb(82, 84, 102),
            364px 148px rgb(82, 84, 102), 364px 152px rgb(52, 34, 45),
            364px 156px rgb(52, 34, 45), 364px 160px rgb(52, 34, 45),
            364px 164px rgb(82, 84, 102), 364px 168px rgb(82, 84, 102),
            364px 172px rgb(101, 104, 126), 364px 176px rgb(101, 104, 126),
            364px 180px rgb(101, 104, 126), 364px 184px rgb(101, 104, 126),
            364px 188px rgb(101, 104, 126), 364px 192px rgb(101, 104, 126),
            364px 204px rgb(101, 104, 126), 364px 208px rgb(101, 104, 126),
            364px 212px rgb(82, 84, 102), 364px 216px rgb(101, 104, 126),
            364px 220px rgb(101, 104, 126), 364px 224px rgb(82, 84, 102),
            364px 232px rgb(82, 84, 102), 364px 236px rgb(82, 84, 102),
            368px 140px rgb(82, 84, 102), 368px 144px rgb(82, 84, 101),
            368px 148px rgb(52, 34, 45), 368px 152px rgb(78, 51, 68),
            368px 156px rgb(124, 72, 72), 368px 160px rgb(52, 34, 45),
            368px 164px rgb(52, 34, 45), 368px 168px rgb(101, 104, 126),
            368px 172px rgb(101, 104, 126), 368px 176px rgb(82, 84, 102),
            368px 180px rgb(101, 104, 126), 368px 184px rgb(101, 104, 126),
            368px 188px rgb(101, 104, 126), 368px 192px rgb(250, 141, 124),
            368px 200px rgb(250, 141, 124), 368px 204px rgb(101, 104, 126),
            368px 208px rgb(101, 104, 126), 368px 212px rgb(101, 104, 126),
            368px 216px rgb(82, 84, 102), 368px 220px rgb(82, 84, 102),
            368px 224px rgb(101, 104, 126), 368px 228px rgb(82, 84, 102),
            368px 232px rgb(82, 84, 102), 368px 236px rgb(82, 84, 102),
            372px 148px rgb(52, 34, 45), 372px 152px rgb(78, 51, 68),
            372px 156px rgb(78, 51, 68), 372px 160px rgb(78, 51, 68),
            372px 164px rgb(52, 34, 45), 372px 168px rgb(52, 34, 45),
            372px 172px rgb(101, 104, 126), 372px 176px rgb(101, 104, 126),
            372px 180px rgb(101, 104, 126), 372px 184px rgb(101, 104, 126),
            372px 188px rgb(101, 104, 126), 372px 200px rgb(82, 84, 102),
            372px 204px rgb(101, 104, 126), 372px 208px rgb(101, 104, 126),
            372px 212px rgb(101, 104, 126), 372px 216px rgb(82, 84, 102),
            372px 220px rgb(82, 84, 102), 372px 224px rgb(82, 84, 102),
            372px 228px rgb(82, 84, 102), 372px 232px rgb(82, 84, 102),
            376px 132px rgb(82, 84, 102), 376px 148px rgb(52, 34, 45),
            376px 152px rgb(52, 34, 45), 376px 156px rgb(124, 72, 72),
            376px 160px rgb(124, 72, 72), 376px 164px rgb(78, 51, 68),
            376px 168px rgb(52, 34, 45), 376px 172px rgb(82, 84, 102),
            376px 176px rgb(101, 104, 126), 376px 180px rgb(101, 104, 126),
            376px 184px rgb(101, 104, 126), 376px 188px rgb(250, 141, 124),
            376px 200px rgb(60, 61, 70), 376px 204px rgb(101, 104, 126),
            376px 208px rgb(101, 104, 126), 376px 212px rgb(101, 104, 126),
            376px 216px rgb(101, 104, 126), 376px 220px rgb(101, 104, 126),
            376px 224px rgb(82, 84, 102), 376px 228px rgb(82, 84, 102),
            376px 244px rgb(82, 84, 102), 380px 128px rgb(82, 84, 102),
            380px 144px rgb(82, 84, 101), 380px 148px rgb(82, 84, 101),
            380px 152px rgb(52, 34, 45), 380px 156px rgb(78, 51, 68),
            380px 160px rgb(124, 72, 72), 380px 164px rgb(124, 72, 72),
            380px 168px rgb(78, 51, 68), 380px 172px rgb(52, 34, 45),
            380px 176px rgb(101, 104, 126), 380px 180px rgb(101, 104, 126),
            380px 184px rgb(101, 104, 126), 380px 196px rgb(60, 61, 70),
            380px 200px rgb(60, 61, 70), 380px 204px rgb(101, 104, 126),
            380px 208px rgb(101, 104, 126), 380px 212px rgb(82, 84, 102),
            380px 216px rgb(82, 84, 102), 380px 220px rgb(82, 84, 102),
            380px 224px rgb(101, 104, 126), 384px 148px rgb(82, 84, 101),
            384px 152px rgb(52, 34, 45), 384px 156px rgb(52, 34, 45),
            384px 160px rgb(124, 72, 72), 384px 164px rgb(78, 51, 68),
            384px 168px rgb(78, 51, 68), 384px 172px rgb(52, 34, 45),
            384px 176px rgb(52, 34, 45), 384px 180px rgb(101, 104, 126),
            384px 184px rgb(250, 141, 124), 384px 196px rgb(60, 61, 70),
            384px 200px rgb(60, 61, 70), 384px 204px rgb(101, 104, 126),
            384px 208px rgb(82, 84, 102), 384px 212px rgb(101, 104, 126),
            384px 216px rgb(82, 84, 102), 384px 228px rgb(82, 84, 102),
            384px 240px rgb(82, 84, 102), 384px 244px rgb(82, 84, 102),
            388px 136px rgb(82, 84, 102), 388px 152px rgb(52, 34, 45),
            388px 156px rgb(52, 34, 45), 388px 160px rgb(78, 51, 68),
            388px 164px rgb(78, 51, 68), 388px 168px rgb(124, 72, 72),
            388px 172px rgb(78, 51, 68), 388px 176px rgb(52, 34, 45),
            388px 180px rgb(82, 84, 102), 388px 192px rgb(101, 104, 126),
            388px 196px rgb(60, 61, 70), 388px 200px rgb(60, 61, 70),
            388px 204px rgb(101, 104, 126), 388px 208px rgb(82, 84, 102),
            388px 212px rgb(82, 84, 102), 388px 216px rgb(101, 104, 126),
            388px 220px rgb(82, 84, 102), 388px 224px rgb(82, 84, 102),
            388px 228px rgb(82, 84, 102), 388px 236px rgb(82, 84, 102),
            392px 132px rgb(82, 84, 102), 392px 148px rgb(82, 84, 102),
            392px 156px rgb(52, 34, 45), 392px 160px rgb(52, 34, 45),
            392px 164px rgb(78, 51, 68), 392px 168px rgb(124, 72, 72),
            392px 172px rgb(78, 51, 68), 392px 176px rgb(52, 34, 45),
            392px 192px rgb(101, 104, 126), 392px 196px rgb(60, 61, 70),
            392px 200px rgb(60, 61, 70), 392px 204px rgb(82, 84, 102),
            392px 208px rgb(82, 84, 102), 392px 212px rgb(82, 84, 102),
            392px 216px rgb(82, 84, 102), 392px 224px rgb(82, 84, 102),
            396px 132px rgb(82, 84, 102), 396px 144px rgb(82, 84, 102),
            396px 148px rgb(82, 84, 102), 396px 156px rgb(52, 34, 45),
            396px 160px rgb(52, 34, 45), 396px 164px rgb(78, 51, 68),
            396px 168px rgb(124, 72, 72), 396px 172px rgb(124, 72, 72),
            396px 176px rgb(78, 51, 68), 396px 188px rgb(82, 84, 102),
            396px 192px rgb(82, 84, 102), 396px 196px rgb(60, 61, 70),
            396px 200px rgb(60, 61, 70), 396px 204px rgb(82, 84, 102),
            396px 208px rgb(101, 104, 126), 396px 212px rgb(82, 84, 102),
            400px 152px rgb(82, 84, 101), 400px 156px rgb(52, 34, 45),
            400px 160px rgb(52, 34, 45), 400px 164px rgb(78, 51, 68),
            400px 168px rgb(78, 51, 68), 400px 172px rgb(124, 72, 72),
            400px 188px rgb(82, 84, 102), 400px 192px rgb(101, 104, 126),
            400px 196px rgb(60, 61, 70), 400px 200px rgb(60, 61, 70),
            400px 204px rgb(82, 84, 102), 400px 208px rgb(82, 84, 102),
            400px 212px rgb(82, 84, 102), 400px 216px rgb(82, 84, 102),
            400px 220px rgb(82, 84, 102), 400px 224px rgb(82, 84, 102),
            404px 152px rgb(82, 84, 102), 404px 156px rgb(82, 84, 102),
            404px 160px rgb(52, 34, 45), 404px 164px rgb(52, 34, 45),
            404px 168px rgb(78, 51, 68), 404px 172px rgb(124, 72, 72),
            404px 184px rgb(52, 34, 45), 404px 188px rgb(82, 84, 102),
            404px 192px rgb(82, 84, 102), 404px 196px rgb(60, 61, 70),
            404px 200px rgb(60, 61, 70), 404px 204px rgb(82, 84, 102),
            404px 208px rgb(82, 84, 102), 404px 212px rgb(82, 84, 102),
            408px 148px rgb(82, 84, 102), 408px 156px rgb(82, 84, 101),
            408px 160px rgb(52, 34, 45), 408px 164px rgb(52, 34, 45),
            408px 168px rgb(52, 34, 45), 408px 176px rgb(118, 80, 95),
            408px 184px rgb(52, 34, 45), 408px 188px rgb(52, 34, 45),
            408px 192px rgb(82, 84, 102), 408px 196px rgb(60, 61, 70),
            408px 200px rgb(60, 61, 70), 408px 204px rgb(82, 84, 102),
            412px 148px rgb(82, 84, 102), 412px 156px rgb(98, 59, 63),
            412px 160px rgb(82, 84, 101), 412px 164px rgb(52, 34, 45),
            412px 168px rgb(52, 34, 45), 412px 176px rgb(118, 80, 95),
            412px 180px rgb(165, 120, 138), 412px 184px rgb(52, 34, 45),
            412px 188px rgb(52, 34, 45), 412px 192px rgb(82, 84, 102),
            412px 196px rgb(60, 61, 70), 412px 200px rgb(60, 61, 70),
            412px 208px rgb(82, 84, 102), 412px 212px rgb(82, 84, 102),
            412px 240px rgb(82, 84, 102), 416px 148px rgb(82, 84, 102),
            416px 156px rgb(98, 59, 63), 416px 160px rgb(98, 59, 63),
            416px 164px rgb(52, 34, 45), 416px 172px rgb(118, 80, 95),
            416px 180px rgb(52, 34, 45), 416px 184px rgb(78, 51, 68),
            416px 188px rgb(52, 34, 45), 416px 192px rgb(52, 34, 45),
            416px 196px rgb(60, 61, 70), 416px 200px rgb(60, 61, 70),
            416px 204px rgb(82, 84, 102), 416px 228px rgb(82, 84, 102),
            420px 156px rgb(52, 34, 45), 420px 160px rgb(98, 59, 63),
            420px 164px rgb(52, 34, 45), 420px 168px rgb(118, 80, 95),
            420px 172px rgb(118, 80, 95), 420px 180px rgb(52, 34, 45),
            420px 184px rgb(78, 51, 68), 420px 188px rgb(78, 51, 68),
            420px 192px rgb(52, 34, 45), 420px 196px rgb(52, 34, 45),
            420px 200px rgb(60, 61, 70), 420px 228px rgb(82, 84, 102),
            424px 156px rgb(52, 34, 45), 424px 160px rgb(52, 34, 45),
            424px 168px rgb(78, 51, 68), 424px 176px rgb(52, 34, 45),
            424px 180px rgb(52, 34, 45), 424px 184px rgb(52, 34, 45),
            424px 188px rgb(52, 34, 45), 424px 192px rgb(52, 34, 45),
            424px 196px rgb(52, 34, 45), 424px 200px rgb(60, 61, 70),
            424px 232px rgb(82, 84, 102), 428px 156px rgb(82, 84, 102),
            428px 160px rgb(52, 34, 45), 428px 172px rgb(118, 80, 95),
            428px 176px rgb(52, 34, 45), 428px 180px rgb(52, 34, 45),
            428px 184px rgb(38, 29, 35), 428px 188px rgb(38, 29, 35),
            428px 192px rgb(38, 29, 35), 428px 196px rgb(52, 34, 45),
            428px 200px rgb(60, 61, 70), 428px 220px rgb(82, 84, 102),
            428px 224px rgb(82, 84, 102), 432px 160px rgb(52, 34, 45),
            432px 164px rgb(76, 53, 68), 432px 168px rgb(76, 53, 68),
            432px 172px rgb(52, 34, 45), 432px 176px rgb(98, 59, 63),
            432px 180px rgb(38, 29, 35), 432px 184px rgb(38, 29, 35),
            432px 188px rgb(38, 29, 35), 432px 192px rgb(38, 29, 35),
            432px 196px rgb(38, 29, 35), 432px 200px rgb(52, 34, 45),
            432px 216px rgb(82, 84, 102), 436px 156px rgb(78, 51, 68),
            436px 160px rgb(78, 51, 68), 436px 164px rgb(52, 34, 45),
            436px 168px rgb(52, 34, 45), 436px 172px rgb(52, 34, 45),
            436px 176px rgb(52, 34, 45), 436px 180px rgb(98, 59, 63),
            436px 184px rgb(38, 29, 35), 436px 188px rgb(52, 34, 45),
            436px 192px rgb(38, 29, 35), 436px 196px rgb(52, 34, 45),
            436px 200px rgb(38, 29, 35), 440px 156px rgb(78, 51, 68),
            440px 160px rgb(52, 34, 45), 440px 164px rgb(52, 34, 45),
            440px 168px rgb(38, 29, 35), 440px 172px rgb(38, 29, 35),
            440px 176px rgb(52, 34, 45), 440px 180px rgb(52, 34, 45),
            440px 184px rgb(38, 29, 35), 440px 188px rgb(38, 29, 35),
            440px 192px rgb(38, 29, 35), 440px 196px rgb(52, 34, 45),
            440px 200px rgb(38, 29, 35), 444px 152px rgb(78, 51, 68),
            444px 156px rgb(78, 51, 68), 444px 160px rgb(52, 34, 45),
            444px 164px rgb(38, 29, 35), 444px 172px rgb(38, 29, 35),
            444px 176px rgb(38, 29, 35), 444px 180px rgb(52, 34, 45),
            444px 184px rgb(52, 34, 45), 444px 188px rgb(52, 34, 45),
            444px 192px rgb(52, 34, 45), 444px 196px rgb(52, 34, 45),
            444px 200px rgb(38, 29, 35), 448px 152px rgb(52, 34, 45),
            448px 156px rgb(52, 34, 45), 448px 160px rgb(52, 34, 45),
            448px 164px rgb(38, 29, 35), 448px 172px rgb(60, 61, 70),
            448px 176px rgb(38, 29, 35), 448px 180px rgb(38, 29, 35),
            448px 184px rgb(52, 34, 45), 448px 188px rgb(52, 34, 45),
            448px 192px rgb(52, 34, 45), 448px 196px rgb(38, 29, 35),
            448px 200px rgb(60, 61, 70), 448px 220px rgb(82, 84, 102),
            448px 224px rgb(82, 84, 102), 452px 152px rgb(38, 29, 35),
            452px 156px rgb(52, 34, 45), 452px 160px rgb(38, 29, 35),
            452px 176px rgb(60, 61, 70), 452px 180px rgb(38, 29, 35),
            452px 184px rgb(38, 29, 35), 452px 188px rgb(38, 29, 35),
            452px 192px rgb(38, 29, 35), 452px 196px rgb(60, 61, 70),
            452px 200px rgb(60, 61, 70), 456px 156px rgb(38, 29, 35),
            456px 160px rgb(38, 29, 35), 456px 176px rgb(60, 61, 70),
            456px 180px rgb(60, 61, 70), 456px 184px rgb(60, 61, 70),
            456px 188px rgb(60, 61, 70), 456px 192px rgb(60, 61, 70),
            456px 196px rgb(60, 61, 70), 456px 200px rgb(60, 61, 70),
            456px 212px rgb(82, 84, 102), 456px 216px rgb(82, 84, 102),
            456px 224px rgb(82, 84, 102), 460px 180px rgb(60, 61, 70),
            460px 184px rgb(60, 61, 70), 460px 188px rgb(60, 61, 70),
            460px 192px rgb(60, 61, 70), 460px 196px rgb(60, 61, 70),
            460px 200px rgb(60, 61, 70), 460px 212px rgb(82, 84, 102),
            464px 180px rgb(60, 61, 70), 464px 184px rgb(60, 61, 70),
            464px 188px rgb(60, 61, 70), 464px 192px rgb(60, 61, 70),
            464px 196px rgb(60, 61, 70), 464px 200px rgb(60, 61, 70),
            464px 208px rgb(82, 84, 102), 468px 184px rgb(60, 61, 70),
            468px 188px rgb(60, 61, 70), 468px 192px rgb(60, 61, 70),
            468px 196px rgb(60, 61, 70), 468px 200px rgb(60, 61, 70),
            472px 184px rgb(60, 61, 70), 472px 188px rgb(60, 61, 70),
            472px 192px rgb(60, 61, 70), 472px 196px rgb(60, 61, 70),
            472px 200px rgb(60, 61, 70), 476px 184px rgb(60, 61, 70),
            476px 188px rgb(60, 61, 70), 476px 192px rgb(60, 61, 70),
            476px 196px rgb(60, 61, 70), 476px 200px rgb(60, 61, 70),
            480px 188px rgb(60, 61, 70), 480px 192px rgb(60, 61, 70),
            480px 196px rgb(60, 61, 70), 480px 200px rgb(60, 61, 70),
            484px 188px rgb(60, 61, 70), 484px 192px rgb(60, 61, 70),
            484px 196px rgb(60, 61, 70), 484px 200px rgb(60, 61, 70),
            488px 192px rgb(60, 61, 70), 488px 196px rgb(60, 61, 70),
            488px 200px rgb(60, 61, 70), 492px 192px rgb(60, 61, 70),
            492px 196px rgb(60, 61, 70), 496px 196px rgb(60, 61, 70);
    }
}

@keyframes animate {
    0% {
        box-shadow: 128px 204px rgb(118, 80, 95), 132px 196px rgb(118, 80, 95),
            132px 204px rgb(165, 120, 138), 136px 196px rgb(165, 120, 138),
            136px 204px rgb(250, 141, 124), 140px 196px rgb(250, 141, 124),
            140px 200px rgb(165, 120, 138), 140px 204px rgb(250, 141, 124),
            140px 208px rgb(118, 80, 95), 140px 220px rgb(118, 80, 95),
            140px 224px rgb(118, 80, 95), 140px 228px rgb(118, 80, 95),
            140px 232px rgb(118, 80, 95), 144px 136px rgb(165, 120, 138),
            144px 140px rgb(165, 120, 138), 144px 200px rgb(250, 141, 124),
            144px 204px rgb(165, 120, 138), 144px 208px rgb(165, 120, 138),
            144px 216px rgb(118, 80, 95), 144px 220px rgb(165, 120, 138),
            144px 224px rgb(165, 120, 138), 144px 228px rgb(165, 120, 138),
            148px 132px rgb(165, 120, 138), 148px 136px rgb(165, 120, 138),
            148px 140px rgb(165, 120, 138), 148px 144px rgb(165, 120, 138),
            152px 132px rgb(165, 120, 138), 152px 136px rgb(250, 141, 124),
            152px 140px rgb(250, 141, 124), 152px 144px rgb(165, 120, 138),
            152px 176px rgb(165, 120, 138), 156px 136px rgb(165, 120, 138),
            156px 140px rgb(165, 120, 138), 156px 176px rgb(165, 120, 138),
            156px 180px rgb(165, 120, 138), 156px 196px rgb(165, 120, 138),
            156px 200px rgb(165, 120, 138), 160px 176px rgb(165, 120, 138),
            160px 180px rgb(165, 120, 138), 160px 196px rgb(165, 120, 138),
            160px 200px rgb(165, 120, 138), 164px 200px rgb(250, 141, 124),
            176px 128px rgb(250, 141, 124), 176px 136px rgb(118, 80, 95),
            176px 140px rgb(165, 120, 138), 176px 144px rgb(165, 120, 138),
            180px 124px rgb(118, 80, 95), 180px 132px rgb(165, 120, 138),
            180px 136px rgb(165, 120, 138), 180px 144px rgb(250, 141, 124),
            184px 120px rgb(118, 80, 95), 184px 124px rgb(250, 141, 124),
            184px 128px rgb(165, 120, 138), 184px 132px rgb(250, 141, 124),
            184px 136px rgb(250, 141, 124), 184px 172px rgb(165, 120, 138),
            184px 180px rgb(165, 120, 138), 188px 120px rgb(165, 120, 138),
            188px 124px rgb(250, 141, 124), 188px 168px rgb(165, 120, 138),
            188px 172px rgb(165, 120, 138), 188px 176px rgb(165, 120, 138),
            188px 180px rgb(250, 141, 124), 192px 136px rgb(118, 80, 95),
            192px 172px rgb(250, 141, 124), 192px 176px rgb(250, 141, 124),
            192px 180px rgb(165, 120, 138), 204px 152px rgb(165, 120, 138),
            208px 148px rgb(250, 141, 124), 208px 152px rgb(165, 120, 138),
            212px 144px rgb(165, 120, 138), 212px 148px rgb(165, 120, 138),
            216px 144px rgb(250, 141, 124), 220px 144px rgb(250, 141, 124),
            220px 184px rgb(101, 104, 126), 224px 176px rgb(101, 104, 126),
            224px 180px rgb(101, 104, 126), 224px 184px rgb(165, 120, 138),
            224px 188px rgb(101, 104, 126), 228px 180px rgb(165, 120, 138),
            228px 184px rgb(165, 120, 138), 228px 188px rgb(165, 120, 138),
            228px 192px rgb(165, 120, 138), 232px 172px rgb(101, 104, 126),
            232px 176px rgb(165, 120, 138), 232px 180px rgb(165, 120, 138),
            232px 184px rgb(165, 120, 138), 232px 188px rgb(165, 120, 138),
            236px 172px rgb(101, 104, 126), 236px 176px rgb(165, 120, 138),
            236px 180px rgb(165, 120, 138), 236px 192px rgb(165, 120, 138),
            236px 196px rgb(165, 120, 138), 240px 172px rgb(165, 120, 138),
            240px 176px rgb(165, 120, 138), 240px 192px rgb(165, 120, 138),
            240px 196px rgb(101, 104, 126), 240px 200px rgb(101, 104, 126),
            244px 168px rgb(101, 104, 126), 244px 172px rgb(165, 120, 138),
            244px 192px rgb(165, 120, 138), 244px 196px rgb(165, 120, 138),
            248px 168px rgb(101, 104, 126), 248px 172px rgb(165, 120, 138),
            248px 196px rgb(165, 120, 138), 248px 200px rgb(165, 120, 138),
            252px 168px rgb(165, 120, 138), 252px 196px rgb(165, 120, 138),
            252px 200px rgb(165, 120, 138), 256px 104px rgb(189, 65, 105),
            256px 108px rgb(189, 65, 105), 256px 168px rgb(165, 120, 138),
            256px 200px rgb(165, 120, 138), 256px 204px rgb(101, 104, 126),
            260px 100px rgb(189, 65, 105), 260px 164px rgb(101, 104, 126),
            260px 168px rgb(165, 120, 138), 260px 200px rgb(165, 120, 138),
            260px 204px rgb(165, 120, 138), 264px 112px rgb(189, 65, 105),
            264px 116px rgb(189, 65, 105), 264px 120px rgb(189, 65, 105),
            264px 164px rgb(101, 104, 126), 264px 200px rgb(165, 120, 138),
            268px 108px rgb(189, 65, 105), 268px 112px rgb(189, 65, 105),
            268px 116px rgb(189, 65, 105), 268px 120px rgb(189, 65, 105),
            268px 124px rgb(189, 65, 105), 268px 128px rgb(189, 65, 105),
            268px 140px rgb(189, 65, 105), 268px 144px rgb(189, 65, 105),
            268px 148px rgb(189, 65, 105), 268px 152px rgb(189, 65, 105),
            268px 156px rgb(189, 65, 105), 268px 200px rgb(165, 120, 138),
            268px 204px rgb(165, 120, 138), 268px 208px rgb(101, 104, 126),
            272px 104px rgb(189, 65, 105), 272px 108px rgb(189, 65, 105),
            272px 112px rgb(189, 65, 105), 272px 116px rgb(189, 65, 105),
            272px 128px rgb(189, 65, 105), 272px 132px rgb(189, 65, 105),
            272px 136px rgb(189, 65, 105), 272px 140px rgb(189, 65, 105),
            272px 144px rgb(189, 65, 105), 272px 148px rgb(250, 141, 124),
            272px 152px rgb(250, 141, 124), 272px 156px rgb(189, 65, 105),
            272px 160px rgb(189, 65, 105), 272px 164px rgb(101, 104, 126),
            272px 168px rgb(165, 120, 138), 272px 200px rgb(165, 120, 138),
            272px 204px rgb(165, 120, 138), 276px 52px rgb(189, 65, 105),
            276px 56px rgb(189, 65, 105), 276px 60px rgb(189, 65, 105),
            276px 64px rgb(189, 65, 105), 276px 68px rgb(189, 65, 105),
            276px 72px rgb(189, 65, 105), 276px 100px rgb(189, 65, 105),
            276px 104px rgb(189, 65, 105), 276px 108px rgb(189, 65, 105),
            276px 124px rgb(189, 65, 105), 276px 128px rgb(189, 65, 105),
            276px 132px rgb(189, 65, 105), 276px 136px rgb(250, 141, 124),
            276px 140px rgb(189, 65, 105), 276px 144px rgb(189, 65, 105),
            276px 148px rgb(250, 141, 124), 276px 152px rgb(250, 141, 124),
            276px 156px rgb(250, 141, 124), 276px 160px rgb(189, 65, 105),
            276px 164px rgb(189, 65, 105), 276px 168px rgb(98, 59, 63),
            276px 200px rgb(165, 120, 138), 276px 204px rgb(165, 120, 138),
            276px 208px rgb(101, 104, 126), 280px 48px rgb(189, 65, 105),
            280px 52px rgb(250, 141, 124), 280px 56px rgb(250, 141, 124),
            280px 60px rgb(250, 141, 124), 280px 64px rgb(189, 65, 105),
            280px 120px rgb(189, 65, 105), 280px 124px rgb(189, 65, 105),
            280px 128px rgb(250, 141, 124), 280px 132px rgb(250, 141, 124),
            280px 136px rgb(189, 65, 105), 280px 140px rgb(189, 65, 105),
            280px 144px rgb(250, 141, 124), 280px 148px rgb(250, 141, 124),
            280px 152px rgb(250, 141, 124), 280px 156px rgb(250, 141, 124),
            280px 160px rgb(189, 65, 105), 280px 164px rgb(189, 65, 105),
            280px 200px rgb(165, 120, 138), 280px 204px rgb(165, 120, 138),
            280px 208px rgb(101, 104, 126), 284px 44px rgb(189, 65, 105),
            284px 48px rgb(250, 141, 124), 284px 52px rgb(250, 141, 124),
            284px 56px rgb(250, 141, 124), 284px 60px rgb(189, 65, 105),
            284px 64px rgb(189, 65, 105), 284px 68px rgb(189, 65, 105),
            284px 112px rgb(189, 65, 105), 284px 116px rgb(189, 65, 105),
            284px 120px rgb(189, 65, 105), 284px 124px rgb(189, 65, 105),
            284px 128px rgb(250, 141, 124), 284px 132px rgb(250, 141, 124),
            284px 136px rgb(250, 141, 124), 284px 140px rgb(250, 141, 124),
            284px 144px rgb(250, 141, 124), 284px 148px rgb(250, 141, 124),
            284px 152px rgb(250, 141, 124), 284px 160px rgb(189, 65, 105),
            284px 164px rgb(189, 65, 105), 284px 200px rgb(165, 120, 138),
            284px 204px rgb(165, 120, 138), 284px 208px rgb(101, 104, 126),
            288px 44px rgb(189, 65, 105), 288px 48px rgb(189, 65, 105),
            288px 52px rgb(250, 141, 124), 288px 56px rgb(250, 141, 124),
            288px 60px rgb(250, 141, 124), 288px 64px rgb(189, 65, 105),
            288px 68px rgb(189, 65, 105), 288px 72px rgb(189, 65, 105),
            288px 100px rgb(189, 65, 105), 288px 104px rgb(189, 65, 105),
            288px 108px rgb(189, 65, 105), 288px 112px rgb(189, 65, 105),
            288px 116px rgb(189, 65, 105), 288px 120px rgb(189, 65, 105),
            288px 124px rgb(250, 141, 124), 288px 128px rgb(250, 141, 124),
            288px 132px rgb(250, 141, 124), 288px 136px rgb(250, 141, 124),
            288px 140px rgb(250, 141, 124), 288px 144px rgb(250, 141, 124),
            288px 148px rgb(250, 141, 124), 288px 152px rgb(189, 65, 105),
            288px 204px rgb(165, 120, 138), 288px 208px rgb(101, 104, 126),
            292px 52px rgb(189, 65, 105), 292px 56px rgb(189, 65, 105),
            292px 60px rgb(189, 65, 105), 292px 64px rgb(189, 65, 105),
            292px 68px rgb(189, 65, 105), 292px 72px rgb(189, 65, 105),
            292px 76px rgb(189, 65, 105), 292px 80px rgb(189, 65, 105),
            292px 84px rgb(189, 65, 105), 292px 88px rgb(189, 65, 105),
            292px 92px rgb(189, 65, 105), 292px 96px rgb(189, 65, 105),
            292px 100px rgb(189, 65, 105), 292px 104px rgb(189, 65, 105),
            292px 108px rgb(189, 65, 105), 292px 112px rgb(189, 65, 105),
            292px 116px rgb(189, 65, 105), 292px 120px rgb(250, 141, 124),
            292px 124px rgb(250, 141, 124), 292px 128px rgb(189, 65, 105),
            292px 132px rgb(189, 65, 105), 292px 136px rgb(250, 141, 124),
            292px 140px rgb(250, 141, 124), 292px 144px rgb(250, 141, 124),
            292px 148px rgb(250, 141, 124), 292px 152px rgb(250, 141, 124),
            292px 156px rgb(189, 65, 105), 292px 204px rgb(165, 120, 138),
            296px 64px rgb(189, 65, 105), 296px 68px rgb(189, 65, 105),
            296px 72px rgb(189, 65, 105), 296px 76px rgb(189, 65, 105),
            296px 80px rgb(189, 65, 105), 296px 84px rgb(189, 65, 105),
            296px 88px rgb(189, 65, 105), 296px 92px rgb(189, 65, 105),
            296px 96px rgb(189, 65, 105), 296px 100px rgb(189, 65, 105),
            296px 104px rgb(189, 65, 105), 296px 108px rgb(189, 65, 105),
            296px 112px rgb(250, 141, 124), 296px 116px rgb(250, 141, 124),
            296px 120px rgb(189, 65, 105), 296px 124px rgb(189, 65, 105),
            296px 128px rgb(189, 65, 105), 296px 132px rgb(189, 65, 105),
            296px 136px rgb(189, 65, 105), 296px 140px rgb(250, 141, 124),
            296px 144px rgb(250, 141, 124), 296px 148px rgb(189, 65, 105),
            296px 152px rgb(189, 65, 105), 296px 156px rgb(189, 65, 105),
            296px 160px rgb(189, 65, 105), 296px 164px rgb(189, 65, 105),
            296px 204px rgb(165, 120, 138), 296px 208px rgb(101, 104, 126),
            300px 72px rgb(189, 65, 105), 300px 76px rgb(189, 65, 105),
            300px 80px rgb(189, 65, 105), 300px 84px rgb(189, 65, 105),
            300px 88px rgb(250, 141, 124), 300px 92px rgb(250, 141, 124),
            300px 96px rgb(250, 141, 124), 300px 100px rgb(250, 141, 124),
            300px 104px rgb(250, 141, 124), 300px 108px rgb(250, 141, 124),
            300px 112px rgb(250, 141, 124), 300px 116px rgb(189, 65, 105),
            300px 120px rgb(189, 65, 105), 300px 132px rgb(189, 65, 105),
            300px 136px rgb(189, 65, 105), 300px 140px rgb(189, 65, 105),
            300px 144px rgb(189, 65, 105), 300px 148px rgb(189, 65, 105),
            300px 152px rgb(189, 65, 105), 300px 156px rgb(189, 65, 105),
            300px 160px rgb(189, 65, 105), 300px 164px rgb(189, 65, 105),
            300px 204px rgb(165, 120, 138), 300px 208px rgb(101, 104, 126),
            304px 80px rgb(189, 65, 105), 304px 84px rgb(189, 65, 105),
            304px 88px rgb(189, 65, 105), 304px 92px rgb(189, 65, 105),
            304px 96px rgb(250, 141, 124), 304px 100px rgb(250, 141, 124),
            304px 104px rgb(250, 141, 124), 304px 108px rgb(250, 141, 124),
            304px 112px rgb(189, 65, 105), 304px 116px rgb(189, 65, 105),
            304px 128px rgb(189, 65, 105), 304px 132px rgb(189, 65, 105),
            304px 136px rgb(189, 65, 105), 304px 140px rgb(189, 65, 105),
            304px 144px rgb(189, 65, 105), 304px 148px rgb(189, 65, 105),
            304px 152px rgb(189, 65, 105), 304px 156px rgb(189, 65, 105),
            304px 164px rgb(101, 104, 126), 304px 200px rgb(165, 120, 138),
            304px 204px rgb(165, 120, 138), 308px 104px rgb(189, 65, 105),
            308px 108px rgb(189, 65, 105), 308px 112px rgb(189, 65, 105),
            308px 124px rgb(189, 65, 105), 308px 128px rgb(189, 65, 105),
            308px 132px rgb(189, 65, 105), 308px 136px rgb(189, 65, 105),
            308px 140px rgb(189, 65, 105), 308px 144px rgb(189, 65, 105),
            308px 148px rgb(189, 65, 105), 308px 152px rgb(189, 65, 105),
            308px 164px rgb(101, 104, 126), 308px 200px rgb(165, 120, 138),
            308px 204px rgb(101, 104, 126), 312px 132px rgb(189, 65, 105),
            312px 136px rgb(189, 65, 105), 312px 140px rgb(189, 65, 105),
            312px 144px rgb(189, 65, 105), 312px 200px rgb(165, 120, 138),
            312px 204px rgb(101, 104, 126), 316px 168px rgb(101, 104, 126),
            316px 196px rgb(165, 120, 138), 316px 200px rgb(165, 120, 138),
            316px 204px rgb(101, 104, 126), 320px 168px rgb(101, 104, 126),
            320px 196px rgb(165, 120, 138), 320px 200px rgb(165, 120, 138),
            324px 168px rgb(101, 104, 126), 324px 192px rgb(165, 120, 138),
            324px 196px rgb(165, 120, 138), 324px 200px rgb(101, 104, 126),
            328px 176px rgb(165, 120, 138), 328px 188px rgb(165, 120, 138),
            328px 192px rgb(101, 104, 126), 328px 196px rgb(101, 104, 126),
            328px 200px rgb(101, 104, 126), 332px 92px rgb(189, 65, 105),
            332px 96px rgb(189, 65, 105), 332px 172px rgb(101, 104, 126),
            332px 176px rgb(165, 120, 138), 332px 180px rgb(165, 120, 138),
            332px 184px rgb(165, 120, 138), 332px 188px rgb(165, 120, 138),
            332px 192px rgb(165, 120, 138), 336px 176px rgb(101, 104, 126),
            336px 180px rgb(165, 120, 138), 336px 184px rgb(101, 104, 126),
            336px 188px rgb(101, 104, 126), 336px 192px rgb(101, 104, 126),
            336px 196px rgb(165, 120, 138), 340px 176px rgb(101, 104, 126),
            340px 180px rgb(101, 104, 126), 340px 184px rgb(165, 120, 138),
            340px 192px rgb(165, 120, 138), 344px 180px rgb(101, 104, 126),
            344px 188px rgb(101, 104, 126), 360px 196px rgb(250, 141, 124),
            360px 200px rgb(250, 141, 124), 364px 196px rgb(250, 141, 124),
            364px 200px rgb(250, 141, 124), 368px 192px rgb(250, 141, 124),
            368px 196px rgb(165, 120, 138), 368px 200px rgb(250, 141, 124),
            372px 192px rgb(250, 141, 124), 372px 196px rgb(165, 120, 138),
            376px 188px rgb(250, 141, 124), 376px 192px rgb(165, 120, 138),
            376px 196px rgb(250, 141, 124), 380px 188px rgb(250, 141, 124),
            380px 192px rgb(165, 120, 138), 384px 184px rgb(250, 141, 124),
            384px 188px rgb(165, 120, 138), 384px 192px rgb(165, 120, 138),
            388px 184px rgb(250, 141, 124), 388px 188px rgb(165, 120, 138),
            392px 180px rgb(250, 141, 124), 392px 184px rgb(165, 120, 138),
            392px 188px rgb(165, 120, 138), 396px 180px rgb(250, 141, 124),
            396px 184px rgb(165, 120, 138), 400px 176px rgb(165, 120, 138),
            400px 180px rgb(165, 120, 138), 400px 184px rgb(165, 120, 138),
            404px 176px rgb(165, 120, 138), 404px 180px rgb(165, 120, 138),
            408px 172px rgb(165, 120, 138), 408px 180px rgb(165, 120, 138),
            412px 172px rgb(165, 120, 138), 412px 180px rgb(165, 120, 138),
            416px 168px rgb(165, 120, 138), 416px 176px rgb(165, 120, 138),
            420px 176px rgb(165, 120, 138), 424px 164px rgb(189, 65, 105),
            424px 172px rgb(165, 120, 138), 428px 164px rgb(250, 141, 124),
            428px 168px rgb(189, 65, 105);
    }

    16.666666666666668% {
        box-shadow: 128px 204px rgb(165, 120, 138),
            132px 196px rgb(165, 120, 138), 132px 204px rgb(165, 120, 138),
            136px 196px rgb(250, 141, 124), 136px 204px rgb(250, 141, 124),
            140px 196px rgb(250, 141, 124), 140px 200px rgb(165, 120, 138),
            140px 204px rgb(250, 141, 124), 140px 208px rgb(165, 120, 138),
            140px 220px rgb(165, 120, 138), 140px 224px rgb(165, 120, 138),
            140px 228px rgb(165, 120, 138), 140px 232px rgb(165, 120, 138),
            144px 136px rgb(165, 120, 138), 144px 140px rgb(165, 120, 138),
            144px 200px rgb(250, 141, 124), 144px 204px rgb(165, 120, 138),
            144px 208px rgb(165, 120, 138), 144px 216px rgb(165, 120, 138),
            144px 220px rgb(165, 120, 138), 144px 224px rgb(165, 120, 138),
            144px 228px rgb(165, 120, 138), 148px 132px rgb(165, 120, 138),
            148px 136px rgb(250, 141, 124), 148px 140px rgb(165, 120, 138),
            148px 144px rgb(165, 120, 138), 152px 132px rgb(165, 120, 138),
            152px 136px rgb(250, 141, 124), 152px 140px rgb(250, 141, 124),
            152px 144px rgb(165, 120, 138), 152px 176px rgb(165, 120, 138),
            156px 136px rgb(165, 120, 138), 156px 140px rgb(165, 120, 138),
            156px 176px rgb(165, 120, 138), 156px 180px rgb(165, 120, 138),
            156px 196px rgb(165, 120, 138), 156px 200px rgb(165, 120, 138),
            160px 176px rgb(165, 120, 138), 160px 180px rgb(165, 120, 138),
            160px 196px rgb(165, 120, 138), 160px 200px rgb(165, 120, 138),
            164px 200px rgb(250, 141, 124), 176px 128px rgb(250, 141, 124),
            176px 136px rgb(165, 120, 138), 176px 140px rgb(165, 120, 138),
            176px 144px rgb(250, 141, 124), 180px 124px rgb(165, 120, 138),
            180px 132px rgb(165, 120, 138), 180px 136px rgb(250, 141, 124),
            180px 144px rgb(250, 141, 124), 184px 120px rgb(165, 120, 138),
            184px 124px rgb(250, 141, 124), 184px 128px rgb(165, 120, 138),
            184px 132px rgb(250, 141, 124), 184px 136px rgb(250, 141, 124),
            184px 172px rgb(165, 120, 138), 184px 180px rgb(165, 120, 138),
            188px 120px rgb(165, 120, 138), 188px 124px rgb(250, 141, 124),
            188px 168px rgb(165, 120, 138), 188px 172px rgb(250, 141, 124),
            188px 176px rgb(165, 120, 138), 188px 180px rgb(250, 141, 124),
            192px 136px rgb(165, 120, 138), 192px 172px rgb(250, 141, 124),
            192px 176px rgb(250, 141, 124), 192px 180px rgb(165, 120, 138),
            204px 152px rgb(165, 120, 138), 208px 148px rgb(250, 141, 124),
            208px 152px rgb(165, 120, 138), 212px 144px rgb(250, 141, 124),
            212px 148px rgb(165, 120, 138), 216px 144px rgb(250, 141, 124),
            220px 144px rgb(250, 141, 124), 264px 80px rgb(189, 65, 105),
            264px 104px rgb(189, 65, 105), 264px 108px rgb(189, 65, 105),
            264px 112px rgb(189, 65, 105), 264px 128px rgb(189, 65, 105),
            264px 132px rgb(189, 65, 105), 264px 136px rgb(189, 65, 105),
            264px 164px rgb(101, 104, 126), 268px 84px rgb(189, 65, 105),
            268px 88px rgb(189, 65, 105), 268px 92px rgb(189, 65, 105),
            268px 96px rgb(189, 65, 105), 268px 100px rgb(189, 65, 105),
            268px 104px rgb(189, 65, 105), 268px 120px rgb(189, 65, 105),
            268px 124px rgb(189, 65, 105), 268px 128px rgb(189, 65, 105),
            268px 132px rgb(189, 65, 105), 268px 136px rgb(189, 65, 105),
            268px 140px rgb(189, 65, 105), 268px 144px rgb(189, 65, 105),
            268px 164px rgb(189, 65, 105), 268px 208px rgb(165, 120, 138),
            272px 72px rgb(189, 65, 105), 272px 88px rgb(189, 65, 105),
            272px 92px rgb(189, 65, 105), 272px 96px rgb(189, 65, 105),
            272px 112px rgb(189, 65, 105), 272px 116px rgb(189, 65, 105),
            272px 120px rgb(189, 65, 105), 272px 124px rgb(189, 65, 105),
            272px 128px rgb(189, 65, 105), 272px 132px rgb(189, 65, 105),
            272px 136px rgb(189, 65, 105), 272px 140px rgb(189, 65, 105),
            272px 144px rgb(189, 65, 105), 272px 148px rgb(189, 65, 105),
            272px 152px rgb(189, 65, 105), 272px 164px rgb(189, 65, 105),
            272px 168px rgb(165, 120, 138), 276px 104px rgb(189, 65, 105),
            276px 108px rgb(189, 65, 105), 276px 112px rgb(189, 65, 105),
            276px 116px rgb(189, 65, 105), 276px 120px rgb(189, 65, 105),
            276px 124px rgb(189, 65, 105), 276px 128px rgb(189, 65, 105),
            276px 132px rgb(189, 65, 105), 276px 136px rgb(250, 141, 124),
            276px 140px rgb(250, 141, 124), 276px 144px rgb(250, 141, 124),
            276px 148px rgb(189, 65, 105), 276px 152px rgb(189, 65, 105),
            276px 156px rgb(189, 65, 105), 276px 160px rgb(189, 65, 105),
            276px 164px rgb(189, 65, 105), 276px 168px rgb(98, 59, 63),
            280px 48px rgb(189, 65, 105), 280px 52px rgb(189, 65, 105),
            280px 68px rgb(189, 65, 105), 280px 72px rgb(189, 65, 105),
            280px 76px rgb(189, 65, 105), 280px 80px rgb(189, 65, 105),
            280px 84px rgb(189, 65, 105), 280px 88px rgb(189, 65, 105),
            280px 92px rgb(189, 65, 105), 280px 96px rgb(189, 65, 105),
            280px 100px rgb(189, 65, 105), 280px 104px rgb(189, 65, 105),
            280px 108px rgb(189, 65, 105), 280px 112px rgb(189, 65, 105),
            280px 116px rgb(189, 65, 105), 280px 120px rgb(250, 141, 124),
            280px 124px rgb(250, 141, 124), 280px 128px rgb(250, 141, 124),
            280px 132px rgb(250, 141, 124), 280px 136px rgb(250, 141, 124),
            280px 140px rgb(189, 65, 105), 280px 144px rgb(189, 65, 105),
            280px 148px rgb(250, 141, 124), 280px 152px rgb(189, 65, 105),
            280px 156px rgb(189, 65, 105), 280px 160px rgb(189, 65, 105),
            284px 64px rgb(189, 65, 105), 284px 68px rgb(189, 65, 105),
            284px 72px rgb(189, 65, 105), 284px 76px rgb(189, 65, 105),
            284px 80px rgb(189, 65, 105), 284px 84px rgb(189, 65, 105),
            284px 88px rgb(189, 65, 105), 284px 92px rgb(189, 65, 105),
            284px 96px rgb(189, 65, 105), 284px 100px rgb(189, 65, 105),
            284px 104px rgb(189, 65, 105), 284px 108px rgb(189, 65, 105),
            284px 112px rgb(250, 141, 124), 284px 116px rgb(250, 141, 124),
            284px 120px rgb(250, 141, 124), 284px 124px rgb(250, 141, 124),
            284px 128px rgb(250, 141, 124), 284px 132px rgb(250, 141, 124),
            284px 136px rgb(189, 65, 105), 284px 140px rgb(189, 65, 105),
            284px 144px rgb(189, 65, 105), 284px 148px rgb(189, 65, 105),
            284px 152px rgb(189, 65, 105), 284px 160px rgb(189, 65, 105),
            288px 44px rgb(189, 65, 105), 288px 60px rgb(189, 65, 105),
            288px 64px rgb(250, 141, 124), 288px 68px rgb(250, 141, 124),
            288px 72px rgb(250, 141, 124), 288px 76px rgb(189, 65, 105),
            288px 80px rgb(250, 141, 124), 288px 84px rgb(189, 65, 105),
            288px 88px rgb(189, 65, 105), 288px 92px rgb(189, 65, 105),
            288px 96px rgb(250, 141, 124), 288px 100px rgb(189, 65, 105),
            288px 104px rgb(189, 65, 105), 288px 108px rgb(250, 141, 124),
            288px 112px rgb(250, 141, 124), 288px 116px rgb(250, 141, 124),
            288px 120px rgb(250, 141, 124), 288px 124px rgb(250, 141, 124),
            288px 128px rgb(250, 141, 124), 288px 132px rgb(250, 141, 124),
            288px 136px rgb(250, 141, 124), 288px 140px rgb(189, 65, 105),
            288px 144px rgb(189, 65, 105), 288px 148px rgb(250, 141, 124),
            288px 152px rgb(250, 141, 124), 288px 156px rgb(189, 65, 105),
            292px 40px rgb(189, 65, 105), 292px 44px rgb(250, 141, 124),
            292px 48px rgb(250, 141, 124), 292px 56px rgb(250, 141, 124),
            292px 60px rgb(250, 141, 124), 292px 64px rgb(189, 65, 105),
            292px 68px rgb(189, 65, 105), 292px 72px rgb(189, 65, 105),
            292px 76px rgb(189, 65, 105), 292px 80px rgb(189, 65, 105),
            292px 84px rgb(189, 65, 105), 292px 88px rgb(189, 65, 105),
            292px 92px rgb(189, 65, 105), 292px 96px rgb(189, 65, 105),
            292px 100px rgb(250, 141, 124), 292px 104px rgb(250, 141, 124),
            292px 108px rgb(250, 141, 124), 292px 112px rgb(250, 141, 124),
            292px 116px rgb(250, 141, 124), 292px 120px rgb(250, 141, 124),
            292px 124px rgb(250, 141, 124), 292px 128px rgb(250, 141, 124),
            292px 132px rgb(250, 141, 124), 292px 136px rgb(250, 141, 124),
            292px 140px rgb(250, 141, 124), 292px 144px rgb(250, 141, 124),
            292px 148px rgb(189, 65, 105), 292px 152px rgb(189, 65, 105),
            292px 156px rgb(189, 65, 105), 296px 44px rgb(250, 141, 124),
            296px 48px rgb(250, 141, 124), 296px 52px rgb(250, 141, 124),
            296px 56px rgb(250, 141, 124), 296px 60px rgb(189, 65, 105),
            296px 64px rgb(189, 65, 105), 296px 68px rgb(189, 65, 105),
            296px 72px rgb(189, 65, 105), 296px 92px rgb(189, 65, 105),
            296px 96px rgb(189, 65, 105), 296px 100px rgb(189, 65, 105),
            296px 104px rgb(189, 65, 105), 296px 108px rgb(189, 65, 105),
            296px 112px rgb(189, 65, 105), 296px 116px rgb(250, 141, 124),
            296px 120px rgb(250, 141, 124), 296px 124px rgb(250, 141, 124),
            296px 128px rgb(250, 141, 124), 296px 132px rgb(250, 141, 124),
            296px 136px rgb(250, 141, 124), 296px 140px rgb(250, 141, 124),
            296px 144px rgb(189, 65, 105), 296px 148px rgb(189, 65, 105),
            296px 152px rgb(189, 65, 105), 296px 156px rgb(189, 65, 105),
            296px 160px rgb(189, 65, 105), 296px 164px rgb(189, 65, 105),
            300px 44px rgb(189, 65, 105), 300px 48px rgb(189, 65, 105),
            300px 52px rgb(189, 65, 105), 300px 56px rgb(189, 65, 105),
            300px 60px rgb(189, 65, 105), 300px 64px rgb(189, 65, 105),
            300px 100px rgb(189, 65, 105), 300px 104px rgb(189, 65, 105),
            300px 108px rgb(189, 65, 105), 300px 112px rgb(189, 65, 105),
            300px 116px rgb(189, 65, 105), 300px 120px rgb(189, 65, 105),
            300px 124px rgb(250, 141, 124), 300px 128px rgb(250, 141, 124),
            300px 132px rgb(250, 141, 124), 300px 136px rgb(189, 65, 105),
            300px 140px rgb(189, 65, 105), 300px 144px rgb(189, 65, 105),
            300px 148px rgb(189, 65, 105), 300px 152px rgb(189, 65, 105),
            300px 156px rgb(189, 65, 105), 300px 160px rgb(189, 65, 105),
            300px 164px rgb(189, 65, 105), 304px 92px rgb(189, 65, 105),
            304px 96px rgb(189, 65, 105), 304px 108px rgb(189, 65, 105),
            304px 112px rgb(189, 65, 105), 304px 116px rgb(189, 65, 105),
            304px 120px rgb(189, 65, 105), 304px 124px rgb(189, 65, 105),
            304px 128px rgb(189, 65, 105), 304px 132px rgb(189, 65, 105),
            304px 136px rgb(189, 65, 105), 304px 140px rgb(189, 65, 105),
            304px 144px rgb(189, 65, 105), 304px 148px rgb(189, 65, 105),
            304px 164px rgb(189, 65, 105), 308px 88px rgb(189, 65, 105),
            308px 92px rgb(189, 65, 105), 308px 96px rgb(189, 65, 105),
            308px 100px rgb(189, 65, 105), 308px 104px rgb(189, 65, 105),
            308px 108px rgb(189, 65, 105), 308px 112px rgb(189, 65, 105),
            308px 116px rgb(189, 65, 105), 308px 120px rgb(189, 65, 105),
            308px 124px rgb(189, 65, 105), 308px 128px rgb(189, 65, 105),
            308px 132px rgb(189, 65, 105), 308px 136px rgb(189, 65, 105),
            308px 140px rgb(189, 65, 105), 312px 84px rgb(189, 65, 105),
            312px 88px rgb(189, 65, 105), 312px 108px rgb(189, 65, 105),
            312px 112px rgb(189, 65, 105), 312px 116px rgb(189, 65, 105),
            312px 120px rgb(189, 65, 105), 312px 124px rgb(189, 65, 105),
            316px 84px rgb(189, 65, 105), 336px 76px rgb(189, 65, 105),
            336px 80px rgb(189, 65, 105), 344px 188px rgb(165, 120, 138),
            360px 196px rgb(250, 141, 124), 360px 200px rgb(250, 141, 124),
            364px 196px rgb(250, 141, 124), 364px 200px rgb(250, 141, 124),
            368px 192px rgb(250, 141, 124), 368px 196px rgb(250, 141, 124),
            368px 200px rgb(250, 141, 124), 372px 192px rgb(250, 141, 124),
            372px 196px rgb(250, 141, 124), 376px 188px rgb(250, 141, 124),
            376px 192px rgb(165, 120, 138), 376px 196px rgb(250, 141, 124),
            380px 188px rgb(250, 141, 124), 380px 192px rgb(250, 141, 124),
            384px 184px rgb(250, 141, 124), 384px 188px rgb(165, 120, 138),
            384px 192px rgb(165, 120, 138), 388px 184px rgb(250, 141, 124),
            388px 188px rgb(165, 120, 138), 392px 180px rgb(250, 141, 124),
            392px 184px rgb(165, 120, 138), 392px 188px rgb(165, 120, 138),
            396px 180px rgb(250, 141, 124), 396px 184px rgb(165, 120, 138),
            400px 176px rgb(250, 141, 124), 400px 180px rgb(165, 120, 138),
            400px 184px rgb(165, 120, 138), 404px 176px rgb(165, 120, 138),
            404px 180px rgb(165, 120, 138), 408px 172px rgb(165, 120, 138),
            408px 180px rgb(165, 120, 138), 412px 172px rgb(165, 120, 138),
            412px 180px rgb(165, 120, 138), 416px 168px rgb(165, 120, 138),
            416px 176px rgb(165, 120, 138), 420px 176px rgb(165, 120, 138),
            424px 164px rgb(250, 141, 124), 424px 172px rgb(165, 120, 138),
            428px 164px rgb(250, 141, 124), 428px 168px rgb(189, 65, 105);
    }

    33.333333333333336% {
        box-shadow: 128px 204px rgb(118, 80, 95), 132px 196px rgb(118, 80, 95),
            132px 204px rgb(165, 120, 138), 136px 196px rgb(165, 120, 138),
            136px 204px rgb(250, 141, 124), 140px 196px rgb(250, 141, 124),
            140px 200px rgb(165, 120, 138), 140px 204px rgb(250, 141, 124),
            140px 208px rgb(118, 80, 95), 140px 220px rgb(118, 80, 95),
            140px 224px rgb(118, 80, 95), 140px 228px rgb(118, 80, 95),
            140px 232px rgb(118, 80, 95), 144px 136px rgb(165, 120, 138),
            144px 140px rgb(165, 120, 138), 144px 200px rgb(250, 141, 124),
            144px 204px rgb(165, 120, 138), 144px 208px rgb(165, 120, 138),
            144px 216px rgb(118, 80, 95), 144px 220px rgb(165, 120, 138),
            144px 224px rgb(165, 120, 138), 144px 228px rgb(165, 120, 138),
            148px 132px rgb(165, 120, 138), 148px 136px rgb(165, 120, 138),
            148px 140px rgb(165, 120, 138), 148px 144px rgb(165, 120, 138),
            152px 132px rgb(165, 120, 138), 152px 136px rgb(250, 141, 124),
            152px 140px rgb(250, 141, 124), 152px 144px rgb(165, 120, 138),
            152px 176px rgb(165, 120, 138), 156px 136px rgb(165, 120, 138),
            156px 140px rgb(165, 120, 138), 156px 176px rgb(165, 120, 138),
            156px 180px rgb(165, 120, 138), 156px 196px rgb(165, 120, 138),
            156px 200px rgb(165, 120, 138), 160px 176px rgb(165, 120, 138),
            160px 180px rgb(165, 120, 138), 160px 196px rgb(165, 120, 138),
            160px 200px rgb(165, 120, 138), 164px 200px rgb(250, 141, 124),
            176px 128px rgb(250, 141, 124), 176px 136px rgb(118, 80, 95),
            176px 140px rgb(165, 120, 138), 176px 144px rgb(165, 120, 138),
            180px 124px rgb(118, 80, 95), 180px 132px rgb(165, 120, 138),
            180px 136px rgb(165, 120, 138), 180px 144px rgb(250, 141, 124),
            184px 120px rgb(118, 80, 95), 184px 124px rgb(250, 141, 124),
            184px 128px rgb(165, 120, 138), 184px 132px rgb(250, 141, 124),
            184px 136px rgb(250, 141, 124), 184px 172px rgb(165, 120, 138),
            184px 180px rgb(165, 120, 138), 188px 120px rgb(165, 120, 138),
            188px 124px rgb(250, 141, 124), 188px 168px rgb(165, 120, 138),
            188px 172px rgb(165, 120, 138), 188px 176px rgb(165, 120, 138),
            188px 180px rgb(250, 141, 124), 192px 136px rgb(118, 80, 95),
            192px 172px rgb(250, 141, 124), 192px 176px rgb(250, 141, 124),
            192px 180px rgb(165, 120, 138), 204px 152px rgb(165, 120, 138),
            208px 148px rgb(250, 141, 124), 208px 152px rgb(165, 120, 138),
            212px 144px rgb(165, 120, 138), 212px 148px rgb(165, 120, 138),
            216px 144px rgb(250, 141, 124), 220px 144px rgb(250, 141, 124),
            220px 184px rgb(101, 104, 126), 224px 176px rgb(101, 104, 126),
            224px 180px rgb(101, 104, 126), 224px 184px rgb(165, 120, 138),
            224px 188px rgb(101, 104, 126), 228px 180px rgb(165, 120, 138),
            228px 184px rgb(165, 120, 138), 228px 188px rgb(165, 120, 138),
            228px 192px rgb(165, 120, 138), 232px 172px rgb(101, 104, 126),
            232px 176px rgb(165, 120, 138), 232px 180px rgb(165, 120, 138),
            232px 184px rgb(165, 120, 138), 232px 188px rgb(165, 120, 138),
            236px 172px rgb(101, 104, 126), 236px 176px rgb(165, 120, 138),
            236px 180px rgb(165, 120, 138), 236px 184px rgb(165, 120, 138),
            236px 188px rgb(165, 120, 138), 236px 192px rgb(165, 120, 138),
            236px 196px rgb(165, 120, 138), 240px 172px rgb(165, 120, 138),
            240px 176px rgb(165, 120, 138), 240px 180px rgb(165, 120, 138),
            240px 184px rgb(165, 120, 138), 240px 188px rgb(165, 120, 138),
            240px 192px rgb(165, 120, 138), 240px 196px rgb(101, 104, 126),
            240px 200px rgb(101, 104, 126), 244px 168px rgb(101, 104, 126),
            244px 172px rgb(165, 120, 138), 244px 176px rgb(165, 120, 138),
            244px 192px rgb(165, 120, 138), 244px 196px rgb(165, 120, 138),
            248px 68px rgb(189, 65, 105), 248px 168px rgb(101, 104, 126),
            248px 172px rgb(165, 120, 138), 248px 176px rgb(165, 120, 138),
            248px 192px rgb(165, 120, 138), 248px 196px rgb(165, 120, 138),
            248px 200px rgb(165, 120, 138), 252px 168px rgb(165, 120, 138),
            252px 172px rgb(165, 120, 138), 252px 200px rgb(165, 120, 138),
            256px 72px rgb(189, 65, 105), 256px 76px rgb(189, 65, 105),
            256px 168px rgb(165, 120, 138), 256px 172px rgb(165, 120, 138),
            256px 200px rgb(165, 120, 138), 256px 204px rgb(101, 104, 126),
            260px 76px rgb(189, 65, 105), 260px 164px rgb(101, 104, 126),
            260px 168px rgb(165, 120, 138), 260px 200px rgb(165, 120, 138),
            260px 204px rgb(165, 120, 138), 264px 76px rgb(189, 65, 105),
            264px 84px rgb(189, 65, 105), 264px 108px rgb(189, 65, 105),
            264px 112px rgb(189, 65, 105), 264px 116px rgb(189, 65, 105),
            264px 120px rgb(189, 65, 105), 264px 164px rgb(101, 104, 126),
            264px 200px rgb(165, 120, 138), 268px 40px rgb(189, 65, 105),
            268px 44px rgb(189, 65, 105), 268px 48px rgb(189, 65, 105),
            268px 52px rgb(189, 65, 105), 268px 56px rgb(189, 65, 105),
            268px 60px rgb(189, 65, 105), 268px 84px rgb(189, 65, 105),
            268px 100px rgb(189, 65, 105), 268px 104px rgb(189, 65, 105),
            268px 108px rgb(189, 65, 105), 268px 112px rgb(189, 65, 105),
            268px 116px rgb(189, 65, 105), 268px 120px rgb(189, 65, 105),
            268px 124px rgb(189, 65, 105), 268px 128px rgb(189, 65, 105),
            268px 132px rgb(189, 65, 105), 268px 160px rgb(189, 65, 105),
            268px 164px rgb(189, 65, 105), 268px 200px rgb(165, 120, 138),
            268px 204px rgb(165, 120, 138), 268px 208px rgb(101, 104, 126),
            272px 36px rgb(189, 65, 105), 272px 40px rgb(250, 141, 124),
            272px 44px rgb(250, 141, 124), 272px 48px rgb(250, 141, 124),
            272px 52px rgb(250, 141, 124), 272px 56px rgb(250, 141, 124),
            272px 60px rgb(189, 65, 105), 272px 64px rgb(189, 65, 105),
            272px 68px rgb(189, 65, 105), 272px 96px rgb(189, 65, 105),
            272px 100px rgb(189, 65, 105), 272px 104px rgb(189, 65, 105),
            272px 108px rgb(189, 65, 105), 272px 112px rgb(250, 141, 124),
            272px 116px rgb(250, 141, 124), 272px 120px rgb(250, 141, 124),
            272px 124px rgb(250, 141, 124), 272px 128px rgb(189, 65, 105),
            272px 132px rgb(189, 65, 105), 272px 136px rgb(189, 65, 105),
            272px 156px rgb(189, 65, 105), 272px 160px rgb(189, 65, 105),
            272px 164px rgb(189, 65, 105), 272px 168px rgb(165, 120, 138),
            272px 200px rgb(165, 120, 138), 272px 204px rgb(165, 120, 138),
            276px 32px rgb(189, 65, 105), 276px 36px rgb(250, 141, 124),
            276px 40px rgb(250, 141, 124), 276px 44px rgb(250, 141, 124),
            276px 48px rgb(189, 65, 105), 276px 52px rgb(189, 65, 105),
            276px 56px rgb(189, 65, 105), 276px 60px rgb(250, 141, 124),
            276px 64px rgb(250, 141, 124), 276px 68px rgb(189, 65, 105),
            276px 72px rgb(189, 65, 105), 276px 92px rgb(189, 65, 105),
            276px 96px rgb(189, 65, 105), 276px 100px rgb(189, 65, 105),
            276px 104px rgb(250, 141, 124), 276px 108px rgb(250, 141, 124),
            276px 112px rgb(250, 141, 124), 276px 116px rgb(250, 141, 124),
            276px 120px rgb(250, 141, 124), 276px 124px rgb(189, 65, 105),
            276px 128px rgb(250, 141, 124), 276px 132px rgb(250, 141, 124),
            276px 136px rgb(189, 65, 105), 276px 140px rgb(189, 65, 105),
            276px 144px rgb(189, 65, 105), 276px 148px rgb(189, 65, 105),
            276px 152px rgb(189, 65, 105), 276px 156px rgb(189, 65, 105),
            276px 160px rgb(189, 65, 105), 276px 164px rgb(101, 104, 126),
            276px 168px rgb(98, 59, 63), 276px 200px rgb(165, 120, 138),
            276px 204px rgb(165, 120, 138), 276px 208px rgb(101, 104, 126),
            280px 32px rgb(189, 65, 105), 280px 36px rgb(250, 141, 124),
            280px 40px rgb(250, 141, 124), 280px 60px rgb(189, 65, 105),
            280px 64px rgb(189, 65, 105), 280px 68px rgb(189, 65, 105),
            280px 72px rgb(189, 65, 105), 280px 76px rgb(189, 65, 105),
            280px 80px rgb(189, 65, 105), 280px 84px rgb(189, 65, 105),
            280px 88px rgb(189, 65, 105), 280px 92px rgb(189, 65, 105),
            280px 96px rgb(189, 65, 105), 280px 100px rgb(250, 141, 124),
            280px 104px rgb(250, 141, 124), 280px 108px rgb(250, 141, 124),
            280px 112px rgb(250, 141, 124), 280px 116px rgb(250, 141, 124),
            280px 120px rgb(250, 141, 124), 280px 124px rgb(250, 141, 124),
            280px 128px rgb(189, 65, 105), 280px 132px rgb(189, 65, 105),
            280px 136px rgb(250, 141, 124), 280px 140px rgb(189, 65, 105),
            280px 144px rgb(189, 65, 105), 280px 148px rgb(189, 65, 105),
            280px 152px rgb(189, 65, 105), 280px 156px rgb(189, 65, 105),
            280px 200px rgb(165, 120, 138), 280px 204px rgb(165, 120, 138),
            280px 208px rgb(101, 104, 126), 284px 32px rgb(189, 65, 105),
            284px 36px rgb(189, 65, 105), 284px 40px rgb(189, 65, 105),
            284px 68px rgb(189, 65, 105), 284px 72px rgb(189, 65, 105),
            284px 76px rgb(189, 65, 105), 284px 80px rgb(189, 65, 105),
            284px 84px rgb(189, 65, 105), 284px 88px rgb(189, 65, 105),
            284px 92px rgb(189, 65, 105), 284px 96px rgb(250, 141, 124),
            284px 100px rgb(250, 141, 124), 284px 104px rgb(250, 141, 124),
            284px 108px rgb(250, 141, 124), 284px 112px rgb(250, 141, 124),
            284px 116px rgb(250, 141, 124), 284px 120px rgb(250, 141, 124),
            284px 124px rgb(250, 141, 124), 284px 128px rgb(250, 141, 124),
            284px 132px rgb(189, 65, 105), 284px 136px rgb(189, 65, 105),
            284px 140px rgb(189, 65, 105), 284px 144px rgb(189, 65, 105),
            284px 148px rgb(189, 65, 105), 284px 152px rgb(189, 65, 105),
            284px 200px rgb(165, 120, 138), 284px 204px rgb(165, 120, 138),
            284px 208px rgb(101, 104, 126), 288px 32px rgb(189, 65, 105),
            288px 36px rgb(189, 65, 105), 288px 48px rgb(189, 65, 105),
            288px 52px rgb(189, 65, 105), 288px 76px rgb(189, 65, 105),
            288px 80px rgb(189, 65, 105), 288px 84px rgb(189, 65, 105),
            288px 88px rgb(189, 65, 105), 288px 92px rgb(189, 65, 105),
            288px 96px rgb(250, 141, 124), 288px 100px rgb(250, 141, 124),
            288px 104px rgb(250, 141, 124), 288px 108px rgb(250, 141, 124),
            288px 112px rgb(189, 65, 105), 288px 116px rgb(189, 65, 105),
            288px 120px rgb(189, 65, 105), 288px 124px rgb(189, 65, 105),
            288px 128px rgb(189, 65, 105), 288px 132px rgb(250, 141, 124),
            288px 136px rgb(250, 141, 124), 288px 140px rgb(189, 65, 105),
            288px 144px rgb(189, 65, 105), 288px 148px rgb(189, 65, 105),
            288px 152px rgb(189, 65, 105), 288px 200px rgb(165, 120, 138),
            288px 204px rgb(165, 120, 138), 288px 208px rgb(101, 104, 126),
            292px 48px rgb(189, 65, 105), 292px 52px rgb(189, 65, 105),
            292px 56px rgb(189, 65, 105), 292px 60px rgb(189, 65, 105),
            292px 80px rgb(189, 65, 105), 292px 84px rgb(189, 65, 105),
            292px 88px rgb(250, 141, 124), 292px 92px rgb(250, 141, 124),
            292px 96px rgb(250, 141, 124), 292px 100px rgb(250, 141, 124),
            292px 104px rgb(250, 141, 124), 292px 108px rgb(189, 65, 105),
            292px 112px rgb(189, 65, 105), 292px 116px rgb(189, 65, 105),
            292px 120px rgb(189, 65, 105), 292px 124px rgb(189, 65, 105),
            292px 128px rgb(189, 65, 105), 292px 132px rgb(189, 65, 105),
            292px 136px rgb(189, 65, 105), 292px 140px rgb(189, 65, 105),
            292px 144px rgb(250, 141, 124), 292px 148px rgb(189, 65, 105),
            292px 152px rgb(189, 65, 105), 292px 156px rgb(189, 65, 105),
            292px 200px rgb(165, 120, 138), 292px 204px rgb(165, 120, 138),
            296px 92px rgb(189, 65, 105), 296px 96px rgb(189, 65, 105),
            296px 100px rgb(250, 141, 124), 296px 104px rgb(189, 65, 105),
            296px 108px rgb(189, 65, 105), 296px 112px rgb(189, 65, 105),
            296px 116px rgb(189, 65, 105), 296px 120px rgb(189, 65, 105),
            296px 124px rgb(189, 65, 105), 296px 128px rgb(189, 65, 105),
            296px 132px rgb(189, 65, 105), 296px 136px rgb(189, 65, 105),
            296px 140px rgb(189, 65, 105), 296px 144px rgb(189, 65, 105),
            296px 148px rgb(189, 65, 105), 296px 152px rgb(189, 65, 105),
            296px 156px rgb(189, 65, 105), 296px 160px rgb(189, 65, 105),
            296px 200px rgb(165, 120, 138), 296px 204px rgb(165, 120, 138),
            296px 208px rgb(101, 104, 126), 300px 80px rgb(189, 65, 105),
            300px 84px rgb(189, 65, 105), 300px 88px rgb(189, 65, 105),
            300px 92px rgb(189, 65, 105), 300px 96px rgb(189, 65, 105),
            300px 100px rgb(189, 65, 105), 300px 104px rgb(189, 65, 105),
            300px 108px rgb(189, 65, 105), 300px 112px rgb(189, 65, 105),
            300px 116px rgb(189, 65, 105), 300px 120px rgb(189, 65, 105),
            300px 124px rgb(189, 65, 105), 300px 128px rgb(189, 65, 105),
            300px 136px rgb(189, 65, 105), 300px 140px rgb(189, 65, 105),
            300px 144px rgb(189, 65, 105), 300px 148px rgb(189, 65, 105),
            300px 152px rgb(189, 65, 105), 300px 156px rgb(189, 65, 105),
            300px 160px rgb(189, 65, 105), 300px 164px rgb(189, 65, 105),
            300px 200px rgb(165, 120, 138), 300px 204px rgb(165, 120, 138),
            300px 208px rgb(101, 104, 126), 304px 56px rgb(189, 65, 105),
            304px 76px rgb(189, 65, 105), 304px 80px rgb(250, 141, 124),
            304px 84px rgb(250, 141, 124), 304px 88px rgb(250, 141, 124),
            304px 92px rgb(189, 65, 105), 304px 96px rgb(189, 65, 105),
            304px 100px rgb(189, 65, 105), 304px 104px rgb(189, 65, 105),
            304px 108px rgb(189, 65, 105), 304px 112px rgb(189, 65, 105),
            304px 116px rgb(189, 65, 105), 304px 120px rgb(189, 65, 105),
            304px 144px rgb(189, 65, 105), 304px 148px rgb(189, 65, 105),
            304px 152px rgb(189, 65, 105), 304px 164px rgb(189, 65, 105),
            304px 200px rgb(165, 120, 138), 304px 204px rgb(165, 120, 138),
            308px 60px rgb(189, 65, 105), 308px 64px rgb(189, 65, 105),
            308px 68px rgb(189, 65, 105), 308px 72px rgb(189, 65, 105),
            308px 76px rgb(189, 65, 105), 308px 80px rgb(250, 141, 124),
            308px 84px rgb(189, 65, 105), 308px 88px rgb(189, 65, 105),
            308px 92px rgb(189, 65, 105), 308px 96px rgb(189, 65, 105),
            308px 104px rgb(189, 65, 105), 308px 108px rgb(189, 65, 105),
            308px 112px rgb(189, 65, 105), 308px 164px rgb(101, 104, 126),
            308px 200px rgb(165, 120, 138), 308px 204px rgb(101, 104, 126),
            312px 68px rgb(189, 65, 105), 312px 72px rgb(189, 65, 105),
            312px 76px rgb(189, 65, 105), 312px 80px rgb(189, 65, 105),
            312px 168px rgb(165, 120, 138), 312px 172px rgb(165, 120, 138),
            312px 200px rgb(165, 120, 138), 312px 204px rgb(101, 104, 126),
            316px 168px rgb(101, 104, 126), 316px 172px rgb(165, 120, 138),
            316px 196px rgb(165, 120, 138), 316px 200px rgb(165, 120, 138),
            316px 204px rgb(101, 104, 126), 320px 88px rgb(189, 65, 105),
            320px 168px rgb(101, 104, 126), 320px 172px rgb(165, 120, 138),
            320px 196px rgb(165, 120, 138), 320px 200px rgb(165, 120, 138),
            324px 84px rgb(189, 65, 105), 324px 88px rgb(189, 65, 105),
            324px 168px rgb(101, 104, 126), 324px 172px rgb(165, 120, 138),
            324px 176px rgb(165, 120, 138), 324px 180px rgb(165, 120, 138),
            324px 184px rgb(165, 120, 138), 324px 188px rgb(165, 120, 138),
            324px 192px rgb(165, 120, 138), 324px 196px rgb(165, 120, 138),
            324px 200px rgb(101, 104, 126), 328px 80px rgb(189, 65, 105),
            328px 84px rgb(189, 65, 105), 328px 88px rgb(189, 65, 105),
            328px 176px rgb(165, 120, 138), 328px 180px rgb(165, 120, 138),
            328px 184px rgb(165, 120, 138), 328px 188px rgb(165, 120, 138),
            328px 192px rgb(101, 104, 126), 328px 196px rgb(101, 104, 126),
            328px 200px rgb(101, 104, 126), 332px 80px rgb(189, 65, 105),
            332px 84px rgb(189, 65, 105), 332px 88px rgb(189, 65, 105),
            332px 172px rgb(101, 104, 126), 332px 176px rgb(165, 120, 138),
            332px 180px rgb(165, 120, 138), 332px 184px rgb(165, 120, 138),
            332px 188px rgb(165, 120, 138), 332px 192px rgb(165, 120, 138),
            336px 80px rgb(189, 65, 105), 336px 84px rgb(189, 65, 105),
            336px 176px rgb(101, 104, 126), 336px 180px rgb(165, 120, 138),
            336px 184px rgb(101, 104, 126), 336px 188px rgb(101, 104, 126),
            336px 192px rgb(101, 104, 126), 336px 196px rgb(165, 120, 138),
            340px 176px rgb(101, 104, 126), 340px 180px rgb(101, 104, 126),
            340px 184px rgb(165, 120, 138), 340px 192px rgb(165, 120, 138),
            344px 180px rgb(101, 104, 126), 344px 188px rgb(101, 104, 126),
            360px 196px rgb(250, 141, 124), 360px 200px rgb(250, 141, 124),
            364px 196px rgb(250, 141, 124), 364px 200px rgb(250, 141, 124),
            368px 192px rgb(250, 141, 124), 368px 196px rgb(165, 120, 138),
            368px 200px rgb(250, 141, 124), 372px 192px rgb(250, 141, 124),
            372px 196px rgb(165, 120, 138), 376px 188px rgb(250, 141, 124),
            376px 192px rgb(165, 120, 138), 376px 196px rgb(250, 141, 124),
            380px 188px rgb(250, 141, 124), 380px 192px rgb(165, 120, 138),
            384px 184px rgb(250, 141, 124), 384px 188px rgb(165, 120, 138),
            384px 192px rgb(165, 120, 138), 388px 184px rgb(250, 141, 124),
            388px 188px rgb(165, 120, 138), 392px 180px rgb(250, 141, 124),
            392px 184px rgb(165, 120, 138), 392px 188px rgb(165, 120, 138),
            396px 180px rgb(250, 141, 124), 396px 184px rgb(165, 120, 138),
            400px 176px rgb(165, 120, 138), 400px 180px rgb(165, 120, 138),
            400px 184px rgb(165, 120, 138), 404px 176px rgb(165, 120, 138),
            404px 180px rgb(165, 120, 138), 408px 172px rgb(165, 120, 138),
            408px 180px rgb(165, 120, 138), 412px 172px rgb(165, 120, 138),
            412px 180px rgb(165, 120, 138), 416px 168px rgb(165, 120, 138),
            416px 176px rgb(165, 120, 138), 420px 176px rgb(165, 120, 138),
            424px 164px rgb(189, 65, 105), 424px 172px rgb(165, 120, 138),
            428px 164px rgb(250, 141, 124), 428px 168px rgb(189, 65, 105);
    }

    50% {
        box-shadow: 128px 204px rgb(118, 80, 95), 132px 196px rgb(118, 80, 95),
            132px 204px rgb(165, 120, 138), 136px 196px rgb(165, 120, 138),
            136px 204px rgb(250, 141, 124), 140px 196px rgb(250, 141, 124),
            140px 200px rgb(165, 120, 138), 140px 204px rgb(250, 141, 124),
            140px 208px rgb(118, 80, 95), 140px 220px rgb(118, 80, 95),
            140px 224px rgb(118, 80, 95), 140px 228px rgb(118, 80, 95),
            140px 232px rgb(118, 80, 95), 144px 136px rgb(165, 120, 138),
            144px 140px rgb(165, 120, 138), 144px 200px rgb(250, 141, 124),
            144px 204px rgb(165, 120, 138), 144px 208px rgb(165, 120, 138),
            144px 216px rgb(118, 80, 95), 144px 220px rgb(165, 120, 138),
            144px 224px rgb(165, 120, 138), 144px 228px rgb(165, 120, 138),
            148px 132px rgb(165, 120, 138), 148px 136px rgb(165, 120, 138),
            148px 140px rgb(165, 120, 138), 148px 144px rgb(165, 120, 138),
            152px 132px rgb(165, 120, 138), 152px 136px rgb(250, 141, 124),
            152px 140px rgb(250, 141, 124), 152px 144px rgb(165, 120, 138),
            152px 176px rgb(165, 120, 138), 156px 136px rgb(165, 120, 138),
            156px 140px rgb(165, 120, 138), 156px 176px rgb(165, 120, 138),
            156px 180px rgb(165, 120, 138), 156px 196px rgb(165, 120, 138),
            156px 200px rgb(165, 120, 138), 160px 176px rgb(165, 120, 138),
            160px 180px rgb(165, 120, 138), 160px 196px rgb(165, 120, 138),
            160px 200px rgb(165, 120, 138), 164px 200px rgb(250, 141, 124),
            176px 128px rgb(250, 141, 124), 176px 136px rgb(118, 80, 95),
            176px 140px rgb(165, 120, 138), 176px 144px rgb(165, 120, 138),
            180px 124px rgb(118, 80, 95), 180px 132px rgb(165, 120, 138),
            180px 136px rgb(165, 120, 138), 180px 144px rgb(250, 141, 124),
            184px 120px rgb(118, 80, 95), 184px 124px rgb(250, 141, 124),
            184px 128px rgb(165, 120, 138), 184px 132px rgb(250, 141, 124),
            184px 136px rgb(250, 141, 124), 184px 172px rgb(165, 120, 138),
            184px 180px rgb(165, 120, 138), 188px 120px rgb(165, 120, 138),
            188px 124px rgb(250, 141, 124), 188px 168px rgb(165, 120, 138),
            188px 172px rgb(165, 120, 138), 188px 176px rgb(165, 120, 138),
            188px 180px rgb(250, 141, 124), 192px 136px rgb(118, 80, 95),
            192px 172px rgb(250, 141, 124), 192px 176px rgb(250, 141, 124),
            192px 180px rgb(165, 120, 138), 204px 152px rgb(165, 120, 138),
            208px 148px rgb(250, 141, 124), 208px 152px rgb(165, 120, 138),
            212px 144px rgb(165, 120, 138), 212px 148px rgb(165, 120, 138),
            216px 144px rgb(250, 141, 124), 220px 144px rgb(250, 141, 124),
            220px 184px rgb(101, 104, 126), 224px 176px rgb(101, 104, 126),
            224px 180px rgb(101, 104, 126), 224px 184px rgb(165, 120, 138),
            224px 188px rgb(101, 104, 126), 228px 180px rgb(165, 120, 138),
            228px 184px rgb(165, 120, 138), 228px 188px rgb(165, 120, 138),
            228px 192px rgb(165, 120, 138), 232px 172px rgb(101, 104, 126),
            232px 176px rgb(165, 120, 138), 232px 180px rgb(165, 120, 138),
            232px 184px rgb(165, 120, 138), 232px 188px rgb(165, 120, 138),
            236px 172px rgb(101, 104, 126), 236px 176px rgb(165, 120, 138),
            236px 180px rgb(165, 120, 138), 236px 184px rgb(165, 120, 138),
            236px 188px rgb(165, 120, 138), 236px 192px rgb(165, 120, 138),
            236px 196px rgb(165, 120, 138), 240px 36px rgb(189, 65, 105),
            240px 40px rgb(189, 65, 105), 240px 172px rgb(165, 120, 138),
            240px 176px rgb(165, 120, 138), 240px 192px rgb(165, 120, 138),
            240px 196px rgb(101, 104, 126), 240px 200px rgb(101, 104, 126),
            244px 40px rgb(189, 65, 105), 244px 44px rgb(189, 65, 105),
            244px 168px rgb(101, 104, 126), 244px 172px rgb(165, 120, 138),
            244px 176px rgb(165, 120, 138), 244px 192px rgb(165, 120, 138),
            244px 196px rgb(165, 120, 138), 248px 132px rgb(189, 65, 105),
            248px 136px rgb(189, 65, 105), 248px 140px rgb(189, 65, 105),
            248px 168px rgb(101, 104, 126), 248px 172px rgb(165, 120, 138),
            248px 196px rgb(165, 120, 138), 248px 200px rgb(165, 120, 138),
            252px 136px rgb(189, 65, 105), 252px 140px rgb(189, 65, 105),
            252px 144px rgb(189, 65, 105), 252px 168px rgb(165, 120, 138),
            252px 196px rgb(165, 120, 138), 252px 200px rgb(165, 120, 138),
            256px 168px rgb(165, 120, 138), 256px 172px rgb(165, 120, 138),
            256px 196px rgb(165, 120, 138), 256px 200px rgb(165, 120, 138),
            256px 204px rgb(101, 104, 126), 260px 128px rgb(189, 65, 105),
            260px 132px rgb(189, 65, 105), 260px 136px rgb(189, 65, 105),
            260px 140px rgb(189, 65, 105), 260px 144px rgb(189, 65, 105),
            260px 148px rgb(189, 65, 105), 260px 152px rgb(189, 65, 105),
            260px 156px rgb(189, 65, 105), 260px 164px rgb(101, 104, 126),
            260px 168px rgb(165, 120, 138), 260px 200px rgb(165, 120, 138),
            260px 204px rgb(165, 120, 138), 264px 116px rgb(189, 65, 105),
            264px 120px rgb(189, 65, 105), 264px 124px rgb(189, 65, 105),
            264px 128px rgb(189, 65, 105), 264px 132px rgb(189, 65, 105),
            264px 136px rgb(189, 65, 105), 264px 140px rgb(189, 65, 105),
            264px 144px rgb(189, 65, 105), 264px 148px rgb(189, 65, 105),
            264px 152px rgb(189, 65, 105), 264px 156px rgb(189, 65, 105),
            264px 160px rgb(189, 65, 105), 264px 164px rgb(101, 104, 126),
            264px 200px rgb(165, 120, 138), 268px 84px rgb(189, 65, 105),
            268px 88px rgb(189, 65, 105), 268px 92px rgb(189, 65, 105),
            268px 96px rgb(189, 65, 105), 268px 100px rgb(189, 65, 105),
            268px 108px rgb(189, 65, 105), 268px 112px rgb(189, 65, 105),
            268px 116px rgb(189, 65, 105), 268px 120px rgb(189, 65, 105),
            268px 124px rgb(189, 65, 105), 268px 128px rgb(189, 65, 105),
            268px 132px rgb(189, 65, 105), 268px 136px rgb(189, 65, 105),
            268px 140px rgb(250, 141, 124), 268px 144px rgb(250, 141, 124),
            268px 148px rgb(250, 141, 124), 268px 152px rgb(250, 141, 124),
            268px 156px rgb(189, 65, 105), 268px 160px rgb(189, 65, 105),
            268px 164px rgb(189, 65, 105), 268px 200px rgb(165, 120, 138),
            268px 204px rgb(165, 120, 138), 268px 208px rgb(101, 104, 126),
            272px 76px rgb(189, 65, 105), 272px 80px rgb(189, 65, 105),
            272px 84px rgb(189, 65, 105), 272px 88px rgb(189, 65, 105),
            272px 92px rgb(189, 65, 105), 272px 96px rgb(189, 65, 105),
            272px 100px rgb(189, 65, 105), 272px 104px rgb(189, 65, 105),
            272px 108px rgb(189, 65, 105), 272px 112px rgb(189, 65, 105),
            272px 116px rgb(189, 65, 105), 272px 120px rgb(189, 65, 105),
            272px 124px rgb(189, 65, 105), 272px 128px rgb(189, 65, 105),
            272px 132px rgb(189, 65, 105), 272px 136px rgb(250, 141, 124),
            272px 140px rgb(250, 141, 124), 272px 144px rgb(250, 141, 124),
            272px 148px rgb(250, 141, 124), 272px 152px rgb(250, 141, 124),
            272px 156px rgb(250, 141, 124), 272px 160px rgb(189, 65, 105),
            272px 164px rgb(189, 65, 105), 272px 168px rgb(165, 120, 138),
            272px 200px rgb(165, 120, 138), 272px 204px rgb(165, 120, 138),
            276px 4px rgb(189, 65, 105), 276px 8px rgb(189, 65, 105),
            276px 12px rgb(189, 65, 105), 276px 72px rgb(189, 65, 105),
            276px 76px rgb(189, 65, 105), 276px 80px rgb(189, 65, 105),
            276px 84px rgb(189, 65, 105), 276px 88px rgb(189, 65, 105),
            276px 92px rgb(189, 65, 105), 276px 96px rgb(189, 65, 105),
            276px 100px rgb(189, 65, 105), 276px 104px rgb(189, 65, 105),
            276px 108px rgb(189, 65, 105), 276px 112px rgb(189, 65, 105),
            276px 116px rgb(189, 65, 105), 276px 120px rgb(189, 65, 105),
            276px 124px rgb(189, 65, 105), 276px 128px rgb(250, 141, 124),
            276px 132px rgb(250, 141, 124), 276px 136px rgb(250, 141, 124),
            276px 140px rgb(250, 141, 124), 276px 144px rgb(250, 141, 124),
            276px 148px rgb(250, 141, 124), 276px 152px rgb(250, 141, 124),
            276px 156px rgb(250, 141, 124), 276px 160px rgb(189, 65, 105),
            276px 164px rgb(189, 65, 105), 276px 204px rgb(165, 120, 138),
            276px 208px rgb(101, 104, 126), 280px 8px rgb(189, 65, 105),
            280px 12px rgb(189, 65, 105), 280px 68px rgb(189, 65, 105),
            280px 72px rgb(189, 65, 105), 280px 76px rgb(189, 65, 105),
            280px 80px rgb(189, 65, 105), 280px 84px rgb(250, 141, 124),
            280px 88px rgb(250, 141, 124), 280px 92px rgb(250, 141, 124),
            280px 96px rgb(189, 65, 105), 280px 100px rgb(189, 65, 105),
            280px 104px rgb(189, 65, 105), 280px 108px rgb(189, 65, 105),
            280px 112px rgb(189, 65, 105), 280px 116px rgb(250, 141, 124),
            280px 120px rgb(250, 141, 124), 280px 124px rgb(250, 141, 124),
            280px 128px rgb(250, 141, 124), 280px 132px rgb(250, 141, 124),
            280px 136px rgb(250, 141, 124), 280px 140px rgb(250, 141, 124),
            280px 144px rgb(250, 141, 124), 280px 148px rgb(250, 141, 124),
            280px 152px rgb(189, 65, 105), 280px 156px rgb(189, 65, 105),
            280px 160px rgb(189, 65, 105), 280px 204px rgb(165, 120, 138),
            280px 208px rgb(101, 104, 126), 284px 12px rgb(189, 65, 105),
            284px 16px rgb(250, 141, 124), 284px 64px rgb(189, 65, 105),
            284px 68px rgb(189, 65, 105), 284px 72px rgb(189, 65, 105),
            284px 76px rgb(189, 65, 105), 284px 80px rgb(250, 141, 124),
            284px 84px rgb(189, 65, 105), 284px 88px rgb(250, 141, 124),
            284px 92px rgb(250, 141, 124), 284px 96px rgb(250, 141, 124),
            284px 100px rgb(250, 141, 124), 284px 104px rgb(250, 141, 124),
            284px 108px rgb(250, 141, 124), 284px 112px rgb(250, 141, 124),
            284px 116px rgb(250, 141, 124), 284px 120px rgb(250, 141, 124),
            284px 124px rgb(250, 141, 124), 284px 128px rgb(250, 141, 124),
            284px 132px rgb(250, 141, 124), 284px 136px rgb(250, 141, 124),
            284px 140px rgb(250, 141, 124), 284px 144px rgb(250, 141, 124),
            284px 148px rgb(189, 65, 105), 284px 152px rgb(189, 65, 105),
            284px 204px rgb(165, 120, 138), 284px 208px rgb(101, 104, 126),
            288px 12px rgb(189, 65, 105), 288px 16px rgb(189, 65, 105),
            288px 20px rgb(250, 141, 124), 288px 24px rgb(250, 141, 124),
            288px 60px rgb(189, 65, 105), 288px 64px rgb(189, 65, 105),
            288px 68px rgb(189, 65, 105), 288px 72px rgb(189, 65, 105),
            288px 76px rgb(189, 65, 105), 288px 80px rgb(189, 65, 105),
            288px 84px rgb(189, 65, 105), 288px 88px rgb(189, 65, 105),
            288px 92px rgb(189, 65, 105), 288px 96px rgb(189, 65, 105),
            288px 100px rgb(250, 141, 124), 288px 104px rgb(250, 141, 124),
            288px 108px rgb(250, 141, 124), 288px 112px rgb(250, 141, 124),
            288px 116px rgb(250, 141, 124), 288px 120px rgb(250, 141, 124),
            288px 124px rgb(250, 141, 124), 288px 128px rgb(250, 141, 124),
            288px 132px rgb(250, 141, 124), 288px 136px rgb(250, 141, 124),
            288px 140px rgb(250, 141, 124), 288px 144px rgb(189, 65, 105),
            288px 148px rgb(189, 65, 105), 288px 152px rgb(189, 65, 105),
            288px 156px rgb(189, 65, 105), 288px 204px rgb(165, 120, 138),
            288px 208px rgb(101, 104, 126), 292px 16px rgb(189, 65, 105),
            292px 20px rgb(189, 65, 105), 292px 24px rgb(189, 65, 105),
            292px 28px rgb(250, 141, 124), 292px 32px rgb(250, 141, 124),
            292px 36px rgb(189, 65, 105), 292px 40px rgb(189, 65, 105),
            292px 92px rgb(189, 65, 105), 292px 96px rgb(189, 65, 105),
            292px 100px rgb(189, 65, 105), 292px 104px rgb(189, 65, 105),
            292px 108px rgb(189, 65, 105), 292px 112px rgb(189, 65, 105),
            292px 116px rgb(189, 65, 105), 292px 120px rgb(250, 141, 124),
            292px 124px rgb(250, 141, 124), 292px 128px rgb(250, 141, 124),
            292px 132px rgb(250, 141, 124), 292px 136px rgb(250, 141, 124),
            292px 140px rgb(250, 141, 124), 292px 144px rgb(189, 65, 105),
            292px 148px rgb(189, 65, 105), 292px 152px rgb(189, 65, 105),
            292px 156px rgb(189, 65, 105), 292px 204px rgb(165, 120, 138),
            296px 84px rgb(189, 65, 105), 296px 88px rgb(189, 65, 105),
            296px 92px rgb(189, 65, 105), 296px 96px rgb(189, 65, 105),
            296px 100px rgb(189, 65, 105), 296px 104px rgb(189, 65, 105),
            296px 108px rgb(189, 65, 105), 296px 112px rgb(189, 65, 105),
            296px 116px rgb(189, 65, 105), 296px 120px rgb(189, 65, 105),
            296px 124px rgb(189, 65, 105), 296px 128px rgb(250, 141, 124),
            296px 132px rgb(250, 141, 124), 296px 136px rgb(250, 141, 124),
            296px 140px rgb(250, 141, 124), 296px 144px rgb(250, 141, 124),
            296px 148px rgb(250, 141, 124), 296px 152px rgb(250, 141, 124),
            296px 156px rgb(250, 141, 124), 296px 160px rgb(189, 65, 105),
            296px 164px rgb(189, 65, 105), 296px 204px rgb(165, 120, 138),
            296px 208px rgb(101, 104, 126), 300px 116px rgb(189, 65, 105),
            300px 120px rgb(189, 65, 105), 300px 124px rgb(189, 65, 105),
            300px 128px rgb(250, 141, 124), 300px 132px rgb(250, 141, 124),
            300px 136px rgb(250, 141, 124), 300px 140px rgb(250, 141, 124),
            300px 144px rgb(250, 141, 124), 300px 148px rgb(250, 141, 124),
            300px 152px rgb(250, 141, 124), 300px 156px rgb(250, 141, 124),
            300px 160px rgb(250, 141, 124), 300px 164px rgb(189, 65, 105),
            300px 204px rgb(165, 120, 138), 300px 208px rgb(101, 104, 126),
            304px 120px rgb(189, 65, 105), 304px 124px rgb(189, 65, 105),
            304px 128px rgb(189, 65, 105), 304px 132px rgb(189, 65, 105),
            304px 136px rgb(189, 65, 105), 304px 140px rgb(189, 65, 105),
            304px 144px rgb(189, 65, 105), 304px 148px rgb(189, 65, 105),
            304px 152px rgb(189, 65, 105), 304px 156px rgb(189, 65, 105),
            304px 160px rgb(189, 65, 105), 304px 164px rgb(189, 65, 105),
            304px 168px rgb(250, 141, 124), 304px 200px rgb(165, 120, 138),
            304px 204px rgb(165, 120, 138), 308px 128px rgb(189, 65, 105),
            308px 132px rgb(189, 65, 105), 308px 136px rgb(189, 65, 105),
            308px 140px rgb(189, 65, 105), 308px 144px rgb(189, 65, 105),
            308px 148px rgb(189, 65, 105), 308px 152px rgb(189, 65, 105),
            308px 156px rgb(189, 65, 105), 308px 160px rgb(189, 65, 105),
            308px 164px rgb(189, 65, 105), 308px 168px rgb(165, 120, 138),
            308px 172px rgb(250, 141, 124), 308px 200px rgb(165, 120, 138),
            308px 204px rgb(101, 104, 126), 312px 136px rgb(189, 65, 105),
            312px 140px rgb(189, 65, 105), 312px 144px rgb(189, 65, 105),
            312px 148px rgb(189, 65, 105), 312px 152px rgb(189, 65, 105),
            312px 156px rgb(189, 65, 105), 312px 168px rgb(165, 120, 138),
            312px 172px rgb(165, 120, 138), 312px 200px rgb(165, 120, 138),
            312px 204px rgb(101, 104, 126), 316px 168px rgb(101, 104, 126),
            316px 172px rgb(165, 120, 138), 316px 196px rgb(165, 120, 138),
            316px 200px rgb(165, 120, 138), 316px 204px rgb(101, 104, 126),
            320px 168px rgb(101, 104, 126), 320px 172px rgb(165, 120, 138),
            320px 192px rgb(165, 120, 138), 320px 196px rgb(165, 120, 138),
            320px 200px rgb(165, 120, 138), 324px 40px rgb(189, 65, 105),
            324px 168px rgb(101, 104, 126), 324px 172px rgb(165, 120, 138),
            324px 176px rgb(165, 120, 138), 324px 192px rgb(165, 120, 138),
            324px 196px rgb(165, 120, 138), 324px 200px rgb(101, 104, 126),
            328px 44px rgb(189, 65, 105), 328px 48px rgb(189, 65, 105),
            328px 52px rgb(189, 65, 105), 328px 176px rgb(165, 120, 138),
            328px 188px rgb(165, 120, 138), 328px 192px rgb(101, 104, 126),
            328px 196px rgb(101, 104, 126), 328px 200px rgb(101, 104, 126),
            332px 48px rgb(189, 65, 105), 332px 52px rgb(189, 65, 105),
            332px 56px rgb(189, 65, 105), 332px 64px rgb(189, 65, 105),
            332px 172px rgb(101, 104, 126), 332px 176px rgb(165, 120, 138),
            332px 180px rgb(165, 120, 138), 332px 184px rgb(165, 120, 138),
            332px 188px rgb(165, 120, 138), 332px 192px rgb(165, 120, 138),
            336px 176px rgb(101, 104, 126), 336px 180px rgb(165, 120, 138),
            336px 184px rgb(101, 104, 126), 336px 188px rgb(101, 104, 126),
            336px 192px rgb(101, 104, 126), 336px 196px rgb(165, 120, 138),
            340px 176px rgb(101, 104, 126), 340px 180px rgb(101, 104, 126),
            340px 184px rgb(165, 120, 138), 340px 192px rgb(165, 120, 138),
            344px 180px rgb(101, 104, 126), 344px 188px rgb(101, 104, 126),
            360px 196px rgb(250, 141, 124), 360px 200px rgb(250, 141, 124),
            364px 196px rgb(250, 141, 124), 364px 200px rgb(250, 141, 124),
            368px 192px rgb(250, 141, 124), 368px 196px rgb(165, 120, 138),
            368px 200px rgb(250, 141, 124), 372px 192px rgb(250, 141, 124),
            372px 196px rgb(165, 120, 138), 376px 188px rgb(250, 141, 124),
            376px 192px rgb(165, 120, 138), 376px 196px rgb(250, 141, 124),
            380px 188px rgb(250, 141, 124), 380px 192px rgb(165, 120, 138),
            384px 184px rgb(250, 141, 124), 384px 188px rgb(165, 120, 138),
            384px 192px rgb(165, 120, 138), 388px 184px rgb(250, 141, 124),
            388px 188px rgb(165, 120, 138), 392px 180px rgb(250, 141, 124),
            392px 184px rgb(165, 120, 138), 392px 188px rgb(165, 120, 138),
            396px 180px rgb(250, 141, 124), 396px 184px rgb(165, 120, 138),
            400px 176px rgb(165, 120, 138), 400px 180px rgb(165, 120, 138),
            400px 184px rgb(165, 120, 138), 404px 176px rgb(165, 120, 138),
            404px 180px rgb(165, 120, 138), 408px 172px rgb(165, 120, 138),
            408px 180px rgb(165, 120, 138), 412px 172px rgb(165, 120, 138),
            412px 180px rgb(165, 120, 138), 416px 168px rgb(165, 120, 138),
            416px 176px rgb(165, 120, 138), 420px 176px rgb(165, 120, 138),
            424px 164px rgb(189, 65, 105), 424px 172px rgb(165, 120, 138),
            428px 164px rgb(250, 141, 124), 428px 168px rgb(189, 65, 105);
    }

    66.66666666666667% {
        box-shadow: 128px 204px rgb(165, 120, 138),
            132px 196px rgb(165, 120, 138), 132px 204px rgb(165, 120, 138),
            136px 196px rgb(250, 141, 124), 136px 204px rgb(250, 141, 124),
            140px 196px rgb(250, 141, 124), 140px 200px rgb(165, 120, 138),
            140px 204px rgb(250, 141, 124), 140px 208px rgb(165, 120, 138),
            140px 220px rgb(165, 120, 138), 140px 224px rgb(165, 120, 138),
            140px 228px rgb(165, 120, 138), 140px 232px rgb(165, 120, 138),
            144px 136px rgb(165, 120, 138), 144px 140px rgb(165, 120, 138),
            144px 200px rgb(250, 141, 124), 144px 204px rgb(165, 120, 138),
            144px 208px rgb(165, 120, 138), 144px 216px rgb(165, 120, 138),
            144px 220px rgb(165, 120, 138), 144px 224px rgb(165, 120, 138),
            144px 228px rgb(165, 120, 138), 148px 132px rgb(165, 120, 138),
            148px 136px rgb(250, 141, 124), 148px 140px rgb(165, 120, 138),
            148px 144px rgb(165, 120, 138), 152px 132px rgb(165, 120, 138),
            152px 136px rgb(250, 141, 124), 152px 140px rgb(250, 141, 124),
            152px 144px rgb(165, 120, 138), 152px 176px rgb(165, 120, 138),
            156px 136px rgb(165, 120, 138), 156px 140px rgb(165, 120, 138),
            156px 176px rgb(165, 120, 138), 156px 180px rgb(165, 120, 138),
            156px 196px rgb(165, 120, 138), 156px 200px rgb(165, 120, 138),
            160px 176px rgb(165, 120, 138), 160px 180px rgb(165, 120, 138),
            160px 196px rgb(165, 120, 138), 160px 200px rgb(165, 120, 138),
            164px 200px rgb(250, 141, 124), 176px 128px rgb(250, 141, 124),
            176px 136px rgb(165, 120, 138), 176px 140px rgb(165, 120, 138),
            176px 144px rgb(250, 141, 124), 180px 124px rgb(165, 120, 138),
            180px 132px rgb(165, 120, 138), 180px 136px rgb(250, 141, 124),
            180px 144px rgb(250, 141, 124), 184px 120px rgb(165, 120, 138),
            184px 124px rgb(250, 141, 124), 184px 128px rgb(165, 120, 138),
            184px 132px rgb(250, 141, 124), 184px 136px rgb(250, 141, 124),
            184px 172px rgb(165, 120, 138), 184px 180px rgb(165, 120, 138),
            188px 120px rgb(165, 120, 138), 188px 124px rgb(250, 141, 124),
            188px 168px rgb(165, 120, 138), 188px 172px rgb(250, 141, 124),
            188px 176px rgb(165, 120, 138), 188px 180px rgb(250, 141, 124),
            192px 136px rgb(165, 120, 138), 192px 172px rgb(250, 141, 124),
            192px 176px rgb(250, 141, 124), 192px 180px rgb(165, 120, 138),
            204px 152px rgb(165, 120, 138), 208px 148px rgb(250, 141, 124),
            208px 152px rgb(165, 120, 138), 212px 144px rgb(250, 141, 124),
            212px 148px rgb(165, 120, 138), 216px 144px rgb(250, 141, 124),
            220px 144px rgb(250, 141, 124), 244px 48px rgb(189, 65, 105),
            244px 52px rgb(189, 65, 105), 248px 56px rgb(189, 65, 105),
            264px 164px rgb(101, 104, 126), 268px 80px rgb(189, 65, 105),
            268px 84px rgb(189, 65, 105), 268px 88px rgb(189, 65, 105),
            268px 92px rgb(189, 65, 105), 268px 96px rgb(189, 65, 105),
            268px 100px rgb(189, 65, 105), 268px 156px rgb(189, 65, 105),
            268px 160px rgb(189, 65, 105), 268px 164px rgb(189, 65, 105),
            268px 208px rgb(165, 120, 138), 272px 72px rgb(189, 65, 105),
            272px 76px rgb(189, 65, 105), 272px 80px rgb(189, 65, 105),
            272px 84px rgb(189, 65, 105), 272px 88px rgb(189, 65, 105),
            272px 92px rgb(189, 65, 105), 272px 96px rgb(189, 65, 105),
            272px 100px rgb(189, 65, 105), 272px 104px rgb(189, 65, 105),
            272px 108px rgb(189, 65, 105), 272px 112px rgb(189, 65, 105),
            272px 116px rgb(189, 65, 105), 272px 136px rgb(189, 65, 105),
            272px 140px rgb(189, 65, 105), 272px 144px rgb(189, 65, 105),
            272px 148px rgb(189, 65, 105), 272px 152px rgb(189, 65, 105),
            272px 156px rgb(189, 65, 105), 272px 160px rgb(189, 65, 105),
            272px 164px rgb(189, 65, 105), 272px 168px rgb(189, 65, 105),
            276px 32px rgb(189, 65, 105), 276px 36px rgb(189, 65, 105),
            276px 40px rgb(189, 65, 105), 276px 44px rgb(189, 65, 105),
            276px 68px rgb(189, 65, 105), 276px 72px rgb(189, 65, 105),
            276px 76px rgb(189, 65, 105), 276px 80px rgb(189, 65, 105),
            276px 84px rgb(250, 141, 124), 276px 88px rgb(250, 141, 124),
            276px 92px rgb(250, 141, 124), 276px 96px rgb(250, 141, 124),
            276px 100px rgb(250, 141, 124), 276px 104px rgb(189, 65, 105),
            276px 108px rgb(189, 65, 105), 276px 112px rgb(189, 65, 105),
            276px 116px rgb(189, 65, 105), 276px 120px rgb(189, 65, 105),
            276px 124px rgb(189, 65, 105), 276px 128px rgb(189, 65, 105),
            276px 132px rgb(189, 65, 105), 276px 136px rgb(189, 65, 105),
            276px 140px rgb(189, 65, 105), 276px 144px rgb(189, 65, 105),
            276px 148px rgb(189, 65, 105), 276px 152px rgb(189, 65, 105),
            276px 156px rgb(189, 65, 105), 276px 160px rgb(189, 65, 105),
            276px 164px rgb(189, 65, 105), 276px 168px rgb(98, 59, 63),
            280px 24px rgb(189, 65, 105), 280px 28px rgb(189, 65, 105),
            280px 32px rgb(189, 65, 105), 280px 36px rgb(250, 141, 124),
            280px 40px rgb(250, 141, 124), 280px 44px rgb(189, 65, 105),
            280px 48px rgb(189, 65, 105), 280px 60px rgb(189, 65, 105),
            280px 64px rgb(189, 65, 105), 280px 68px rgb(189, 65, 105),
            280px 72px rgb(189, 65, 105), 280px 76px rgb(189, 65, 105),
            280px 80px rgb(250, 141, 124), 280px 84px rgb(250, 141, 124),
            280px 88px rgb(250, 141, 124), 280px 92px rgb(250, 141, 124),
            280px 96px rgb(250, 141, 124), 280px 100px rgb(250, 141, 124),
            280px 104px rgb(250, 141, 124), 280px 108px rgb(189, 65, 105),
            280px 112px rgb(189, 65, 105), 280px 116px rgb(189, 65, 105),
            280px 120px rgb(189, 65, 105), 280px 124px rgb(189, 65, 105),
            280px 128px rgb(189, 65, 105), 280px 132px rgb(189, 65, 105),
            280px 136px rgb(189, 65, 105), 280px 140px rgb(189, 65, 105),
            280px 144px rgb(189, 65, 105), 280px 148px rgb(250, 141, 124),
            280px 152px rgb(189, 65, 105), 280px 156px rgb(189, 65, 105),
            280px 160px rgb(189, 65, 105), 280px 168px rgb(189, 65, 105),
            284px 4px rgb(189, 65, 105), 284px 8px rgb(189, 65, 105),
            284px 12px rgb(189, 65, 105), 284px 16px rgb(189, 65, 105),
            284px 20px rgb(189, 65, 105), 284px 24px rgb(189, 65, 105),
            284px 28px rgb(250, 141, 124), 284px 32px rgb(250, 141, 124),
            284px 36px rgb(250, 141, 124), 284px 40px rgb(250, 141, 124),
            284px 44px rgb(250, 141, 124), 284px 48px rgb(189, 65, 105),
            284px 52px rgb(189, 65, 105), 284px 56px rgb(189, 65, 105),
            284px 60px rgb(189, 65, 105), 284px 64px rgb(189, 65, 105),
            284px 68px rgb(189, 65, 105), 284px 72px rgb(189, 65, 105),
            284px 76px rgb(250, 141, 124), 284px 80px rgb(250, 141, 124),
            284px 84px rgb(250, 141, 124), 284px 88px rgb(250, 141, 124),
            284px 92px rgb(250, 141, 124), 284px 96px rgb(250, 141, 124),
            284px 100px rgb(250, 141, 124), 284px 104px rgb(250, 141, 124),
            284px 108px rgb(250, 141, 124), 284px 112px rgb(250, 141, 124),
            284px 116px rgb(189, 65, 105), 284px 120px rgb(189, 65, 105),
            284px 124px rgb(189, 65, 105), 284px 128px rgb(189, 65, 105),
            284px 132px rgb(189, 65, 105), 284px 136px rgb(189, 65, 105),
            284px 140px rgb(250, 141, 124), 284px 144px rgb(250, 141, 124),
            284px 148px rgb(189, 65, 105), 284px 152px rgb(189, 65, 105),
            288px 16px rgb(189, 65, 105), 288px 20px rgb(189, 65, 105),
            288px 24px rgb(189, 65, 105), 288px 28px rgb(189, 65, 105),
            288px 32px rgb(189, 65, 105), 288px 36px rgb(189, 65, 105),
            288px 40px rgb(250, 141, 124), 288px 44px rgb(250, 141, 124),
            288px 48px rgb(250, 141, 124), 288px 52px rgb(189, 65, 105),
            288px 56px rgb(189, 65, 105), 288px 60px rgb(189, 65, 105),
            288px 64px rgb(189, 65, 105), 288px 68px rgb(189, 65, 105),
            288px 72px rgb(189, 65, 105), 288px 76px rgb(250, 141, 124),
            288px 80px rgb(189, 65, 105), 288px 84px rgb(189, 65, 105),
            288px 88px rgb(189, 65, 105), 288px 92px rgb(189, 65, 105),
            288px 96px rgb(189, 65, 105), 288px 100px rgb(250, 141, 124),
            288px 104px rgb(250, 141, 124), 288px 108px rgb(250, 141, 124),
            288px 112px rgb(250, 141, 124), 288px 116px rgb(250, 141, 124),
            288px 120px rgb(250, 141, 124), 288px 124px rgb(250, 141, 124),
            288px 128px rgb(250, 141, 124), 288px 132px rgb(250, 141, 124),
            288px 136px rgb(250, 141, 124), 288px 140px rgb(250, 141, 124),
            288px 144px rgb(189, 65, 105), 288px 148px rgb(189, 65, 105),
            288px 152px rgb(189, 65, 105), 288px 156px rgb(189, 65, 105),
            292px 20px rgb(189, 65, 105), 292px 24px rgb(189, 65, 105),
            292px 28px rgb(189, 65, 105), 292px 32px rgb(189, 65, 105),
            292px 36px rgb(189, 65, 105), 292px 40px rgb(189, 65, 105),
            292px 44px rgb(189, 65, 105), 292px 48px rgb(189, 65, 105),
            292px 52px rgb(189, 65, 105), 292px 56px rgb(189, 65, 105),
            292px 60px rgb(189, 65, 105), 292px 64px rgb(189, 65, 105),
            292px 68px rgb(189, 65, 105), 292px 72px rgb(189, 65, 105),
            292px 76px rgb(189, 65, 105), 292px 80px rgb(189, 65, 105),
            292px 84px rgb(189, 65, 105), 292px 88px rgb(189, 65, 105),
            292px 92px rgb(189, 65, 105), 292px 96px rgb(189, 65, 105),
            292px 100px rgb(189, 65, 105), 292px 104px rgb(189, 65, 105),
            292px 108px rgb(250, 141, 124), 292px 112px rgb(250, 141, 124),
            292px 116px rgb(250, 141, 124), 292px 120px rgb(250, 141, 124),
            292px 124px rgb(250, 141, 124), 292px 128px rgb(250, 141, 124),
            292px 132px rgb(250, 141, 124), 292px 136px rgb(189, 65, 105),
            292px 140px rgb(189, 65, 105), 292px 144px rgb(189, 65, 105),
            292px 148px rgb(189, 65, 105), 292px 152px rgb(189, 65, 105),
            292px 156px rgb(189, 65, 105), 296px 36px rgb(189, 65, 105),
            296px 40px rgb(189, 65, 105), 296px 44px rgb(189, 65, 105),
            296px 48px rgb(189, 65, 105), 296px 52px rgb(189, 65, 105),
            296px 56px rgb(189, 65, 105), 296px 60px rgb(189, 65, 105),
            296px 64px rgb(189, 65, 105), 296px 68px rgb(189, 65, 105),
            296px 72px rgb(189, 65, 105), 296px 76px rgb(189, 65, 105),
            296px 80px rgb(189, 65, 105), 296px 84px rgb(189, 65, 105),
            296px 88px rgb(189, 65, 105), 296px 92px rgb(189, 65, 105),
            296px 96px rgb(189, 65, 105), 296px 100px rgb(189, 65, 105),
            296px 104px rgb(189, 65, 105), 296px 108px rgb(189, 65, 105),
            296px 112px rgb(189, 65, 105), 296px 116px rgb(189, 65, 105),
            296px 120px rgb(189, 65, 105), 296px 124px rgb(189, 65, 105),
            296px 128px rgb(189, 65, 105), 296px 132px rgb(189, 65, 105),
            296px 136px rgb(189, 65, 105), 296px 140px rgb(250, 141, 124),
            296px 144px rgb(250, 141, 124), 296px 148px rgb(189, 65, 105),
            296px 152px rgb(189, 65, 105), 296px 156px rgb(189, 65, 105),
            296px 160px rgb(189, 65, 105), 296px 164px rgb(189, 65, 105),
            300px 36px rgb(189, 65, 105), 300px 40px rgb(189, 65, 105),
            300px 44px rgb(189, 65, 105), 300px 48px rgb(189, 65, 105),
            300px 52px rgb(189, 65, 105), 300px 56px rgb(189, 65, 105),
            300px 60px rgb(189, 65, 105), 300px 64px rgb(189, 65, 105),
            300px 68px rgb(189, 65, 105), 300px 72px rgb(189, 65, 105),
            300px 76px rgb(189, 65, 105), 300px 104px rgb(189, 65, 105),
            300px 108px rgb(189, 65, 105), 300px 112px rgb(189, 65, 105),
            300px 116px rgb(189, 65, 105), 300px 120px rgb(189, 65, 105),
            300px 124px rgb(189, 65, 105), 300px 128px rgb(189, 65, 105),
            300px 132px rgb(189, 65, 105), 300px 136px rgb(189, 65, 105),
            300px 140px rgb(189, 65, 105), 300px 144px rgb(189, 65, 105),
            300px 148px rgb(189, 65, 105), 300px 152px rgb(189, 65, 105),
            300px 156px rgb(189, 65, 105), 300px 160px rgb(189, 65, 105),
            300px 164px rgb(189, 65, 105), 304px 44px rgb(189, 65, 105),
            304px 48px rgb(189, 65, 105), 304px 52px rgb(189, 65, 105),
            304px 56px rgb(189, 65, 105), 304px 60px rgb(189, 65, 105),
            304px 64px rgb(189, 65, 105), 304px 116px rgb(189, 65, 105),
            304px 120px rgb(189, 65, 105), 304px 124px rgb(189, 65, 105),
            304px 128px rgb(189, 65, 105), 304px 132px rgb(189, 65, 105),
            304px 136px rgb(189, 65, 105), 304px 140px rgb(189, 65, 105),
            304px 144px rgb(189, 65, 105), 304px 148px rgb(189, 65, 105),
            304px 152px rgb(189, 65, 105), 304px 164px rgb(189, 65, 105),
            304px 168px rgb(189, 65, 105), 308px 120px rgb(189, 65, 105),
            308px 124px rgb(189, 65, 105), 308px 128px rgb(189, 65, 105),
            308px 132px rgb(189, 65, 105), 308px 136px rgb(189, 65, 105),
            308px 140px rgb(189, 65, 105), 308px 144px rgb(189, 65, 105),
            308px 148px rgb(189, 65, 105), 312px 128px rgb(189, 65, 105),
            312px 132px rgb(189, 65, 105), 312px 136px rgb(189, 65, 105),
            312px 140px rgb(189, 65, 105), 328px 72px rgb(189, 65, 105),
            328px 76px rgb(189, 65, 105), 332px 56px rgb(189, 65, 105),
            332px 60px rgb(189, 65, 105), 332px 64px rgb(189, 65, 105),
            332px 68px rgb(189, 65, 105), 332px 72px rgb(189, 65, 105),
            332px 76px rgb(189, 65, 105), 336px 64px rgb(189, 65, 105),
            336px 68px rgb(189, 65, 105), 336px 72px rgb(189, 65, 105),
            340px 40px rgb(189, 65, 105), 344px 188px rgb(165, 120, 138),
            360px 196px rgb(250, 141, 124), 360px 200px rgb(250, 141, 124),
            364px 196px rgb(250, 141, 124), 364px 200px rgb(250, 141, 124),
            368px 192px rgb(250, 141, 124), 368px 196px rgb(250, 141, 124),
            368px 200px rgb(250, 141, 124), 372px 192px rgb(250, 141, 124),
            372px 196px rgb(250, 141, 124), 376px 188px rgb(250, 141, 124),
            376px 192px rgb(165, 120, 138), 376px 196px rgb(250, 141, 124),
            380px 188px rgb(250, 141, 124), 380px 192px rgb(250, 141, 124),
            384px 184px rgb(250, 141, 124), 384px 188px rgb(165, 120, 138),
            384px 192px rgb(165, 120, 138), 388px 184px rgb(250, 141, 124),
            388px 188px rgb(165, 120, 138), 392px 180px rgb(250, 141, 124),
            392px 184px rgb(165, 120, 138), 392px 188px rgb(165, 120, 138),
            396px 180px rgb(250, 141, 124), 396px 184px rgb(165, 120, 138),
            400px 176px rgb(250, 141, 124), 400px 180px rgb(165, 120, 138),
            400px 184px rgb(165, 120, 138), 404px 176px rgb(165, 120, 138),
            404px 180px rgb(165, 120, 138), 408px 172px rgb(165, 120, 138),
            408px 180px rgb(165, 120, 138), 412px 172px rgb(165, 120, 138),
            412px 180px rgb(165, 120, 138), 416px 168px rgb(165, 120, 138),
            416px 176px rgb(165, 120, 138), 420px 176px rgb(165, 120, 138),
            424px 164px rgb(250, 141, 124), 424px 172px rgb(165, 120, 138),
            428px 164px rgb(250, 141, 124), 428px 168px rgb(189, 65, 105);
    }

    83.33333333333334% {
        box-shadow: 128px 204px rgb(165, 120, 138),
            132px 196px rgb(165, 120, 138), 132px 204px rgb(165, 120, 138),
            136px 196px rgb(250, 141, 124), 136px 204px rgb(250, 141, 124),
            140px 196px rgb(250, 141, 124), 140px 200px rgb(165, 120, 138),
            140px 204px rgb(250, 141, 124), 140px 208px rgb(165, 120, 138),
            140px 220px rgb(165, 120, 138), 140px 224px rgb(165, 120, 138),
            140px 228px rgb(165, 120, 138), 140px 232px rgb(165, 120, 138),
            144px 136px rgb(165, 120, 138), 144px 140px rgb(165, 120, 138),
            144px 200px rgb(250, 141, 124), 144px 204px rgb(165, 120, 138),
            144px 208px rgb(165, 120, 138), 144px 216px rgb(165, 120, 138),
            144px 220px rgb(165, 120, 138), 144px 224px rgb(165, 120, 138),
            144px 228px rgb(165, 120, 138), 148px 132px rgb(165, 120, 138),
            148px 136px rgb(250, 141, 124), 148px 140px rgb(165, 120, 138),
            148px 144px rgb(165, 120, 138), 152px 132px rgb(165, 120, 138),
            152px 136px rgb(250, 141, 124), 152px 140px rgb(250, 141, 124),
            152px 144px rgb(165, 120, 138), 152px 176px rgb(165, 120, 138),
            156px 136px rgb(165, 120, 138), 156px 140px rgb(165, 120, 138),
            156px 176px rgb(165, 120, 138), 156px 180px rgb(165, 120, 138),
            156px 196px rgb(165, 120, 138), 156px 200px rgb(165, 120, 138),
            160px 176px rgb(165, 120, 138), 160px 180px rgb(165, 120, 138),
            160px 196px rgb(165, 120, 138), 160px 200px rgb(165, 120, 138),
            164px 200px rgb(250, 141, 124), 176px 128px rgb(250, 141, 124),
            176px 136px rgb(165, 120, 138), 176px 140px rgb(165, 120, 138),
            176px 144px rgb(250, 141, 124), 180px 124px rgb(165, 120, 138),
            180px 132px rgb(165, 120, 138), 180px 136px rgb(250, 141, 124),
            180px 144px rgb(250, 141, 124), 184px 120px rgb(165, 120, 138),
            184px 124px rgb(250, 141, 124), 184px 128px rgb(165, 120, 138),
            184px 132px rgb(250, 141, 124), 184px 136px rgb(250, 141, 124),
            184px 172px rgb(165, 120, 138), 184px 180px rgb(165, 120, 138),
            188px 120px rgb(165, 120, 138), 188px 124px rgb(250, 141, 124),
            188px 168px rgb(165, 120, 138), 188px 172px rgb(250, 141, 124),
            188px 176px rgb(165, 120, 138), 188px 180px rgb(250, 141, 124),
            192px 136px rgb(165, 120, 138), 192px 172px rgb(250, 141, 124),
            192px 176px rgb(250, 141, 124), 192px 180px rgb(165, 120, 138),
            204px 152px rgb(165, 120, 138), 208px 148px rgb(250, 141, 124),
            208px 152px rgb(165, 120, 138), 212px 144px rgb(250, 141, 124),
            212px 148px rgb(165, 120, 138), 216px 144px rgb(250, 141, 124),
            220px 144px rgb(250, 141, 124), 244px 16px rgb(189, 65, 105),
            244px 20px rgb(189, 65, 105), 244px 116px rgb(189, 65, 105),
            244px 120px rgb(189, 65, 105), 244px 124px rgb(189, 65, 105),
            248px 112px rgb(189, 65, 105), 248px 116px rgb(189, 65, 105),
            248px 148px rgb(189, 65, 105), 248px 152px rgb(189, 65, 105),
            248px 156px rgb(189, 65, 105), 252px 132px rgb(189, 65, 105),
            252px 136px rgb(189, 65, 105), 252px 140px rgb(189, 65, 105),
            252px 144px rgb(189, 65, 105), 252px 148px rgb(189, 65, 105),
            252px 152px rgb(189, 65, 105), 252px 156px rgb(189, 65, 105),
            252px 160px rgb(189, 65, 105), 256px 120px rgb(189, 65, 105),
            256px 124px rgb(189, 65, 105), 256px 128px rgb(189, 65, 105),
            256px 132px rgb(189, 65, 105), 256px 136px rgb(189, 65, 105),
            256px 140px rgb(189, 65, 105), 256px 144px rgb(189, 65, 105),
            256px 148px rgb(189, 65, 105), 256px 152px rgb(189, 65, 105),
            256px 156px rgb(189, 65, 105), 256px 160px rgb(189, 65, 105),
            256px 164px rgb(189, 65, 105), 260px 116px rgb(189, 65, 105),
            260px 120px rgb(189, 65, 105), 260px 124px rgb(189, 65, 105),
            260px 128px rgb(189, 65, 105), 260px 132px rgb(189, 65, 105),
            260px 136px rgb(189, 65, 105), 260px 140px rgb(189, 65, 105),
            260px 144px rgb(189, 65, 105), 260px 148px rgb(250, 141, 124),
            260px 152px rgb(250, 141, 124), 260px 156px rgb(189, 65, 105),
            260px 160px rgb(189, 65, 105), 260px 164px rgb(189, 65, 105),
            264px 112px rgb(189, 65, 105), 264px 116px rgb(189, 65, 105),
            264px 128px rgb(189, 65, 105), 264px 132px rgb(189, 65, 105),
            264px 136px rgb(189, 65, 105), 264px 140px rgb(189, 65, 105),
            264px 144px rgb(250, 141, 124), 264px 148px rgb(250, 141, 124),
            264px 152px rgb(250, 141, 124), 264px 156px rgb(250, 141, 124),
            264px 160px rgb(189, 65, 105), 264px 164px rgb(189, 65, 105),
            268px 124px rgb(189, 65, 105), 268px 128px rgb(189, 65, 105),
            268px 132px rgb(189, 65, 105), 268px 136px rgb(189, 65, 105),
            268px 140px rgb(189, 65, 105), 268px 144px rgb(250, 141, 124),
            268px 148px rgb(250, 141, 124), 268px 152px rgb(250, 141, 124),
            268px 156px rgb(250, 141, 124), 268px 160px rgb(189, 65, 105),
            268px 164px rgb(189, 65, 105), 272px 108px rgb(189, 65, 105),
            272px 112px rgb(189, 65, 105), 272px 116px rgb(189, 65, 105),
            272px 120px rgb(189, 65, 105), 272px 124px rgb(189, 65, 105),
            272px 128px rgb(189, 65, 105), 272px 132px rgb(189, 65, 105),
            272px 136px rgb(189, 65, 105), 272px 140px rgb(250, 141, 124),
            272px 144px rgb(250, 141, 124), 272px 148px rgb(250, 141, 124),
            272px 152px rgb(250, 141, 124), 272px 156px rgb(250, 141, 124),
            272px 160px rgb(189, 65, 105), 272px 164px rgb(189, 65, 105),
            272px 168px rgb(189, 65, 105), 276px 0px rgb(189, 65, 105),
            276px 80px rgb(189, 65, 105), 276px 84px rgb(189, 65, 105),
            276px 88px rgb(189, 65, 105), 276px 92px rgb(189, 65, 105),
            276px 96px rgb(189, 65, 105), 276px 100px rgb(189, 65, 105),
            276px 104px rgb(189, 65, 105), 276px 108px rgb(189, 65, 105),
            276px 112px rgb(189, 65, 105), 276px 116px rgb(189, 65, 105),
            276px 120px rgb(189, 65, 105), 276px 124px rgb(189, 65, 105),
            276px 128px rgb(189, 65, 105), 276px 132px rgb(189, 65, 105),
            276px 136px rgb(189, 65, 105), 276px 140px rgb(250, 141, 124),
            276px 144px rgb(250, 141, 124), 276px 148px rgb(250, 141, 124),
            276px 152px rgb(189, 65, 105), 276px 156px rgb(189, 65, 105),
            276px 160px rgb(189, 65, 105), 276px 164px rgb(189, 65, 105),
            276px 168px rgb(189, 65, 105), 280px 0px rgb(189, 65, 105),
            280px 4px rgb(189, 65, 105), 280px 48px rgb(189, 65, 105),
            280px 52px rgb(189, 65, 105), 280px 56px rgb(189, 65, 105),
            280px 60px rgb(189, 65, 105), 280px 76px rgb(189, 65, 105),
            280px 80px rgb(189, 65, 105), 280px 84px rgb(189, 65, 105),
            280px 88px rgb(189, 65, 105), 280px 92px rgb(189, 65, 105),
            280px 96px rgb(189, 65, 105), 280px 100px rgb(250, 141, 124),
            280px 104px rgb(189, 65, 105), 280px 108px rgb(189, 65, 105),
            280px 112px rgb(189, 65, 105), 280px 116px rgb(189, 65, 105),
            280px 120px rgb(189, 65, 105), 280px 124px rgb(189, 65, 105),
            280px 128px rgb(189, 65, 105), 280px 132px rgb(250, 141, 124),
            280px 136px rgb(250, 141, 124), 280px 140px rgb(250, 141, 124),
            280px 144px rgb(250, 141, 124), 280px 148px rgb(189, 65, 105),
            280px 152px rgb(189, 65, 105), 280px 156px rgb(189, 65, 105),
            280px 168px rgb(189, 65, 105), 284px 16px rgb(189, 65, 105),
            284px 44px rgb(189, 65, 105), 284px 48px rgb(189, 65, 105),
            284px 52px rgb(189, 65, 105), 284px 56px rgb(189, 65, 105),
            284px 72px rgb(189, 65, 105), 284px 76px rgb(189, 65, 105),
            284px 80px rgb(189, 65, 105), 284px 84px rgb(189, 65, 105),
            284px 88px rgb(189, 65, 105), 284px 92px rgb(189, 65, 105),
            284px 96px rgb(189, 65, 105), 284px 100px rgb(189, 65, 105),
            284px 104px rgb(189, 65, 105), 284px 108px rgb(250, 141, 124),
            284px 112px rgb(189, 65, 105), 284px 116px rgb(189, 65, 105),
            284px 120px rgb(189, 65, 105), 284px 124px rgb(189, 65, 105),
            284px 128px rgb(250, 141, 124), 284px 132px rgb(250, 141, 124),
            284px 136px rgb(250, 141, 124), 284px 140px rgb(250, 141, 124),
            284px 144px rgb(250, 141, 124), 284px 148px rgb(189, 65, 105),
            284px 152px rgb(189, 65, 105), 288px 84px rgb(189, 65, 105),
            288px 88px rgb(189, 65, 105), 288px 92px rgb(189, 65, 105),
            288px 96px rgb(189, 65, 105), 288px 100px rgb(189, 65, 105),
            288px 104px rgb(189, 65, 105), 288px 108px rgb(189, 65, 105),
            288px 112px rgb(250, 141, 124), 288px 116px rgb(250, 141, 124),
            288px 120px rgb(250, 141, 124), 288px 124px rgb(250, 141, 124),
            288px 128px rgb(250, 141, 124), 288px 132px rgb(250, 141, 124),
            288px 136px rgb(250, 141, 124), 288px 140px rgb(250, 141, 124),
            288px 144px rgb(189, 65, 105), 288px 148px rgb(189, 65, 105),
            288px 152px rgb(189, 65, 105), 292px 80px rgb(189, 65, 105),
            292px 96px rgb(189, 65, 105), 292px 100px rgb(189, 65, 105),
            292px 104px rgb(189, 65, 105), 292px 108px rgb(189, 65, 105),
            292px 112px rgb(189, 65, 105), 292px 116px rgb(189, 65, 105),
            292px 120px rgb(189, 65, 105), 292px 124px rgb(189, 65, 105),
            292px 128px rgb(189, 65, 105), 292px 132px rgb(189, 65, 105),
            292px 136px rgb(250, 141, 124), 292px 140px rgb(250, 141, 124),
            292px 144px rgb(189, 65, 105), 292px 148px rgb(189, 65, 105),
            292px 152px rgb(189, 65, 105), 292px 156px rgb(250, 141, 124),
            296px 108px rgb(189, 65, 105), 296px 112px rgb(189, 65, 105),
            296px 116px rgb(189, 65, 105), 296px 120px rgb(189, 65, 105),
            296px 124px rgb(189, 65, 105), 296px 128px rgb(189, 65, 105),
            296px 132px rgb(189, 65, 105), 296px 136px rgb(189, 65, 105),
            296px 140px rgb(250, 141, 124), 296px 144px rgb(250, 141, 124),
            296px 148px rgb(250, 141, 124), 296px 152px rgb(250, 141, 124),
            296px 156px rgb(250, 141, 124), 296px 160px rgb(189, 65, 105),
            296px 164px rgb(189, 65, 105), 300px 120px rgb(189, 65, 105),
            300px 124px rgb(189, 65, 105), 300px 128px rgb(189, 65, 105),
            300px 132px rgb(189, 65, 105), 300px 136px rgb(189, 65, 105),
            300px 140px rgb(250, 141, 124), 300px 144px rgb(250, 141, 124),
            300px 148px rgb(250, 141, 124), 300px 152px rgb(250, 141, 124),
            300px 156px rgb(189, 65, 105), 300px 160px rgb(189, 65, 105),
            300px 164px rgb(189, 65, 105), 304px 128px rgb(189, 65, 105),
            304px 132px rgb(189, 65, 105), 304px 136px rgb(189, 65, 105),
            304px 140px rgb(189, 65, 105), 304px 144px rgb(250, 141, 124),
            304px 148px rgb(250, 141, 124), 304px 152px rgb(189, 65, 105),
            304px 156px rgb(189, 65, 105), 304px 160px rgb(189, 65, 105),
            304px 164px rgb(189, 65, 105), 304px 168px rgb(189, 65, 105),
            308px 132px rgb(189, 65, 105), 308px 136px rgb(189, 65, 105),
            308px 140px rgb(189, 65, 105), 308px 144px rgb(189, 65, 105),
            308px 148px rgb(189, 65, 105), 308px 152px rgb(189, 65, 105),
            308px 156px rgb(189, 65, 105), 308px 160px rgb(189, 65, 105),
            308px 164px rgb(189, 65, 105), 312px 136px rgb(189, 65, 105),
            312px 140px rgb(189, 65, 105), 312px 144px rgb(189, 65, 105),
            312px 148px rgb(189, 65, 105), 312px 152px rgb(189, 65, 105),
            312px 156px rgb(189, 65, 105), 312px 160px rgb(189, 65, 105),
            312px 164px rgb(189, 65, 105), 316px 140px rgb(189, 65, 105),
            316px 144px rgb(189, 65, 105), 316px 148px rgb(189, 65, 105),
            316px 152px rgb(189, 65, 105), 316px 156px rgb(189, 65, 105),
            316px 160px rgb(189, 65, 105), 320px 144px rgb(189, 65, 105),
            320px 148px rgb(189, 65, 105), 320px 152px rgb(189, 65, 105),
            320px 156px rgb(189, 65, 105), 324px 120px rgb(189, 65, 105),
            328px 108px rgb(189, 65, 105), 328px 112px rgb(189, 65, 105),
            332px 24px rgb(189, 65, 105), 332px 28px rgb(189, 65, 105),
            332px 32px rgb(189, 65, 105), 348px 8px rgb(189, 65, 105),
            360px 196px rgb(250, 141, 124), 360px 200px rgb(250, 141, 124),
            364px 196px rgb(250, 141, 124), 364px 200px rgb(250, 141, 124),
            368px 192px rgb(250, 141, 124), 368px 196px rgb(250, 141, 124),
            368px 200px rgb(250, 141, 124), 372px 192px rgb(250, 141, 124),
            372px 196px rgb(250, 141, 124), 376px 188px rgb(250, 141, 124),
            376px 192px rgb(165, 120, 138), 376px 196px rgb(250, 141, 124),
            380px 188px rgb(250, 141, 124), 380px 192px rgb(250, 141, 124),
            384px 184px rgb(250, 141, 124), 384px 188px rgb(165, 120, 138),
            384px 192px rgb(165, 120, 138), 388px 184px rgb(250, 141, 124),
            388px 188px rgb(165, 120, 138), 392px 180px rgb(250, 141, 124),
            392px 184px rgb(165, 120, 138), 392px 188px rgb(165, 120, 138),
            396px 180px rgb(250, 141, 124), 396px 184px rgb(165, 120, 138),
            400px 176px rgb(250, 141, 124), 400px 180px rgb(165, 120, 138),
            400px 184px rgb(165, 120, 138), 404px 176px rgb(165, 120, 138),
            404px 180px rgb(165, 120, 138), 408px 172px rgb(165, 120, 138),
            408px 180px rgb(165, 120, 138), 412px 172px rgb(165, 120, 138),
            412px 180px rgb(165, 120, 138), 416px 168px rgb(165, 120, 138),
            416px 176px rgb(165, 120, 138), 420px 176px rgb(165, 120, 138),
            424px 164px rgb(250, 141, 124), 424px 172px rgb(165, 120, 138),
            428px 164px rgb(250, 141, 124), 428px 168px rgb(189, 65, 105);
    }

    100% {
        box-shadow: 128px 204px rgb(165, 120, 138),
            132px 196px rgb(165, 120, 138), 132px 204px rgb(165, 120, 138),
            136px 196px rgb(250, 141, 124), 136px 204px rgb(250, 141, 124),
            140px 196px rgb(250, 141, 124), 140px 200px rgb(165, 120, 138),
            140px 204px rgb(250, 141, 124), 140px 208px rgb(165, 120, 138),
            140px 220px rgb(165, 120, 138), 140px 224px rgb(165, 120, 138),
            140px 228px rgb(165, 120, 138), 140px 232px rgb(165, 120, 138),
            144px 136px rgb(165, 120, 138), 144px 140px rgb(165, 120, 138),
            144px 200px rgb(250, 141, 124), 144px 204px rgb(165, 120, 138),
            144px 208px rgb(165, 120, 138), 144px 216px rgb(165, 120, 138),
            144px 220px rgb(165, 120, 138), 144px 224px rgb(165, 120, 138),
            144px 228px rgb(165, 120, 138), 148px 132px rgb(165, 120, 138),
            148px 136px rgb(250, 141, 124), 148px 140px rgb(165, 120, 138),
            148px 144px rgb(165, 120, 138), 152px 132px rgb(165, 120, 138),
            152px 136px rgb(250, 141, 124), 152px 140px rgb(250, 141, 124),
            152px 144px rgb(165, 120, 138), 152px 176px rgb(165, 120, 138),
            156px 136px rgb(165, 120, 138), 156px 140px rgb(165, 120, 138),
            156px 176px rgb(165, 120, 138), 156px 180px rgb(165, 120, 138),
            156px 196px rgb(165, 120, 138), 156px 200px rgb(165, 120, 138),
            160px 176px rgb(165, 120, 138), 160px 180px rgb(165, 120, 138),
            160px 196px rgb(165, 120, 138), 160px 200px rgb(165, 120, 138),
            164px 200px rgb(250, 141, 124), 176px 128px rgb(250, 141, 124),
            176px 136px rgb(165, 120, 138), 176px 140px rgb(165, 120, 138),
            176px 144px rgb(250, 141, 124), 180px 124px rgb(165, 120, 138),
            180px 132px rgb(165, 120, 138), 180px 136px rgb(250, 141, 124),
            180px 144px rgb(250, 141, 124), 184px 120px rgb(165, 120, 138),
            184px 124px rgb(250, 141, 124), 184px 128px rgb(165, 120, 138),
            184px 132px rgb(250, 141, 124), 184px 136px rgb(250, 141, 124),
            184px 172px rgb(165, 120, 138), 184px 180px rgb(165, 120, 138),
            188px 120px rgb(165, 120, 138), 188px 124px rgb(250, 141, 124),
            188px 168px rgb(165, 120, 138), 188px 172px rgb(250, 141, 124),
            188px 176px rgb(165, 120, 138), 188px 180px rgb(250, 141, 124),
            192px 136px rgb(165, 120, 138), 192px 172px rgb(250, 141, 124),
            192px 176px rgb(250, 141, 124), 192px 180px rgb(165, 120, 138),
            204px 152px rgb(165, 120, 138), 208px 148px rgb(250, 141, 124),
            208px 152px rgb(165, 120, 138), 212px 144px rgb(250, 141, 124),
            212px 148px rgb(165, 120, 138), 216px 144px rgb(250, 141, 124),
            220px 144px rgb(250, 141, 124), 244px 16px rgb(189, 65, 105),
            244px 20px rgb(189, 65, 105), 244px 116px rgb(189, 65, 105),
            244px 120px rgb(189, 65, 105), 244px 124px rgb(189, 65, 105),
            248px 112px rgb(189, 65, 105), 248px 116px rgb(189, 65, 105),
            248px 148px rgb(189, 65, 105), 248px 152px rgb(189, 65, 105),
            248px 156px rgb(189, 65, 105), 252px 132px rgb(189, 65, 105),
            252px 136px rgb(189, 65, 105), 252px 140px rgb(189, 65, 105),
            252px 144px rgb(189, 65, 105), 252px 148px rgb(189, 65, 105),
            252px 152px rgb(189, 65, 105), 252px 156px rgb(189, 65, 105),
            252px 160px rgb(189, 65, 105), 256px 120px rgb(189, 65, 105),
            256px 124px rgb(189, 65, 105), 256px 128px rgb(189, 65, 105),
            256px 132px rgb(189, 65, 105), 256px 136px rgb(189, 65, 105),
            256px 140px rgb(189, 65, 105), 256px 144px rgb(189, 65, 105),
            256px 148px rgb(189, 65, 105), 256px 152px rgb(189, 65, 105),
            256px 156px rgb(189, 65, 105), 256px 160px rgb(189, 65, 105),
            256px 164px rgb(189, 65, 105), 260px 116px rgb(189, 65, 105),
            260px 120px rgb(189, 65, 105), 260px 124px rgb(189, 65, 105),
            260px 128px rgb(189, 65, 105), 260px 132px rgb(189, 65, 105),
            260px 136px rgb(189, 65, 105), 260px 140px rgb(189, 65, 105),
            260px 144px rgb(189, 65, 105), 260px 148px rgb(250, 141, 124),
            260px 152px rgb(250, 141, 124), 260px 156px rgb(189, 65, 105),
            260px 160px rgb(189, 65, 105), 260px 164px rgb(189, 65, 105),
            264px 112px rgb(189, 65, 105), 264px 116px rgb(189, 65, 105),
            264px 128px rgb(189, 65, 105), 264px 132px rgb(189, 65, 105),
            264px 136px rgb(189, 65, 105), 264px 140px rgb(189, 65, 105),
            264px 144px rgb(250, 141, 124), 264px 148px rgb(250, 141, 124),
            264px 152px rgb(250, 141, 124), 264px 156px rgb(250, 141, 124),
            264px 160px rgb(189, 65, 105), 264px 164px rgb(189, 65, 105),
            268px 124px rgb(189, 65, 105), 268px 128px rgb(189, 65, 105),
            268px 132px rgb(189, 65, 105), 268px 136px rgb(189, 65, 105),
            268px 140px rgb(189, 65, 105), 268px 144px rgb(250, 141, 124),
            268px 148px rgb(250, 141, 124), 268px 152px rgb(250, 141, 124),
            268px 156px rgb(250, 141, 124), 268px 160px rgb(189, 65, 105),
            268px 164px rgb(189, 65, 105), 272px 108px rgb(189, 65, 105),
            272px 112px rgb(189, 65, 105), 272px 116px rgb(189, 65, 105),
            272px 120px rgb(189, 65, 105), 272px 124px rgb(189, 65, 105),
            272px 128px rgb(189, 65, 105), 272px 132px rgb(189, 65, 105),
            272px 136px rgb(189, 65, 105), 272px 140px rgb(250, 141, 124),
            272px 144px rgb(250, 141, 124), 272px 148px rgb(250, 141, 124),
            272px 152px rgb(250, 141, 124), 272px 156px rgb(250, 141, 124),
            272px 160px rgb(189, 65, 105), 272px 164px rgb(189, 65, 105),
            272px 168px rgb(189, 65, 105), 276px 0px rgb(189, 65, 105),
            276px 80px rgb(189, 65, 105), 276px 84px rgb(189, 65, 105),
            276px 88px rgb(189, 65, 105), 276px 92px rgb(189, 65, 105),
            276px 96px rgb(189, 65, 105), 276px 100px rgb(189, 65, 105),
            276px 104px rgb(189, 65, 105), 276px 108px rgb(189, 65, 105),
            276px 112px rgb(189, 65, 105), 276px 116px rgb(189, 65, 105),
            276px 120px rgb(189, 65, 105), 276px 124px rgb(189, 65, 105),
            276px 128px rgb(189, 65, 105), 276px 132px rgb(189, 65, 105),
            276px 136px rgb(189, 65, 105), 276px 140px rgb(250, 141, 124),
            276px 144px rgb(250, 141, 124), 276px 148px rgb(250, 141, 124),
            276px 152px rgb(189, 65, 105), 276px 156px rgb(189, 65, 105),
            276px 160px rgb(189, 65, 105), 276px 164px rgb(189, 65, 105),
            276px 168px rgb(189, 65, 105), 280px 0px rgb(189, 65, 105),
            280px 4px rgb(189, 65, 105), 280px 48px rgb(189, 65, 105),
            280px 52px rgb(189, 65, 105), 280px 56px rgb(189, 65, 105),
            280px 60px rgb(189, 65, 105), 280px 76px rgb(189, 65, 105),
            280px 80px rgb(189, 65, 105), 280px 84px rgb(189, 65, 105),
            280px 88px rgb(189, 65, 105), 280px 92px rgb(189, 65, 105),
            280px 96px rgb(189, 65, 105), 280px 100px rgb(250, 141, 124),
            280px 104px rgb(189, 65, 105), 280px 108px rgb(189, 65, 105),
            280px 112px rgb(189, 65, 105), 280px 116px rgb(189, 65, 105),
            280px 120px rgb(189, 65, 105), 280px 124px rgb(189, 65, 105),
            280px 128px rgb(189, 65, 105), 280px 132px rgb(250, 141, 124),
            280px 136px rgb(250, 141, 124), 280px 140px rgb(250, 141, 124),
            280px 144px rgb(250, 141, 124), 280px 148px rgb(189, 65, 105),
            280px 152px rgb(189, 65, 105), 280px 156px rgb(189, 65, 105),
            280px 168px rgb(189, 65, 105), 284px 16px rgb(189, 65, 105),
            284px 44px rgb(189, 65, 105), 284px 48px rgb(189, 65, 105),
            284px 52px rgb(189, 65, 105), 284px 56px rgb(189, 65, 105),
            284px 72px rgb(189, 65, 105), 284px 76px rgb(189, 65, 105),
            284px 80px rgb(189, 65, 105), 284px 84px rgb(189, 65, 105),
            284px 88px rgb(189, 65, 105), 284px 92px rgb(189, 65, 105),
            284px 96px rgb(189, 65, 105), 284px 100px rgb(189, 65, 105),
            284px 104px rgb(189, 65, 105), 284px 108px rgb(250, 141, 124),
            284px 112px rgb(189, 65, 105), 284px 116px rgb(189, 65, 105),
            284px 120px rgb(189, 65, 105), 284px 124px rgb(189, 65, 105),
            284px 128px rgb(250, 141, 124), 284px 132px rgb(250, 141, 124),
            284px 136px rgb(250, 141, 124), 284px 140px rgb(250, 141, 124),
            284px 144px rgb(250, 141, 124), 284px 148px rgb(189, 65, 105),
            284px 152px rgb(189, 65, 105), 288px 84px rgb(189, 65, 105),
            288px 88px rgb(189, 65, 105), 288px 92px rgb(189, 65, 105),
            288px 96px rgb(189, 65, 105), 288px 100px rgb(189, 65, 105),
            288px 104px rgb(189, 65, 105), 288px 108px rgb(189, 65, 105),
            288px 112px rgb(250, 141, 124), 288px 116px rgb(250, 141, 124),
            288px 120px rgb(250, 141, 124), 288px 124px rgb(250, 141, 124),
            288px 128px rgb(250, 141, 124), 288px 132px rgb(250, 141, 124),
            288px 136px rgb(250, 141, 124), 288px 140px rgb(250, 141, 124),
            288px 144px rgb(189, 65, 105), 288px 148px rgb(189, 65, 105),
            288px 152px rgb(189, 65, 105), 292px 80px rgb(189, 65, 105),
            292px 96px rgb(189, 65, 105), 292px 100px rgb(189, 65, 105),
            292px 104px rgb(189, 65, 105), 292px 108px rgb(189, 65, 105),
            292px 112px rgb(189, 65, 105), 292px 116px rgb(189, 65, 105),
            292px 120px rgb(189, 65, 105), 292px 124px rgb(189, 65, 105),
            292px 128px rgb(189, 65, 105), 292px 132px rgb(189, 65, 105),
            292px 136px rgb(250, 141, 124), 292px 140px rgb(250, 141, 124),
            292px 144px rgb(189, 65, 105), 292px 148px rgb(189, 65, 105),
            292px 152px rgb(189, 65, 105), 292px 156px rgb(250, 141, 124),
            296px 108px rgb(189, 65, 105), 296px 112px rgb(189, 65, 105),
            296px 116px rgb(189, 65, 105), 296px 120px rgb(189, 65, 105),
            296px 124px rgb(189, 65, 105), 296px 128px rgb(189, 65, 105),
            296px 132px rgb(189, 65, 105), 296px 136px rgb(189, 65, 105),
            296px 140px rgb(250, 141, 124), 296px 144px rgb(250, 141, 124),
            296px 148px rgb(250, 141, 124), 296px 152px rgb(250, 141, 124),
            296px 156px rgb(250, 141, 124), 296px 160px rgb(189, 65, 105),
            296px 164px rgb(189, 65, 105), 300px 120px rgb(189, 65, 105),
            300px 124px rgb(189, 65, 105), 300px 128px rgb(189, 65, 105),
            300px 132px rgb(189, 65, 105), 300px 136px rgb(189, 65, 105),
            300px 140px rgb(250, 141, 124), 300px 144px rgb(250, 141, 124),
            300px 148px rgb(250, 141, 124), 300px 152px rgb(250, 141, 124),
            300px 156px rgb(189, 65, 105), 300px 160px rgb(189, 65, 105),
            300px 164px rgb(189, 65, 105), 304px 128px rgb(189, 65, 105),
            304px 132px rgb(189, 65, 105), 304px 136px rgb(189, 65, 105),
            304px 140px rgb(189, 65, 105), 304px 144px rgb(250, 141, 124),
            304px 148px rgb(250, 141, 124), 304px 152px rgb(189, 65, 105),
            304px 156px rgb(189, 65, 105), 304px 160px rgb(189, 65, 105),
            304px 164px rgb(189, 65, 105), 304px 168px rgb(189, 65, 105),
            308px 132px rgb(189, 65, 105), 308px 136px rgb(189, 65, 105),
            308px 140px rgb(189, 65, 105), 308px 144px rgb(189, 65, 105),
            308px 148px rgb(189, 65, 105), 308px 152px rgb(189, 65, 105),
            308px 156px rgb(189, 65, 105), 308px 160px rgb(189, 65, 105),
            308px 164px rgb(189, 65, 105), 312px 136px rgb(189, 65, 105),
            312px 140px rgb(189, 65, 105), 312px 144px rgb(189, 65, 105),
            312px 148px rgb(189, 65, 105), 312px 152px rgb(189, 65, 105),
            312px 156px rgb(189, 65, 105), 312px 160px rgb(189, 65, 105),
            312px 164px rgb(189, 65, 105), 316px 140px rgb(189, 65, 105),
            316px 144px rgb(189, 65, 105), 316px 148px rgb(189, 65, 105),
            316px 152px rgb(189, 65, 105), 316px 156px rgb(189, 65, 105),
            316px 160px rgb(189, 65, 105), 320px 144px rgb(189, 65, 105),
            320px 148px rgb(189, 65, 105), 320px 152px rgb(189, 65, 105),
            320px 156px rgb(189, 65, 105), 324px 120px rgb(189, 65, 105),
            328px 108px rgb(189, 65, 105), 328px 112px rgb(189, 65, 105),
            332px 24px rgb(189, 65, 105), 332px 28px rgb(189, 65, 105),
            332px 32px rgb(189, 65, 105), 348px 8px rgb(189, 65, 105),
            360px 196px rgb(250, 141, 124), 360px 200px rgb(250, 141, 124),
            364px 196px rgb(250, 141, 124), 364px 200px rgb(250, 141, 124),
            368px 192px rgb(250, 141, 124), 368px 196px rgb(250, 141, 124),
            368px 200px rgb(250, 141, 124), 372px 192px rgb(250, 141, 124),
            372px 196px rgb(250, 141, 124), 376px 188px rgb(250, 141, 124),
            376px 192px rgb(165, 120, 138), 376px 196px rgb(250, 141, 124),
            380px 188px rgb(250, 141, 124), 380px 192px rgb(250, 141, 124),
            384px 184px rgb(250, 141, 124), 384px 188px rgb(165, 120, 138),
            384px 192px rgb(165, 120, 138), 388px 184px rgb(250, 141, 124),
            388px 188px rgb(165, 120, 138), 392px 180px rgb(250, 141, 124),
            392px 184px rgb(165, 120, 138), 392px 188px rgb(165, 120, 138),
            396px 180px rgb(250, 141, 124), 396px 184px rgb(165, 120, 138),
            400px 176px rgb(250, 141, 124), 400px 180px rgb(165, 120, 138),
            400px 184px rgb(165, 120, 138), 404px 176px rgb(165, 120, 138),
            404px 180px rgb(165, 120, 138), 408px 172px rgb(165, 120, 138),
            408px 180px rgb(165, 120, 138), 412px 172px rgb(165, 120, 138),
            412px 180px rgb(165, 120, 138), 416px 168px rgb(165, 120, 138),
            416px 176px rgb(165, 120, 138), 420px 176px rgb(165, 120, 138),
            424px 164px rgb(250, 141, 124), 424px 172px rgb(165, 120, 138),
            428px 164px rgb(250, 141, 124), 428px 168px rgb(189, 65, 105);
    }
}